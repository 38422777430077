import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { NavLink } from 'react-router-dom';

import { ReactComponent as EarchIcon } from '../../media/icons/earn.svg';
import { ReactComponent as MoneyIcon } from '../../media/icons/money.svg';
import { ReactComponent as ProfileIcon } from '../../media/icons/profile.svg';

import api from '../../core/api';
import { handleError } from '../../utils/errors';

const BottomNav = () => {
  const classes = useStyles();
  const [unreadIds, setUnreadIds] = React.useState([]);
  const [updateDate, setUpdateDate] = React.useState(new Date().getTime() - 10000);

  const fetchData = React.useCallback(() => {
    api
      .get('payment/')
      .then(res => {
        const { data } = res;
        if (data && data.results) {
          let ids = [];
          data.results.forEach(data => {
            if (!data.read) {
              ids.push(data.id);
            }
          });
          setUpdateDate(new Date().getTime());
          setUnreadIds(ids);
        }
      })
      .catch(err => {
        handleError(err);
      });
  }, []);

  const readAll = React.useCallback(() => {
    if (unreadIds.length === 0) return;
    api
      .post('/payment/mark-read/', { payments: unreadIds })
      .then(() => {
        setUnreadIds([]);
      })
      .catch(err => {
        handleError(err);
      });
  }, [unreadIds]);

  if (new Date().getTime() - updateDate > 1000) {
    setUpdateDate(new Date().getTime());
    fetchData();
  }

  return (
    <div className={`app-nav ${classes.wrap}`}>
      <ul className={classes.nav}>
        <li className={classes.navItem}>
          <NavLink to="/app/profile" activeClassName={classes.navLinkActive} className={classes.navLink}>
            <ProfileIcon />
            <span>Profile</span>
          </NavLink>
        </li>
        <li className={classes.navItem}>
          <NavLink exact to="/app" activeClassName={classes.navLinkActive} className={classes.navLink}>
            <EarchIcon />
            <span>Earn</span>
          </NavLink>
        </li>
        <li className={classes.navItem}>
          <NavLink
            to="/app/payment"
            activeClassName={classes.navLinkActive}
            className={classes.navLink}
            onClick={readAll}
          >
            <MoneyIcon />
            <span>Wallet</span>
            {unreadIds.length > 0 ? <p className={classes.badge}>{unreadIds.length}</p> : null}
          </NavLink>
        </li>
      </ul>
    </div>
  );
};

const useStyles = makeStyles({
  wrap: {
    minHeight: 49,
    backgroundColor: '#FAFAFA',
    borderTop: '1px solid #ccc'
  },
  nav: {
    listStyle: 'none',
    display: 'flex',
    padding: 0,
    margin: 0,
    width: '100%'
  },
  navItem: {
    flex: '1 0 auto'
  },
  navLink: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '8px 0 2px',
    textDecoration: 'none',
    fontSize: 10,
    lineHeight: '12px',
    color: '#5C5E6C',
    '& span': {
      paddingTop: 3
    },
    '& svg path': {
      fill: 'currentColor'
    },
    paddingBottom: 'env(safe-area-inset-bottom)'
  },
  navLinkActive: {
    color: '#2B508D'
  },
  badge: {
    position: 'absolute',
    top: 8,
    backgroundColor: '#D62121',
    border: '1px solid white',
    color: 'white',
    fontSize: 10,
    lineHeight: '12px',
    borderRadius: '50%',
    padding: '0 3px',
    minWidth: '6px',
    textAlign: 'center',
    margin: 0,
    marginLeft: 6
  }
});

export default BottomNav;
