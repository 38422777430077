import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';

import Loader from './Loader';
import PageHeader from './PageHeader';

import { formatPaymentHistoryDate } from '../../utils';

import api from '../../core/api';
import { handleError } from '../../utils/errors';

function TransactionsList({ data, renderItem }) {
  const classes = useStyles();

  if (isEmpty(data)) {
    return <p>No items yet</p>;
  }
  return (
    <div className={classes.list}>
      {data.map(group => (
        <div className={classes.group} key={group.date}>
          <div className={classes.groupTitle}>{formatPaymentHistoryDate(group.date)}</div>
          {group.transactions.map(item => renderItem(item))}
        </div>
      ))}
    </div>
  );
}

const HistoryContainer = ({ pageTitle, renderItem }) => {
  const classes = useStyles();

  const [isLoading, setLoading] = React.useState(true);
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const { data } = await api.get('payment/by-days/', {
          params: {
            page_size: 100
          }
        });
        setData(data.results);
      } catch (e) {
        handleError(e);
      } finally {
        setLoading(false);
      }
    };
    fetchData().then();
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className={classes.page}>
      <PageHeader backBtn>{pageTitle}</PageHeader>
      <TransactionsList data={data} renderItem={renderItem} />
    </div>
  );
};

const useStyles = makeStyles({
  page: {
    paddingBottom: 24
  },
  list: {
    margin: '8px -24px 0'
  },
  groupTitle: {
    padding: '12px 24px',
    backgroundColor: '#F2F2F2',
    font: "16px/24px 'Roboto', serif",
    color: '#8A8B94',
    fontWeight: 500,
    position: 'sticky',
    top: 0
  }
});

HistoryContainer.propTypes = {
  pageTitle: PropTypes.string,
  renderItem: PropTypes.func.isRequired
};

export default HistoryContainer;
