import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import hexagon from '../../media/icons/hexagon-hiw-register.svg';
import bg from '../../media/icons/statistics-mobile-right-top-bg.svg';

const RegistrationHowItWorks = () => {
  const classes = useStyles();
  return (
    <div className={`hiw-registration ${classes.wrap}`}>
      <h2 className={classes.title}>How It Works:</h2>
      <ul className={classes.list}>
        <li className={classes.listItem}>
          <b className={classes.listItemTitle}>Sign Up</b>
          <p className={classes.listItemText}>
            Provide some basic <br />
            information so we can identify <br />
            the best opportunities for you
          </p>
        </li>
        <li className={classes.listItem}>
          <b className={classes.listItemTitle}>Share Insights</b>
          <p className={classes.listItemText}>
            We’ll notify you with new opportunities <br />
            to provide insights and opinions via <br />
            the our mobile web application
          </p>
        </li>
        <li className={classes.listItem}>
          <b className={classes.listItemTitle}>Get Paid</b>
          <p className={classes.listItemText}>
            Accumulate earnings and cash <br />
            out instantly via PayPal
          </p>
        </li>
      </ul>
    </div>
  );
};

const useStyles = makeStyles({
  wrap: {
    marginTop: 80,
    padding: '40px 24px',
    borderRadius: '40px 40px 0 0',
    color: '#fff',
    background: `url(${bg}) no-repeat #14315C top right`
  },
  title: {
    margin: 0,
    font: "40px/48px 'Domine', serif"
  },
  list: {
    counterReset: 'list',
    listStyle: 'none',
    padding: 0,
    margin: 0
  },
  listItem: {
    position: 'relative',
    paddingLeft: 112,
    minHeight: 98,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: 24,
    '&:before': {
      counterIncrement: 'list',
      content: 'counter(list)',
      position: 'absolute',
      width: 104,
      height: 104,
      left: 0,
      top: '50%',
      marginTop: -52,
      font: "40px/104px 'Domine', serif",
      fontWeight: 700,
      textAlign: 'center',
      background: `url(${hexagon}) no-repeat transparent center center`
    }
  },
  listItemTitle: {
    fontSize: 24,
    lineHeight: '32px',
    fontWeight: 500,
    display: 'block'
  },
  listItemText: {
    fontSize: 16,
    lineHeight: '24px',
    margin: '8px 0 0'
  }
});

export default RegistrationHowItWorks;
