export const AnswerType = {
  multiple_choice: 'multiple_choice',
  image: 'image',
  survey_js: 'survey_js'
};

export const CurrentItemResponseType = {
  survey: 'survey',
  question: 'question'
};

export const PaymentActionType = {
  withdrawal: 'withdrawal',
  survey: 'survey',
  reverse: 'reverse'
};

export const PageType = {
  Surveys: 'Surveys',
  Question: 'Question'
};
