import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';

import { Button } from './Button';

const DialogWrapper = ({
  isOpened,
  onClose,
  onSubmit,
  title,
  text,
  buttonTitle,
  icon,
  disabled = false,
  showButton = true
}) => {
  const classes = useStyles();
  return (
    <Dialog onClose={onClose} open={isOpened} className={classes.dialog} scroll="body">
      <div className={classes.dialogContent}>
        <div className={classes.dialogIcon}>{icon}</div>
        <h2 className={classes.dialogTitle}>{title}</h2>
        <p className={classes.dialogText}>{text}</p>
        {showButton && (
          <Button disabled={disabled} className={classes.dialogButton} onClick={onSubmit}>
            {buttonTitle}
          </Button>
        )}
      </div>
    </Dialog>
  );
};

const useStyles = makeStyles({
  dialog: {
    '& .MuiDialog-paper': {
      overflow: 'visible',
      margin: '56px 24px 24px',
      maxWidth: 'calc(100% - 48px)',
      width: '100%',
      boxShadow: 'none'
    }
  },
  dialogContent: {
    padding: '0 24px 24px',
    textAlign: 'center'
  },
  dialogIcon: {
    display: 'block',
    margin: '-32px auto 24px'
  },
  dialogTitle: {
    font: "32px/40px 'Domine', serif",
    color: '#333646',
    margin: 0,
    fontWeight: 700
  },
  dialogText: {
    fontSize: 16,
    lineHeight: '24px',
    color: '#5C5E6C',
    margin: '8px 0 0'
  },
  dialogButton: {
    width: '100%',
    marginTop: 24
  }
});

export default DialogWrapper;
