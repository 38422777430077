import React from 'react';

function Icon({ color = '#14315c', size = 64 }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 128 128">
      <g>
        <circle cx="16" cy="64" r="16" fill={color}></circle>
        <circle cx="16" cy="64" r="14.344" fill={color} transform="rotate(45 64 64)"></circle>
        <circle cx="16" cy="64" r="12.531" fill={color} transform="rotate(90 64 64)"></circle>
        <circle cx="16" cy="64" r="10.75" fill={color} transform="rotate(135 64 64)"></circle>
        <circle cx="16" cy="64" r="10.063" fill={color} transform="rotate(180 64 64)"></circle>
        <circle cx="16" cy="64" r="8.063" fill={color} transform="rotate(225 64 64)"></circle>
        <circle cx="16" cy="64" r="6.438" fill={color} transform="rotate(270 64 64)"></circle>
        <circle cx="16" cy="64" r="5.375" fill={color} transform="rotate(315 64 64)"></circle>
        <animateTransform
          attributeName="transform"
          calcMode="discrete"
          dur="960ms"
          repeatCount="indefinite"
          type="rotate"
          values="0 64 64;315 64 64;270 64 64;225 64 64;180 64 64;135 64 64;90 64 64;45 64 64"
        ></animateTransform>
      </g>
    </svg>
  );
}

export default Icon;
