import React from 'react';

import { Button } from './Button';
import { TextField } from './TextField';
import Privacy from './Privacy';

const LoginForm = ({ text, onPress, onInputChange, placeholder, buttonText, reenterText, reenterTextClick, error }) => {
  const { button, buttonContainer, form, textBox, errorText, reenterTextStyle } = styles;
  return (
    <form
      style={form}
      onSubmit={e => {
        e.preventDefault();
        onPress();
      }}
    >
      <div>
        <TextField
          style={textBox}
          type="tel"
          id="enter_phone"
          placeholder={placeholder}
          onChange={onInputChange.bind(this)}
          value={text}
        />
        {error && error.type === 'field' ? <div style={errorText}>{error.message}</div> : null}
      </div>
      <Privacy />
      <div style={buttonContainer}>
        <Button onClick={onPress} style={button}>
          {buttonText}
        </Button>
      </div>
      {error && error.type !== 'field' ? <div style={errorText}>{error.message}</div> : null}
      {reenterText ? (
        <div style={reenterTextStyle}>
          <span onClick={reenterTextClick}>
            {reenterText.split('<br>').map((item, index) => (
              <p key={index} style={{ margin: 0 }}>
                {item}
              </p>
            ))}
          </span>
        </div>
      ) : null}
    </form>
  );
};

const styles = {
  button: {
    width: '100%'
  },
  buttonContainer: {
    position: 'relative',
    paddingTop: 0
  },
  form: {
    width: '100%',
    marginTop: 10
  },
  textBox: {
    width: '100%',
    marginBottom: 0
  },
  errorText: {
    color: '#D62121',
    padding: '2px 16px'
  },
  reenterTextStyle: {
    textAlign: 'center',
    margin: '12px 16px',
    cursor: 'pointer',
    color: '#579862',
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 500
  }
};

export { LoginForm };
