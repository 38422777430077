import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';

async function getQuestionImage(id, { signal }) {
  const token = localStorage.getItem('accessToken');
  const base_url = process.env.REACT_APP_BASE_URL;
  const url = base_url + 'question_image_by_id/' + id + '/';

  const config = {
    headers: {
      Authorization: 'Token ' + token
    },
    signal
  };

  const res = await axios.get(url, config);
  if (res.data) {
    return res.data;
  }
}

const QuestionImage = ({ id }) => {
  const [image, setImage] = useState(undefined);
  const classes = useStyles();
  useEffect(() => {
    let c = new AbortController();
    (async () => {
      try {
        if (c !== undefined) {
          c.abort();
          c = new AbortController();
        }
        const data = await getQuestionImage(id, { signal: c.signal });
        setImage(data);
      } catch (err) {
        setImage(undefined);
      }
    })();
    return () => {
      if (c !== undefined) {
        c.abort();
      }
    };
  }, [id]);

  if (image === undefined) {
    return null;
  }

  const maxWidthStr = (image.max_width || '').split('\n')[0];
  const maxWidth = maxWidthStr.match(/^\d+(px|%)$/) ? maxWidthStr : '95%';
  const maxHeightStr = (image.max_height || '').split('\n')[0];
  const maxHeight = maxHeightStr.match(/^\d+(px|%)$/) ? maxHeightStr : 250;
  const objectFit = ['contain', 'cover', 'fill', 'none', 'scale-down'].includes(image.object_fit)
    ? image.object_fit
    : 'contain';

  return (
    <div className={classes.imgContainer}>
      <img style={{ maxWidth, maxHeight, objectFit }} src={`data:image/jpeg;base64,${image.base64}`} />
    </div>
  );
};

const useStyles = makeStyles({
  imgContainer: {
    textAlign: 'center',
    marginBottom: '4px'
  }
});

export default QuestionImage;
