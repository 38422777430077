import {
  EMAIL_CHANGED,
  PASSWORD_CHANGED,
  USERNAME_CHANGED,
  CURRENT_FORM_CHANGED,
  PHONE_CHANGED,
  VERIFICATION_CODE_CHANGED,
  ERROR_CHANGED,
  PROFILE_CHANGED
} from '../actions/types';

const INITIAL_STATE = {
  error: '',
  loading: false,
  email: '',
  password: '',
  username: '',
  currentForm: 'phone',
  phone: '',
  verificationCode: '',
  profile: {}
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case EMAIL_CHANGED:
      return { ...state, email: action.payload };
    case PASSWORD_CHANGED:
      return { ...state, password: action.payload };
    case USERNAME_CHANGED:
      return { ...state, username: action.payload };
    case CURRENT_FORM_CHANGED:
      return { ...state, currentForm: action.payload };
    case PHONE_CHANGED:
      return { ...state, phone: action.payload };
    case VERIFICATION_CODE_CHANGED:
      return { ...state, verificationCode: action.payload };
    case ERROR_CHANGED:
      return { ...state, error: action.payload };
    case PROFILE_CHANGED:
      return { ...state, profile: action.payload };
    default:
      return state;
  }
};
