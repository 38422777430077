import { useEffect, useContext, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTitle } from 'react-use';
import WebsocketContext from '../context/Websockets';
import { profileChanged } from '../actions';
import { getUserId } from '../core/auth';
import { handleError } from '../utils/errors';
import api from '../core/api';
import useGTM from '@elgorditosalsero/react-gtm-hook';
import { Browser } from '@capacitor/browser';

export const useTitleWithEvent = (title, history) => {
  const { sendDataToGTM } = useGTM();
  useTitle(title);
  useEffect(() => {
    const event = {
      event: 'Pageview',
      pageTitle: title,
      pagePath: history.location.pathname
    };
    sendDataToGTM(event);
  }, [title, history, sendDataToGTM]);
};

export const useHandleWsEvents = profile => {
  const wsContext = useContext(WebsocketContext);
  const dispatch = useDispatch();

  function connect(url) {
    let ws = new WebSocket(url);
    wsContext.eventsWs = ws;

    ws.onopen = function () {
      console.log('Socket is open.');
    };

    ws.onmessage = function (event) {
      let data = JSON.parse(event.data);

      if (data.message == 'browser_close') {
        Browser.close();
      } else {
        fetchUser();
      }
    };

    ws.onclose = function (e) {
      console.log('Socket is closed. Reconnect will be attempted in 3 seconds.', e.reason);
      setTimeout(function () {
        connect(url);
      }, 3000);
    };
  }

  const fetchUser = useCallback(() => {
    api
      .get(`users/${getUserId()}/`)
      .then(res => {
        const profile = res.data.profile;
        dispatch(profileChanged(profile));
      })
      .catch(err => {
        handleError(err);
      })
      .finally(() => {});
  }, []);

  useEffect(() => {
    let url = new URL(process.env.REACT_APP_BASE_URL);
    url.pathname = `/ws/events/${profile.id}/`;
    url.protocol = { 'http:': 'ws:', 'https:': 'wss:' }[url.protocol];
    // prevent websocket from connecting multiple times
    if (profile && profile.id && !wsContext.eventsWs) {
      const baseUrl = process.env.REACT_APP_BASE_URL;
      const protocol = baseUrl.startsWith('http://') ? 'ws://' : 'wss://';
      connect(`${url.toString()}`);
    }
  }, [profile, wsContext]);
};
