import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { Link, Redirect } from 'react-router-dom';
import validator from 'validator';
import { Form } from 'antd';
import MailCheck from 'react-mailcheck';
import {
  emailChanged,
  firstNameChanged,
  lastNameChanged,
  paypalEmailChanged,
  phoneChanged,
  registerErrorChanged
} from '../../actions';

import RegistrationWrap from '../UI/RegistrationWrap';
import { Button } from '../UI/Button';
import { TextField } from '../UI/TextField';
import RegisterPrivacy from '../UI/RegisterPrivacy';
import Responsive from '../UI/Responsive';
import { getToken, setToken, setUserId } from '../../core/auth';

import { useTitleWithEvent } from '../../hooks';

class Register extends Component {
  state = {
    isSubmitDisabled: false,
    placesError: null
  };

  onEmailChange = event => {
    const val = event.target.value;
    let email = val.replace(/ /g, '');
    this.props.emailChanged(email);
  };

  onPhoneChange = event => {
    const val = event.target.value;
    let phone = val.replace(/\D/g, '');
    this.props.phoneChanged(phone);
  };

  onFirstNameChange = event => {
    const val = event.target.value;
    this.props.firstNameChanged(val);
  };

  onLastNameChange = event => {
    const val = event.target.value;
    this.props.lastNameChanged(val);
  };

  onPaypalEmailChange = event => {
    const val = event.target.value;
    let email = val.replace(/ /g, '');
    this.props.paypalEmailChanged(email);
  };

  onErrorChange = error => {
    this.props.registerErrorChanged(error);
  };

  validateEmail = email => {
    var validated = validator.isEmail(email);
    return validated;
  };

  validatePhone = phone => {
    var validated = validator.isMobilePhone(phone);
    return validated;
  };

  emptyFields = () => {
    const { email, firstName, lastName, phone } = this.props;

    if (email === '' || firstName === '' || lastName === '' || phone === '') {
      return true;
    } else {
      return false;
    }
  };

  clearFields = () => {
    this.props.firstNameChanged('');
    this.props.lastNameChanged('');
    this.props.emailChanged('');
    this.props.phoneChanged('');
    this.props.paypalEmailChanged('');
  };

  gotoForm = () => {
    this.props.history.push('/app');
  };

  updateAuthToken = token => {
    setToken(token);
  };

  updateAuthUserId = userId => {
    setUserId(userId);
  };

  setIsSubmitButtonDisabled = value => {
    this.setState({ isSubmitDisabled: value });
  };

  submit = async () => {
    const base_url = process.env.REACT_APP_BASE_URL;
    const url = base_url + 'register/';
    const { firstName, lastName, email, phone } = this.props;

    let emptyFields = this.emptyFields();
    if (emptyFields) {
      return;
    }
    let validated = this.validateEmail(email);
    if (!validated) {
      this.onErrorChange('Invalid email');
      return;
    }

    validated = this.validatePhone(phone);
    if (!validated || phone.length !== 10) {
      this.onErrorChange('Invalid phone number');
      return;
    }

    const params = {
      username: email,
      email: email,
      profile: {
        first_name: firstName,
        last_name: lastName,
        phone_number: phone
      }
    };

    const search = this.props.history.location?.search;
    if (search) {
      const search_params = new URLSearchParams(search);
      const fields = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content', 'utm_referral'];
      for (const field of fields) {
        const data = search_params.get(field);
        if (data) {
          params['profile'][field] = data;
        }
      }
    }

    this.setIsSubmitButtonDisabled(true);
    try {
      const res = await axios.post(url, params);
      if (res.data.status === 'success') {
        const authToken = res.data.key;
        this.onErrorChange('');
        this.updateAuthToken(authToken);
        this.updateAuthUserId(res.data?.user?.id);
        this.clearFields();
        this.gotoForm();
      } else {
        const errorMessage = res.data.error_message;
        this.onErrorChange(errorMessage);
      }
    } catch (err) {
      const errorMessage = err.response?.data?.error_message;
      this.onErrorChange(errorMessage);
    } finally {
      this.setIsSubmitButtonDisabled(false);
    }
  };

  render() {
    const token = getToken();
    if (token) {
      return <Redirect to="/app" />;
    }
    const { email, firstName, lastName, phone, error } = this.props;
    const {
      form,
      formContainer,
      textbox,
      submitButtonContainer,
      submitButton,
      emailSuggestionWrap,
      emailSuggestionTextbox,
      emailSuggestion,
      emailSuggestionLink
    } = styles;

    return (
      <RegistrationWrap>
        <h2>
          <Responsive.Mobile>Get Paid to Take Weekly Surveys!</Responsive.Mobile>
          <Responsive.Desktop>Sign Up</Responsive.Desktop>
        </h2>
        <Responsive.Mobile>
          <p className="_mobile-visible">Join 100k+ other gig economy workers getting paid to share weekly insights!</p>
        </Responsive.Mobile>
        <p>
          Have account? <Link to="/login">Log In</Link>
        </p>
        <div style={formContainer}>
          <Form style={form}>
            <TextField
              autoComplete="given-name"
              required
              style={textbox}
              placeholder="First Name"
              onChange={this.onFirstNameChange.bind(this)}
              value={firstName}
            />

            <TextField
              autoComplete="family-name"
              required
              style={textbox}
              placeholder="Last Name"
              onChange={this.onLastNameChange.bind(this)}
              value={lastName}
            />

            <MailCheck email={email}>
              {suggestion => (
                <div style={emailSuggestionWrap}>
                  <TextField
                    autoComplete="email"
                    required
                    style={emailSuggestionTextbox}
                    placeholder="Email"
                    onChange={this.onEmailChange.bind(this)}
                    value={email}
                  />
                  {suggestion ? (
                    <div style={emailSuggestion}>
                      Did you mean&nbsp;
                      <span
                        style={emailSuggestionLink}
                        onClick={() => this.onEmailChange({ target: { value: suggestion.full } })}
                      >
                        {suggestion.full}
                      </span>
                      ?
                    </div>
                  ) : null}
                </div>
              )}
            </MailCheck>

            <TextField
              type="tel"
              required
              style={textbox}
              placeholder="Mobile Phone"
              onChange={this.onPhoneChange.bind(this)}
              value={phone}
            />
            {error ? <div style={{ textAlign: 'center', color: '#D62121', padding: 10 }}>{error}</div> : null}
            <RegisterPrivacy />
            <div style={submitButtonContainer}>
              <Button onClick={this.submit} type="submit" style={submitButton} disabled={this.state.isSubmitDisabled}>
                GET STARTED
              </Button>
            </div>
          </Form>
        </div>
      </RegistrationWrap>
    );
  }
}

const styles = {
  form: {
    position: 'relative'
  },
  formContainer: {
    paddingTop: 40
  },
  textbox: {
    width: '100%',
    marginBottom: 24
  },
  textboxPlaceSuggestion: {
    width: '100%'
  },
  submitButtonContainer: {
    paddingTop: 24
  },
  submitButton: {
    width: '100%'
  },
  placeSuggestionListWrap: {
    position: 'relative',
    marginBottom: 24
  },
  emailSuggestionWrap: {
    marginBottom: 24
  },
  emailSuggestionTextbox: {
    width: '100%'
  },
  emailSuggestion: {
    marginTop: 8,
    fontSize: 16,
    lineHeight: '24px'
  },
  emailSuggestionLink: {
    cursor: 'pointer',
    color: '#579862',
    fontWeight: 'bold',
    wordBreak: 'break-word'
  }
};

const RegistrationPage = ({ history, ...props }) => {
  useTitleWithEvent('Registration', history);
  return <Register history={history} {...props} />;
};

const mapStateToProps = ({ register }) => {
  const { error, email, phone, firstName, lastName, paypalEmail } = register;
  return {
    error,
    email,
    phone,
    firstName,
    lastName,
    paypalEmail
  };
};

export default connect(mapStateToProps, {
  emailChanged,
  phoneChanged,
  registerErrorChanged,
  firstNameChanged,
  lastNameChanged,
  paypalEmailChanged
})(RegistrationPage);
