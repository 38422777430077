import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

import Page from './Page';
import Nav from './Nav';

const Pager = ({
  surveyModel,
  data,
  onDataChange,
  error,
  errors,
  currentPage,
  onCurrentPageChange,
  lastPageAction
}) => {
  const classes = useStyles();

  return (
    <Box display="flex" flexDirection="column" className={classes.pager}>
      <Box flexGrow={1} className={classes.slider}>
        {surveyModel.pages.map((page, i) => (
          <Page
            key={page.name}
            pageModel={page}
            active={i === currentPage}
            lastPageAction={lastPageAction}
            data={data}
            onDataChange={onDataChange}
            errors={errors}
            isLastPage={i === surveyModel.pages.length - 1}
          />
        ))}
      </Box>
      <Nav currentPage={currentPage} onCurrentPageChange={onCurrentPageChange} pageCount={surveyModel.pages.length} />
    </Box>
  );
};

const useStyles = makeStyles({
  pager: {
    height: '100%',
    marginLeft: -24,
    marginRight: -24
  },
  slider: {
    position: 'relative'
  }
});

export default Pager;
