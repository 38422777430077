import React from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import ReduxThunk from 'redux-thunk';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import reducers from './reducers';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import { Capacitor } from '@capacitor/core';
import { App as CapacitorApp } from '@capacitor/app';

import { Button } from './components/UI/Button';
import PrivateRoute from './components/UI/PrivateRoute';
import Login from './components/screens/Login';
import Register from './components/screens/Register';
import GetApp from './components/screens/GetApp';
import ContactUs from './web-site/ContactUs';
import TestError from './components/screens/TestError';
import App from './App';
import WebSite from './web-site';
import api from './core/api';
import history from './core/history';
import { handleError } from './utils/errors';

const store = createStore(reducers, {}, applyMiddleware(ReduxThunk));
const platform = Capacitor.getPlatform();

const store_link =
  platform === 'android'
    ? 'market://details?id=ai.productlab'
    : 'itms-apps://itunes.apple.com/app/ai.productlab.mobile';

const defaultAppInfo = {
  name: 'ProductLab',
  id: 'ai.productlab',
  build: '2',
  version: '1.7'
};

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const AppMain = () => {
  const [appInfo, setAppInfo] = React.useState(undefined);
  const [forceUpgrade, setForceUpgrade] = React.useState(false);

  const checkVersion = React.useCallback(() => {
    let info;
    if (platform === 'web') {
      info = defaultAppInfo;
    } else if (platform === 'ios' || platform === 'android') {
      info = appInfo;
    }
    if (!info) {
      return;
    }

    api
      .get(`appversion/`, {
        params: {
          platform: platform,
          app_version: info.version,
          build_version: info.build
        }
      })
      .then(res => {
        const data = res?.data;
        if (data) {
          let haveForceUpgrade = false;
          data.forEach(version => {
            if (version.force_upgrade) {
              haveForceUpgrade = true;
            }
          });

          if (platform === 'web') {
            if (haveForceUpgrade) window.location.reload(true);
          } else {
            setForceUpgrade(haveForceUpgrade);
          }
        }
      })
      .catch(err => {
        handleError(err);
      });
  }, [appInfo]);

  React.useEffect(() => {
    if (platform === 'web') checkVersion();
  }, [checkVersion]);

  React.useEffect(() => {
    if (appInfo) checkVersion();
  }, [appInfo, checkVersion]);

  React.useEffect(() => {
    CapacitorApp.getInfo()
      .then(info => {
        setAppInfo(info);
      })
      .catch(err => {});
  }, [setAppInfo]);

  React.useEffect(() => {
    let interval = setInterval(() => {
      checkVersion();
    }, 1000 * 60 * 60);
    return () => clearInterval(interval);
  }, [checkVersion]);

  React.useEffect(() => {
    const listener = CapacitorApp.addListener('appStateChange', ({ isActive }) => {
      if (isActive) checkVersion();
    });
    return () => {
      listener.then(result => {
        result.remove();
      });
    };
  }, [checkVersion]);

  return (
    <Provider store={store}>
      <Elements stripe={stripePromise}>
        <Router history={history}>
          <Switch>
            <Route path="/login" component={Login} />
            <Route path="/register" component={Register} />
            <Route path="/get" component={GetApp} />
            <Route path="/contact" component={ContactUs} />
            <PrivateRoute path="/app" component={App} />
            <Route path="/_test-error" component={TestError} />
            <Route component={WebSite} />
          </Switch>
          <ToastContainer position="top-center" />
          <Dialog disableBackdropClick disableEscapeKeyDown maxWidth="xs" open={forceUpgrade}>
            <DialogTitle>New Update Available!</DialogTitle>
            <DialogContent>
              <DialogContentText>Click below to update your application to latest version.</DialogContentText>
              <DialogActions>
                <Button color="primary" onClick={() => (window.location.href = store_link)} autoFocus>
                  Download
                </Button>
              </DialogActions>
            </DialogContent>
          </Dialog>
        </Router>
      </Elements>
    </Provider>
  );
};

export default AppMain;
