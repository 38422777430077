import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import { Button } from '../UI/Button';

const Confirm = ({ opened, onClose, text, confirmBtnText = 'Delete', onYes }) => {
  const classes = useStyles();

  return (
    <div className={`${classes.wrap} ${opened ? '_opened' : ''}`} onClick={onClose}>
      <div className={`${classes.content} ${opened ? '_opened' : ''}`}>
        <h3 className={classes.title}>Confirmation</h3>
        <p className={classes.text}>{text}</p>
        <Button className={classes.button} color="inherit" onClick={onYes}>
          {confirmBtnText}
        </Button>
        <Button className={classes.button} color="secondary" onClick={onClose}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

Confirm.propTypes = {
  opened: PropTypes.bool,
  onClose: PropTypes.func,
  text: PropTypes.string,
  confirmBtnText: PropTypes.string,
  onYes: PropTypes.func.isRequired
};

const useStyles = makeStyles({
  wrap: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: -10,
    transition: 'background-color .3s ease,z-index 0s ease .3s',
    '&._opened': {
      zIndex: 1000,
      backgroundColor: 'rgba(0,0,0,.5)',
      transition: 'background-color .3s ease,z-index 0s ease 0s'
    }
  },
  content: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    right: 0,
    backgroundColor: '#fff',
    borderRadius: '16px 16px 0 0',
    padding: 24,
    transform: 'translateY(100%)',
    transitionDuration: '.3s',
    transitionTimingFunction: 'ease',
    transitionProperty: 'transform',
    '&._opened': {
      transform: 'translateY(0px)'
    }
  },
  title: {
    fontSize: 24,
    lineHeight: '32px',
    margin: '0 0 16px',
    fontWeight: 'bold',
    color: '#1B1B1B'
  },
  text: {
    fontSize: 16,
    lineHeight: '24px',
    margin: 0,
    paddingBottom: 8,
    color: '#5C5E6C'
  },
  button: {
    width: '100%',
    marginTop: 16
  }
});

export default Confirm;
