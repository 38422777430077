import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import { Button } from '../UI/Button';
import Confirm from '../UI/Confirm';
import Loader from '../UI/Loader';

import { ReactComponent as UploadIcon } from '../../media/icons/upload.svg';
import { ReactComponent as ImageIcon } from '../../media/icons/image.svg';
import { ReactComponent as TrashIcon } from '../../media/icons/trash.svg';

import api from '../../core/api';

import WarningIcon from '@material-ui/icons/Warning';

import { find, isEmpty } from 'lodash';
import { handleError } from '../../utils/errors';

const ImageError = ({ errors, file }) => {
  const found = find(errors, ['file', file]);
  if (found) {
    return (
      <div style={{ display: 'flex', alignItems: 'center', color: 'red' }}>
        <WarningIcon />
        <div style={{ margin: '5px' }}>{found.error}</div>
      </div>
    );
  }

  return null;
};

const SubmissionError = ({ error }) => {
  if (error) {
    return (
      <div style={{ display: 'flex', alignItems: 'center', color: 'red' }}>
        <WarningIcon />
        <div style={{ margin: '5px' }}>{error}</div>
      </div>
    );
  }

  return null;
};

const ImagePreview = ({ file, ...props }) => {
  const [imageUrl, setImageUrl] = React.useState(null);
  React.useEffect(() => {
    const objectUrl = URL.createObjectURL(file);
    setImageUrl(objectUrl);
    return () => URL.revokeObjectURL(objectUrl);
  }, [file]);
  return <img {...props} src={imageUrl} alt={file.name} />;
};

const QuestionImage = ({ question, onSubmit, isLoadingButton }) => {
  const classes = useStyles();

  const submitButton = React.useRef(null);
  const [isImagePreviewLoading, setImagePreviewLoading] = React.useState(true);
  const [imagePlaceholder, setImagePlaceholder] = React.useState(null);

  const [imageErrors, setImageErrors] = React.useState([]);
  const [submissionError, setSubmissionError] = React.useState();

  const [imageList, setImageList] = React.useState([]);
  const [imageToDelete, setImageToDelete] = React.useState(null);

  const [confirmOpened, setConfirmOpened] = React.useState(false);

  const openConfirm = React.useCallback(() => {
    setConfirmOpened(true);
  }, [setConfirmOpened]);

  const closeConfirm = React.useCallback(() => {
    setConfirmOpened(false);
  }, [setConfirmOpened]);

  const getQuestionImage = React.useCallback(() => {
    api
      .get(`question_image/${question?.id}/`)
      .then(res => {
        if (res.data) {
          setImagePlaceholder(res.data.base64);
        }
      })
      .catch(err => {
        handleError(err);
      })
      .finally(() => setImagePreviewLoading(false));
  }, [question]);

  React.useEffect(() => {
    getQuestionImage();
  }, [getQuestionImage]);

  const submitAnswer = React.useCallback(() => {
    let payload = new FormData();
    payload.append('question_id', question?.id);

    for (const imageFile of imageList) {
      payload.append('image', imageFile);
    }

    onSubmit(
      'image_answer_create/',
      payload,
      {
        headers: {
          Accept: '*/*',
          'content-type': 'multipart/form-data'
        }
      },
      err => {
        const response = err.response || {};
        let handled = false;
        if (response.invalid_images) {
          const imageErrs = response.invalid_images.map(e => ({
            file: imageList[e.index].name,
            error: e.message
          }));
          setImageErrors(imageErrs);
          handled = true;
        }

        if (response.error) {
          setSubmissionError(response.error?.message || 'Could not submit images');
          handled = true;
        }
        if (!handled) {
          handleError(err);
        }
      }
    );
  }, [onSubmit, imageList, question]);

  const onSelectImages = e => {
    const files = [...e.target.files];
    setImageList(current => [...current, ...files]);
    setSubmissionError(undefined);
    e.target.value = null;
    if (submitButton.current) {
      submitButton.current.scrollIntoView();
    }
  };

  const confirmDeleteImg = React.useCallback(() => {
    const fileName = imageToDelete?.name;
    setImageList(prevImages => {
      return prevImages.filter(image => image.name !== fileName);
    });
    setImageErrors(prev => prev.filter(e => e.file !== imageToDelete?.name));
    setSubmissionError(undefined);
    closeConfirm();
  }, [setImageList, imageToDelete, closeConfirm, setImageErrors]);

  const content = React.useMemo(() => {
    if (imageList.length) {
      if (imageList.length === 1) {
        return (
          <div className={classes.imgRow}>
            <div className={classes.imgRowContent}>
              <div className={classes.imgLoaded}>
                <ImagePreview className={classes.imgLoadedPreview} file={imageList[0]} />
                <div className={classes.imgLoadedOverlay}>
                  <span
                    className={classes.imgLoadedOverlayBtn}
                    onClick={() => {
                      setImageToDelete(imageList[0]);
                      openConfirm();
                    }}
                  >
                    <TrashIcon />
                  </span>
                </div>
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <div className={classes.imgList}>
            {imageList.map((image, i) => (
              <div
                key={`img${i}`}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  color: 'red'
                }}
              >
                <div className={classes.imgListItem} key={`${image.name}${i}`}>
                  <div className={classes.imgListItemImg}>
                    <ImagePreview className={classes.imgListItemImgPreview} file={image} />
                  </div>
                  <div className={classes.imgListItemStatus}>
                    <span className="uploaded">Uploaded</span>
                  </div>
                  <div className={classes.imgListItemBtnWrap}>
                    <div
                      className={classes.imgListItemBtn}
                      onClick={() => {
                        setImageToDelete(image);
                        openConfirm();
                      }}
                    >
                      <TrashIcon />
                    </div>
                  </div>
                </div>
                <ImageError key={`err${i}`} errors={imageErrors} file={image.name} />
              </div>
            ))}
          </div>
        );
      }
    } else {
      if (isImagePreviewLoading) {
        return (
          <div className={classes.imgLoadingWrap}>
            <div className={classes.imgLoading}>
              <Loader />
            </div>
          </div>
        );
      } else {
        return (
          <div className={classes.imgPreviewWrap}>
            {imagePlaceholder ? (
              <img className={classes.dialogImg} src={`data:image/jpeg;base64,${imagePlaceholder}`} alt="img" />
            ) : (
              <ImageIcon />
            )}
          </div>
        );
      }
    }
  }, [
    imageList,
    classes.imgRow,
    classes.imgRowContent,
    classes.imgLoaded,
    classes.imgLoadedPreview,
    classes.imgLoadedOverlay,
    classes.imgLoadedOverlayBtn,
    classes.imgList,
    classes.imgListItem,
    classes.imgListItemImg,
    classes.imgListItemImgPreview,
    classes.imgListItemStatus,
    classes.imgListItemBtnWrap,
    classes.imgListItemBtn,
    classes.imgLoadingWrap,
    classes.imgLoading,
    classes.imgPreviewWrap,
    classes.dialogImg,
    openConfirm,
    imageErrors,
    isImagePreviewLoading,
    imagePlaceholder
  ]);

  return (
    <React.Fragment>
      <div className={classes.row}>
        <div className={classes.rowLabel} dangerouslySetInnerHTML={{ __html: question?.question_description }} />
      </div>
      <div className={classes.content}>
        {content}
        {imageList.length === 1 ? <ImageError key={`err${0}`} errors={imageErrors} file={imageList[0].name} /> : null}
        {submissionError && <SubmissionError error={submissionError} />}
      </div>
      <div>
        <div className={classes.uploadButton}>
          <UploadIcon />
          Upload Screenshots
          <input id="upload" type="file" accept="image/*" multiple onChange={onSelectImages} />
        </div>
      </div>
      <div className={classes.submitButtonWrap} ref={submitButton}>
        <Button
          className={classes.submitButton}
          onClick={submitAnswer}
          disabled={isLoadingButton || !imageList.length || !isEmpty(imageErrors)}
          loading={isLoadingButton}
        >
          Submit
        </Button>
      </div>
      <div className={classes.disclaimer}>
        <p className={classes.disclaimerText}>
          Do not upload screenshots or other documents containing sensitive personal information or other information
          that we do not require.
        </p>
      </div>
      <Confirm
        opened={confirmOpened}
        text="Are you sure you want to delete uploaded file?"
        onClose={closeConfirm}
        onYes={confirmDeleteImg}
      />
    </React.Fragment>
  );
};

QuestionImage.propTypes = {
  question: PropTypes.object,
  onSubmit: PropTypes.func,
  isLoadingButton: PropTypes.bool
};

const useStyles = makeStyles({
  row: {
    marginBottom: 24
  },
  rowLabel: {
    fontSize: 16,
    lineHeight: '24px',
    color: '#5C5E6C'
  },
  content: {
    marginBottom: '40px'
  },
  imgRow: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    marginBottom: '8px',
    paddingBottom: '100%',
    height: '0'
  },
  imgRowContent: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%'
  },
  imgLoadingWrap: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    marginBottom: '8px',
    paddingBottom: '100%',
    height: '0'
  },
  imgLoading: {
    width: '100%',
    height: '100%',
    backgroundColor: '#E9E9E9',
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& .loader': {
      position: 'static',
      backgroundColor: 'transparent',
      '& svg': {
        width: 56,
        height: 56
      }
    }
  },
  imgLoaded: {
    width: '100%',
    height: '100%',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    borderRadius: 4
  },
  imgLoadedPreview: {
    maxWidth: '100%',
    maxHeight: '100%'
  },
  imgLoadedOverlay: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,.5)',
    display: 'flex',
    justifyContent: 'flex-end',
    borderRadius: 4
  },
  imgLoadedOverlayBtn: {
    width: 48,
    height: 48,
    marginTop: 16,
    marginRight: 16,
    borderRadius: 4,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,.5)'
  },
  imgPreviewWrap: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid #F2F2F2',
    borderRadius: 4,
    boxSizing: 'border-box'
  },
  imgPreview: {
    maxWidth: '100%',
    maxHeight: '100%'
  },
  imgList: {
    height: '100%',
    overflowY: 'auto'
  },
  imgListItem: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px 0',
    borderTop: '1px solid #E9E9E9',
    '&:first-child': {
      borderTop: 'none'
    }
  },
  imgListItemImg: {
    width: 96,
    height: 96,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
    overflow: 'hidden'
  },
  imgListItemImgPreview: {
    maxWidth: '100%',
    maxHeight: '100%'
  },
  imgListItemStatus: {
    marginLeft: 16,
    marginRight: 16,
    '& span': {
      display: 'inline-flex',
      padding: '4px 16px',
      borderRadius: 4,
      fontSize: 16,
      lineHeight: '24px',
      '&.uploaded': {
        color: '#2B508D',
        backgroundColor: 'rgba(43,80,141, .1)'
      },
      '&.approved': {
        color: '#579862',
        backgroundColor: 'rgba(87,152,98, .1)'
      },
      '&.uploading': {
        color: '#5C5E6C',
        backgroundColor: 'rgba(92,94,108, .1)'
      }
    }
  },
  imgListItemBtnWrap: {
    marginLeft: 'auto',
    minWidth: 24
  },
  imgListItemBtn: {
    width: 24,
    height: 24,
    color: '#B8B8B8',
    '& svg path': {
      fill: 'currentColor'
    }
  },
  '@keyframes loader': {
    from: {
      transform: 'rotate(0deg)'
    },
    to: {
      transform: 'rotate(360deg)'
    }
  },
  imgListItemLoader: {
    animationName: '$loader',
    animationDuration: '1000ms',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'linear',
    display: 'flex'
  },
  uploadButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    background: '#fff',
    border: '1px dashed #A0AFC4',
    boxSizing: 'border-box',
    borderRadius: 4,
    fontFamily: 'inherit',
    fontSize: 16,
    lineHeight: '24px',
    color: '#2B508D',
    fontWeight: 500,
    padding: 23,
    marginBottom: 24,
    position: 'relative',
    '& svg': {
      marginRight: 8,
      '& path': {
        fill: 'currentColor'
      }
    },
    '&:disabled': {
      color: 'rgba(0,0,0,.3)',
      borderColor: '#EEEEEE'
    },
    '& input': {
      position: 'absolute',
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      opacity: 0.001,
      width: '100%',
      height: '100%'
    }
  },
  disclaimer: {
    background: '#F2F2F2',
    border: '1px solid #C4CBD6',
    boxSizing: 'border-box',
    borderRadius: 4,
    padding: 15
  },
  disclaimerText: {
    margin: 0,
    fontSize: 14,
    lineHeight: '24px',
    color: '#5C5E6C',
    marginBottom: 8
  },
  submitButtonWrap: {
    marginTop: 16,
    paddingBottom: 24
  },
  submitButton: {
    width: '100%'
  },
  dialogImgPreview: {
    '& .MuiBackdrop-root': {
      backgroundColor: '#000'
    },
    '& .MuiDialog-paper': {
      overflow: 'visible',
      margin: 0,
      maxWidth: '100%',
      width: '100%',
      height: '100%',
      maxHeight: '100%',
      boxShadow: 'none',
      backgroundColor: 'transparent'
    }
  },
  dialogImgPreviewContent: {
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%'
  },
  dialogImg: {
    maxWidth: '100%',
    maxHeight: '100%'
  }
});

export default QuestionImage;
