import { SUBMISSION_ID_CHANGED, SUBMISSION_CHANGED } from '../actions/types';

const INITIAL_STATE = {
  error: '',
  loading: false,
  submissionID: '',
  submission: {}
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SUBMISSION_ID_CHANGED:
      return { ...state, submissionID: action.payload };
    case SUBMISSION_CHANGED:
      return { ...state, submission: action.payload };
    default:
      return state;
  }
};
