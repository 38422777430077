///////////////////// lOGIN /////////////////////////////////////////
export const EMAIL_CHANGED = 'email_changed';
export const PASSWORD_CHANGED = 'password_changed';
export const USERNAME_CHANGED = 'username_changed';

export const REPEAT_PASSWORD_CHANGED = 'repeat_password_changed';
export const FIRST_NAME_CHANGED = 'first_name_changed';
export const LAST_NAME_CHANGED = 'last_name_changed';
export const PHONE_CHANGED = 'phone_changed';

export const CURRENT_FORM_CHANGED = 'current_form_changed';
export const VERIFICATION_CODE_CHANGED = 'verification_code_changed';
export const ERROR_CHANGED = 'error_changed';
export const PASSTHROUGH_CHANGED = 'passthrough_changed';

///////////////////// REGISTER /////////////////////////////////////////
export const PAYPAL_EMAIL_CHANGED = 'paypal_email_changed';

export const REGISTER_USER_SUCCESS = 'register_user_success';
export const REGISTER_USER_FAIL = 'register_user_fail';
export const REGISTER_USER = 'register_user';
export const REGISTER_ERROR_CHANGED = 'register_error_changed';

///////////////////// FORM /////////////////////////////////////////
export const ANSWERS_CHANGED = 'answers_changed';
export const OPTIONS_CHANGED = 'options_changed';
export const SELECTED_OPTIONS_CHANGED = 'selected_options_changed';
export const SUBMISSION_ID_CHANGED = 'submission_id_changed';
export const SUBMISSION_CHANGED = 'submission_changed';
export const QUESTION_CHANGED = 'question_changed';
export const QUESTION_DESCRIPTIONS_CHANGED = 'question_descriptions_changed';
export const QUESTION_INDEX_CHANGED = 'question_index_changed';
export const QUESTION_LIST_CHANGED = 'question_list_changed';
export const QUESTION_IMAGE_BASE64_CHANGED = 'question_image_base64_changed';
export const IMG_LIST_CHANGED = 'image_list_changed';

///////////////////// PROFILE /////////////////////////////////////////
export const PROFILE_CHANGED = 'profile_changed';
