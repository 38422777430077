import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaFacebookF, FaTwitter } from 'react-icons/fa';
import { makeStyles } from '@material-ui/core/styles';
import LoginWrap from '../UI/LoginWrap';
import springTextLogo from '../../media/spring-text-logo.svg';
import getSpringApp from '../../media/get-spring-app.png';
import getSpringAppQr from '../../media/get-app-qr.png';
import chips from '../../media/chips.png';
import productLabLogoWhite from '../../media/product-lab-logo-white.png';
import fullLogo from '../../media/full-logo.png';
import appscreen1 from '../../media/appscreen1.png';
import appscreen2 from '../../media/appscreen2.png';
import appleLogo from '../../media/apple-logo.png';
import androidLogo from '../../media/android-logo.png';
import { useMediaQuery } from 'react-responsive';

function GraphicWrapper({ children }) {
  const classes = useStyles();

  function onAppGetClick() {
    window.open('https://prodlab.app.link/app', '_blank');
  }

  return (
    <div className={classes.container}>
      <NavBar />
      <div className={classes.blueBackgroundWrapper} onClick={onAppGetClick}>{children} </div>
      <Row1 />
      <Row2 />
      <Testimonials />
      <DownloadTheApp />
      <Footer />
    </div>
  );
}

function Row1() {
  const isDesktop = useMediaQuery({ minWidth: 1000 });

  return (
    <div
      style={{
        display: 'flex',
        flexFlow: isDesktop ? 'row' : 'column',
        justifyContent: 'space-between',
        padding: isDesktop ? '48px' : '24px',
        borderRadius: 10,
        backgroundColor: '#31343D',
        height: isDesktop ? '600px' : 'auto',
        color: 'white',
        marginTop: isDesktop ? '48px' : '24px'
      }}
    >
      <div
        style={{
          display: 'flex',
          width: isDesktop ? '48%' : '100%',
          order: isDesktop ? 1 : 2,
          height: '100%',
          flexFlow: 'column'
        }}
      >
        <h1
          style={{
            fontSize: isDesktop ? '60px' : '40px',
            marginTop: isDesktop ? '0' : '24px',
            flex: 1,
            fontFamily: 'Anton'
          }}
        >
          Fast & Easy Payment
        </h1>
        <p style={{ fontSize: '1.45rem' }}>
          Boost your earnings by taking surveys each week with ProductLab! Once you download the app you will create a
          profile and begin receiving weekly reward offers directly in your inbox. You can let your earnings accumulate
          and cash out anytime via PayPal or Venmo!
        </p>
        {isDesktop ? (
          <RowWrapper>
            <div
              style={{
                display: 'flex',
                flexFlow: 'column',
                alignItems: 'center',
                width: '33%'
              }}
            >
              <span>Over</span>
              <span style={{ fontWeight: 'bold', fontSize: '24px' }}>1M+</span>
              <span>Payouts</span>
            </div>
            <div
              style={{
                display: 'flex',
                flexFlow: 'column',
                alignItems: 'center',
                width: '33%'
              }}
            >
              <span>Over</span>
              <span style={{ fontWeight: 'bold', fontSize: '24px' }}>150k</span>
              <span>Contributors</span>
            </div>
            <div
              style={{
                display: 'flex',
                flexFlow: 'column',
                alignItems: 'center',
                width: '33%'
              }}
            >
              <span>Avg.</span>
              <span style={{ fontWeight: 'bold', fontSize: '24px' }}>4.9★</span>
              <span>App store Rating</span>
            </div>
          </RowWrapper>
        ) : (
          <RowWrapper>
            <div
              style={{
                display: 'flex',
                flexFlow: 'column',
                alignItems: 'center',
                width: '100%',
                margin: '16px 0'
              }}
            >
              <span>Over</span>
              <span style={{ fontWeight: 'bold', fontSize: '24px' }}>1M+</span>
              <span>Payouts</span>
            </div>
            <div
              style={{
                display: 'flex',
                flexFlow: 'column',
                alignItems: 'center',
                width: '100%',
                margin: '16px 0'
              }}
            >
              <span>Over</span>
              <span style={{ fontWeight: 'bold', fontSize: '24px' }}>150k</span>
              <span>Contributors</span>
            </div>
            <div
              style={{
                display: 'flex',
                flexFlow: 'column',
                alignItems: 'center',
                width: '100%',
                margin: '16px 0'
              }}
            >
              <span>Avg.</span>
              <span style={{ fontWeight: 'bold', fontSize: '24px' }}>4.9★</span>
              <span>App store Rating</span>
            </div>
          </RowWrapper>
        )}
      </div>

      <div
        style={{
          display: 'flex',
          width: isDesktop ? '48%' : '100%',
          order: isDesktop ? 2 : 1,
          height: '100%',
          flexFlow: 'column'
        }}
      >
        <img
          src={appscreen1}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            borderRadius: 6
          }}
        />
      </div>
    </div>
  );
}

function Row2() {
  const isDesktop = useMediaQuery({ minWidth: 1000 });

  return (
    <div
      style={{
        display: 'flex',
        flexFlow: isDesktop ? 'row' : 'column',
        justifyContent: 'space-between',
        padding: isDesktop ? '48px' : '24px',
        borderRadius: 10,
        backgroundColor: '#31343D',
        height: isDesktop ? '600px' : 'auto',
        color: 'white',
        marginTop: isDesktop ? '48px' : '24px'
      }}
    >
      <div
        style={{
          display: 'flex',
          width: isDesktop ? '48%' : '100%',
          order: 2,
          height: '100%',
          flexFlow: 'column'
        }}
      >
        <h1
          style={{
            fontSize: isDesktop ? '60px' : '40px',
            marginTop: isDesktop ? '0' : '24px',
            flex: 1,
            fontFamily: 'Anton'
          }}
        >
          Control Your Data
        </h1>
        <p style={{ fontSize: isDesktop ? '1.85rem' : '1.45rem' }}>
          Take control of your data and how you monetize it. We only collect data you share directly with us and all
          data is anonymized and stripped of personal identifiers. We will never share your name or email for marketing
          purposes and you can delete your account and data at any time.
        </p>
      </div>

      <div
        style={{
          display: 'flex',
          width: isDesktop ? '48%' : '100%',
          order: 1,
          height: '100%',
          flexFlow: 'column'
        }}
      >
        <img
          src={appscreen2}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            borderRadius: 6
          }}
        />
      </div>
    </div>
  );
}

function NavBar() {
  const isDesktop = useMediaQuery({ minWidth: 1000 });
  return (
    <div
      style={{
        height: '80px',
        // width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: isDesktop ? '0 48px' : '0 24px'
      }}
    >
      {isDesktop ? (
        <div style={{ textDecoration: 'none', color: 'white', fontSize: '16px', width: '300px' }}>
          {/* <a>About</a>
          <a style={{ marginLeft: '24px' }}>Contact</a> */}
        </div>
      ) : (
        <div></div>
      )}
      <img
        src={fullLogo}
        style={{
          height: '30px',
          objectFit: 'contain'
        }}
      />
      {isDesktop ? (
        <div style={{ display: 'flex', width: '300px', fontSize: '14px' }}>
          <a
            href="https://apps.apple.com/us/app/productlab/id1557610246"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'white',
              color: 'black',
              padding: '12px 16px',
              borderRadius: '100px',
              textDecoration: 'none',
              margin: '0 4px'
            }}
          >
            <img src={appleLogo} style={{ width: '20px' }} />
            <span style={{ fontWeight: 'bold' }}>App Store</span>
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=ai.productlab&hl=en&gl=US"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'white',
              color: 'black',
              padding: '12px 16px',
              borderRadius: '100px',
              textDecoration: 'none',
              margin: '0 4px'
            }}
          >
            <img src={androidLogo} style={{ width: '20px' }} />
            <span style={{ fontWeight: 'bold' }}>Google Play</span>
          </a>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
}

function Testimonials() {
  const isDesktop = useMediaQuery({ minWidth: 1000 });
  return (
    <div
      style={{
        display: 'flex',
        flexFlow: 'column',
        alignItems: 'center',
        padding: isDesktop ? '48px' : '24px',
        borderRadius: 10,
        backgroundColor: '#282EDA',
        minHeight: isDesktop ? '600px' : 'auto',
        color: 'white',
        marginTop: isDesktop ? '48px' : '24px',
        textAlign: 'center'
      }}
    >
      <h1 style={{ color: 'white', fontFamily: 'Anton', fontSize: '50px', marginBottom: '24px' }}>
        Don’t take our word for it
      </h1>
      <h2 style={{ fontSize: '28px', marginBottom: '4px', marginTop: 0 }}>4.9★</h2>
      <span style={{ fontSize: '18px', marginBottom: '24px' }}>5k+ reviews</span>
      <div style={{ display: 'flex', flexFlow: isDesktop ? 'row wrap' : 'column', justifyContent: 'space-around' }}>
        <Testimonial
          title="I’m really loving this survey app!!"
          text="Easy, and quick surveys that pay out right to my PayPal!! I love it!!"
          user="HeatherSt1"
        />
        <Testimonial
          title="👍🏼👍🏼💯"
          text="App is great, they just paid me out, and I took nothing but the surveys. The other review thing takes like a day but the withdrawal comes like a minute after u put in your PayPal email great app."
          user="Lukadon77"
        />
        <Testimonial
          title="Legitimate way to add to your income"
          text="Always easy to report earnings that have little or no identifiable information that could possibly be misused. Trustworthy and I’ve never had issues with payouts."
          user="rico1968rico"
        />
      </div>
    </div>
  );
}

function Testimonial({ title, text, user }) {
  const isDesktop = useMediaQuery({ minWidth: 1000 });
  return (
    <div
      style={{
        display: 'flex',
        flexFlow: 'column',
        alignItems: 'center',
        width: isDesktop ? '400px' : 'auto',
        padding: '24px',
        backgroundColor: '#1D22B1',
        borderRadius: 8,
        margin: 8
      }}
    >
      <span
        style={{
          color: 'white',
          fontSize: '28px',
          fontWeight: 'bold',
          marginBottom: '16px'
        }}
      >
        {title}
      </span>
      <span
        style={{
          color: '#D0D2D0',
          fontSize: '18px',
          marginBottom: '16px'
        }}
      >
        {text}
      </span>
      <span
        style={{
          color: '#03DAC6',
          fontSize: '16px',
          fontWeight: 'bold'
        }}
      >
        -{user}
      </span>
    </div>
  );
}

function RowWrapper({ children }) {
  const isDesktop = useMediaQuery({ minWidth: 1000 });

  return isDesktop ? (
    <div
      style={{
        display: 'flex',
        flexFlow: 'row',
        width: '100%',
        marginTop: '48px',
        backgroundColor: '#222735',
        textAlign: 'center',
        borderRadius: 6,
        padding: '24px 0'
      }}
    >
      {children}
    </div>
  ) : (
    <div
      style={{
        display: 'flex',
        flexFlow: 'column',
        width: '100%',
        marginTop: '48px',
        backgroundColor: '#222735',
        textAlign: 'center',
        borderRadius: 6,
        padding: '24px 0'
      }}
    >
      {children}
    </div>
  );
}

function Footer() {
  const classes = useStyles();

  return (
    <div className={classes.footerWrapper}>
      <span className={classes.copywrite}>© Product Lab AI, Inc. All Rights Reserved</span>
      <div className={classes.footerLinksWrapper}>
        <a className={classes.footerLink} href="https://productlab.ai/privacy">
          Privacy Policy
        </a>
        <a className={classes.footerLink} href="https://productlab.ai/terms">
          Terms & Conditions
        </a>
        <a className={classes.footerLink} href="https://productlab.ai/#get-in-touch">
          Do Not Sell Info
        </a>
        <a className={classes.footerLink} href="https://productlab.ai/#get-in-touch">
          Contact Us
        </a>
        <a className={classes.footerLink} href="https://app.productlab.ai/blog/">
          Blog
        </a>
      </div>
      <div className={classes.socialLinksWrapper}>
        <a href="https://www.facebook.com/theproductlab" target="_blank" rel="noopener noreferrer">
          <FaFacebookF className={classes.footerLink} />
        </a>
        <a href="https://twitter.com/productlabai" target="_blank" rel="noopener noreferrer">
          <FaTwitter className={classes.footerLink} />
        </a>
      </div>
    </div>
  );
}

function StoreButtons() {
  const isDesktop = useMediaQuery({ minWidth: 1000 });
  return (
    <div style={{ display: 'flex' }}>
      <a
        href="https://apps.apple.com/us/app/productlab/id1557610246"
        target="_blank"
        rel="noopener noreferrer"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'white',
          color: 'black',
          padding: '16px 20px',
          borderRadius: '100px',
          textDecoration: 'none',
          margin: isDesktop ? '0 16px' : '0 4px'
        }}
      >
        <img src={appleLogo} style={{ width: '20px' }} />
        <span style={{ fontWeight: 'bold' }}>App Store</span>
      </a>
      <a
        href="https://play.google.com/store/apps/details?id=ai.productlab&hl=en&gl=US"
        target="_blank"
        rel="noopener noreferrer"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'white',
          color: 'black',
          padding: '16px 20px',
          borderRadius: '100px',
          textDecoration: 'none',
          margin: isDesktop ? '0 16px' : '0 4px'
        }}
      >
        <img src={androidLogo} style={{ width: '20px' }} />
        <span style={{ fontWeight: 'bold' }}>Google Play</span>
      </a>
    </div>
  );
}

function DownloadTheApp() {
  const isDesktop = useMediaQuery({ minWidth: 1000 });
  return (
    <div
      style={{
        padding: isDesktop ? '48px' : '24px',
        marginTop: '48px',
        marginBottom: '48px',
        display: 'flex',
        flexFlow: 'column',
        justifyContent: 'center',
        alignItems: 'center'
        // backgroundColor: '#1A1E28'
      }}
    >
      <h1
        style={{
          fontSize: '50px',
          fontWeight: 'bold',
          fontFamily: 'Anton',
          margin: 0,
          color: 'white',
          textAlign: 'center'
        }}
      >
        Download the app
      </h1>
      <div style={{ marginTop: '24px' }}>
        <StoreButtons />
      </div>
    </div>
  );
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexFlow: 'column',
    width: '100%',
    backgroundColor: '#161C31',
    padding: 10,
    boxSizing: 'border-box',
    // height: '100vh',
    minHeight: '100vh',
    '@media (max-width: 768px)': {
      height: 'auto',
      minHeight: '100vh'
    }
  },
  blueBackgroundWrapper: {
    display: 'flex',
    // alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    width: '100%',
    height: '90%',
    minHeight: '90vh',
    backgroundImage: 'url(/chips.png) , linear-gradient(180deg, #0114BF 0%, #4663F6 100%)',
    backgroundSize: 'contain',
    borderRadius: 10
  },
  mainContentWrapper: {
    display: 'flex',
    flexFlow: 'row',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    padding: '0 50px',
    '@media (max-width: 768px)': {
      flexFlow: 'column',
      padding: '0 24px'
    }
  },
  leftItemWrapper: {
    display: 'flex',
    flexFlow: 'column',
    width: '50%',
    order: 1,
    '@media (max-width: 768px)': {
      width: '100%',
      order: 2
    }
  },
  rightItemWrapper: {
    display: 'flex',
    width: '50%',
    height: '100%',
    order: 2,
    '@media (max-width: 768px)': {
      width: '100%',
      order: 1
    }
  },
  logoText: {
    // width: '150px',
    width: 50,
    position: 'relative',
    top: 0,
    left: 0,
    '@media (max-width: 768px)': {
      // width: '50px',
      width: 30,
      position: 'absolute',
      top: 30,
      left: 30
    }
  },
  splashImage: {},
  getAppButton: {
    backgroundColor: 'white',
    color: 'black',
    border: 0,
    borderRadius: 6,
    padding: '10px 20px',
    fontWeight: 600,
    margin: '20px 0',
    display: 'none',
    '@media (max-width: 768px)': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  },
  qrWrapper: {
    display: 'flex',
    flexFlow: 'row',
    backgroundColor: '#0215C0',
    padding: 4,
    marginTop: 10,
    borderRadius: 10,
    width: 'max-content',
    alignItems: 'center',
    '@media (max-width: 768px)': {
      display: 'none'
    }
  },
  header: {
    font: "40px/48px 'Domine', serif",
    color: '#333646',
    margin: 0,
    fontWeight: 70
  },
  qr: {
    width: 200,
    marginTop: 20
  },
  footerWrapper: {
    width: '100%',
    minHeight: 60,
    display: 'flex',
    alignItems: 'center',
    flexFlow: 'row wrap',
    '@media (max-width: 768px)': {
      margin: '10px 0',
      flexFlow: 'column'
    }
  },
  footerLinksWrapper: {
    display: 'flex',
    justifyContent: 'space-around',
    flexFlow: 'row wrap',
    order: 2,
    '@media (max-width: 768px)': {
      order: 1
    }
  },
  footerLink: {
    margin: '8px 10px',
    color: 'white',
    textDecoration: 'none'
  },
  copywrite: {
    flex: 1,
    order: 1,
    color: '#A4A4A4',
    margin: '8px 10px',
    '@media (max-width: 768px)': {
      order: 3
    }
  },
  socialLinksWrapper: {
    display: 'flex',
    flexFlow: 'row',
    order: 3,
    '@media (max-width: 768px)': {
      order: 2
    }
  }
});

export default GraphicWrapper;
