import 'core-js/es/promise/finally';
import 'core-js/es/object/assign';

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { CaptureConsole } from '@sentry/integrations';
import AppMain from './AppMain';
import * as serviceWorker from './serviceWorker';

const sentryDSN = process.env.REACT_APP_SENTRY_DSN;

if (typeof sentryDSN === 'string' && sentryDSN.length > 0) {
  Sentry.init({
    dsn: sentryDSN,
    integrations: [
      new Integrations.BrowserTracing(),
      new CaptureConsole({
        levels: ['error']
      })
    ],
    tracesSampleRate: 0.1,
    beforeSend(event, hint) {
      if (event.message && event.message.includes('NotLoadingAPIFromGoogleMapsError')) {
        return null;
      }
      return event;
    }
  });
}

ReactDOM.render(<AppMain />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
