import React from 'react';

import { ReactComponent as LogoIcon } from '../../media/icons/logo.svg';
import { makeStyles } from '@material-ui/core/styles';
import { getFullSiteUrl } from '../../utils/urls';
import { Link, Redirect } from 'react-router-dom';
import { getToken } from '../../core/auth';

export default function Welcome() {
  const classes = useStyles();
  const token = getToken();
  if (token) {
    return <Redirect to="/app" />;
  }

  return (
    <div className={classes.page}>
      <LogoIcon width="80" height="80" />
      <h1 className={classes.title}>Welcome to ProductLab</h1>
      <h2 className={classes.subTitle}>Earn cash taking surveys</h2>
      <div className={classes.buttonsGroup}>
        <Link to="/register" className={`${classes.button} ${classes.outlined}`}>
          Sign Up
        </Link>
        <Link to="/login" className={classes.button}>
          Log in
        </Link>
      </div>
      <p className={classes.footer}>
        By participating you agree to our&nbsp;
        <a href={getFullSiteUrl('/privacy')} target="_blank" rel="noopener noreferrer">
          Privacy Policy
        </a>
        &nbsp;and&nbsp;
        <a href={getFullSiteUrl('/terms')} target="_blank" rel="noopener noreferrer">
          Terms &amp; Conditions
        </a>
        .
      </p>
    </div>
  );
}

const useStyles = makeStyles({
  page: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 20px',
    textAlign: 'center',
    color: '#333646'
  },
  title: {
    fontSize: 24,
    margin: 0,
    marginTop: 32,
    marginBottom: 8
  },
  subTitle: {
    fontSize: 13,
    margin: 0,
    marginBottom: 40,
    color: '#5C5E6C'
  },
  buttonsGroup: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    '& *:not(:last-child)': {
      marginBottom: 10
    }
  },
  button: {
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 500,
    color: '#fff',
    textDecoration: 'none',
    textAlign: 'center',
    padding: '12px',
    borderRadius: 4,
    border: '1px solid #579862',
    transition: 'opacity .3s ease',
    boxSizing: 'border-box',
    backgroundColor: '#579862',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#4E8958'
    },
    '&:active': {
      color: '#fff',
      backgroundColor: '#467B4F'
    }
  },
  outlined: {
    backgroundColor: 'transparent',
    color: '#579862'
  },
  footer: {
    color: '#5C5E6C',
    fontSize: 13,

    '& a': {
      color: '#2d2f34'
    }
  }
});
