import React from 'react';

import Pager from './Pager';

const validateElement = (element, value) => {
  if (element.isRequired && !value) {
    return 'This field is required.';
  }
  if (element.type === 'number') {
    const numberValue = parseInt(value);
    if (Number.isNaN(numberValue)) {
      return `This answer must be a number.`;
    }
    if (typeof element.min === 'number' && numberValue < element.min) {
      return `This answer be at least ${element.min}.`;
    }
    if (typeof element.max === 'number' && numberValue > element.max) {
      return `This answer must be less than or equal to ${element.max}.`;
    }
  } else if (element.type === 'text' && (value || '').length < 3) {
    return 'Please provide a longer answer.';
  }
};

const Survey = ({
  surveyModel,
  data,
  onDataChange,
  currentPage,
  onCurrentPageChange,
  lastPageAction,
  onLastPageActionChange,
  onComplete,
  errors,
  onErrorsChange
}) => {
  const validate = () => {
    const page = surveyModel.pages[currentPage];
    const errors = {};
    for (const element of page.elements) {
      if (element.isRequired && !data[element.name]) {
        errors[element.name] = 'This field is required.';
      }
      const result = validateElement(element, data[element.name]);
      if (result) {
        errors[element.name] = result;
      }
    }
    if (Object.keys(errors).length > 0) {
      return errors;
    }
  };
  const handleCurrentPageChange = (newCurrentPage, pageAction) => {
    if (pageAction === 'prev') {
      onLastPageActionChange(pageAction);
      onCurrentPageChange(newCurrentPage);
      onErrorsChange({});
    } else {
      const errors = validate();
      if (errors) {
        onErrorsChange(errors);
      } else {
        onLastPageActionChange(pageAction);
        onCurrentPageChange(newCurrentPage);
        if (pageAction === 'complete') {
          onComplete();
        }
      }
    }
  };

  return (
    <Pager
      surveyModel={surveyModel}
      data={data}
      onDataChange={onDataChange}
      errors={errors}
      currentPage={currentPage}
      onCurrentPageChange={handleCurrentPageChange}
      lastPageAction={lastPageAction}
    />
  );
};

export default Survey;
