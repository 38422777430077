import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { getFullSiteUrl } from '../../utils/urls';

const Privacy = () => {
  const classes = useStyles();

  return (
    <div className={`policy-block ${classes.copyright}`}>
      <p>
        By participating you agree to our&nbsp;
        <a href={getFullSiteUrl('/privacy')} target="_blank" rel="noopener noreferrer">
          Privacy Policy
        </a>
        &nbsp;and&nbsp;
        <a href={getFullSiteUrl('/terms')} target="_blank" rel="noopener noreferrer">
          Terms &amp; Conditions
        </a>
        .
      </p>
    </div>
  );
};

const useStyles = makeStyles({
  copyright: {
    fontSize: '14px',
    lineHeight: '24px',
    textAlign: 'center',
    marginTop: 'auto',
    paddingTop: 24,
    paddingBottom: 24,
    '@media (max-width: 999px)': {
      paddingBottom: 16
    },
    '& p': {
      margin: '0 auto',
      maxWidth: 303
    },
    '& a': {
      textDecoration: 'none'
      // color: '#14315C'
    }
  }
});

export default Privacy;
