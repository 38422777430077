import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import MaterialUiSwitch from '@material-ui/core/Switch';

const StyledSwitch = withStyles({
  root: {
    width: 40,
    height: 24,
    padding: 0
  },
  switchBase: {
    padding: 2,
    color: '#14315C',
    boxShadow: 'none',
    transition: 'color .3s ease, transform .3s ease',
    '&$checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + $track': {
        backgroundColor: '#14315C',
        opacity: 1
      }
    }
  },
  thumb: {
    width: 20,
    height: 20,
    transition: 'background-color .3s ease'
  },
  track: {
    borderRadius: 16,
    border: '1px solid #14315C',
    backgroundColor: '#fff',
    opacity: 1,
    boxSizing: 'border-box',
    transition: 'background-color .3s ease'
  },
  checked: {}
})(MaterialUiSwitch);

const Switch = ({ color = 'primary', ...rest }) => {
  return <StyledSwitch disableRipple color={color} {...rest} />;
};

Switch.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary', 'inherit']),
  loading: PropTypes.bool
};

export { Switch };
