import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import MaterialUiRadio from '@material-ui/core/Radio';
import MaterialUiCheckbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { ReactComponent as CheckIcon } from '../../media/icons/check.svg';

const useRadioStyles = makeStyles({
  root: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent'
    },
    '&.Mui-checked ~ .MuiFormControlLabel-label': {
      paddingRight: 56,
      backgroundColor: '#2B508D',
      borderColor: '#2B508D',
      color: '#fff',
      minWIdth: 0
    }
  },
  icon: {
    width: 0,
    height: 0
  },
  checkedIcon: {
    position: 'absolute',
    width: 24,
    height: 24,
    right: 16,
    top: '50%',
    marginTop: -12
  }
});

const StyledRadio = props => {
  const classes = useRadioStyles();

  return (
    <MaterialUiRadio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={
        <span className={classes.checkedIcon}>
          <CheckIcon />
        </span>
      }
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
};

const StyledCheckbox = props => {
  const classes = useRadioStyles();

  return (
    <MaterialUiCheckbox
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={
        <span className={classes.checkedIcon}>
          <CheckIcon />
        </span>
      }
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
};

const useLabelClasses = makeStyles({
  root: {
    margin: '16px 0 0',
    position: 'relative',
    '& .MuiFormControlLabel-label': {
      flexGrow: 1,
      minWIdth: 0,
      alignItems: 'center',
      background: '#fff',
      border: '1px solid #C4CBD6',
      boxSizing: 'border-box',
      borderRadius: 4,
      padding: '11px 15px'
    }
  }
});

const Radio = ({ asCheckbox, ...rest }) => {
  const classes = useLabelClasses();

  return (
    <FormControlLabel
      className={classes.root}
      control={asCheckbox ? <StyledCheckbox /> : <StyledRadio />}
      labelPlacement="start"
      {...rest}
    />
  );
};

Radio.propTypes = {
  asCheckbox: PropTypes.bool
};

export { Radio };
