import validator from 'validator';

export const validateRequired = value => {
  if (!value || (typeof value === 'string' && value.length === 0)) return 'Required';
  return undefined;
};

export const validatePhone = value => {
  if (validator.isMobilePhone(value) && value.length === 10) return undefined;
  return 'Invalid phone number';
};

export const validateEmail = email => {
  if (validator.isEmail(email)) return undefined;
  return 'Invalid email';
};

export const composeValidators = (...args) => value => {
  for (const validator of args) {
    const error = validator(value);

    if (error) {
      return error;
    }
  }

  return undefined;
};
