import { find } from 'lodash';

const regionTypes = ['locality', 'administrative_area_level_1', 'country'];

export const geocode = (lat, lng, handler) => {
  if (!lat || !lng || (lat === 0 && lng === 0)) return;

  const geocoder = new window.google.maps.Geocoder();
  const OK = window.google.maps.GeocoderStatus.OK;
  geocoder.geocode(
    {
      location: {
        lat,
        lng
      }
    },
    (results, status) => {
      if (status === OK && results.length) {
        handler(regionFromResult(results[0]));
      } else {
        handler(undefined);
      }
    }
  );
};

export const regionFromResult = result =>
  regionTypes.reduce(
    (acc, regionType) =>
      `${acc}${!!acc ? ', ' : ''}${
        find(result.address_components, c => c.types.includes(regionType))?.short_name || 'Unknown'
      }`,
    ''
  );
