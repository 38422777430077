import { TextField } from './TextField';
import React, { useState } from 'react';
import { Button } from './Button';
import api from '../../core/api';
import isString from 'lodash/isString';
import { handleError } from '../../utils/errors';
import FormError from './FormError';
import { makeStyles } from '@material-ui/core/styles';

const STEP_SEND_CODE = 'send-code';
const STEP_CONFIRM_PHONE = 'confirm-phone';

async function makeApiCall({ loading, setLoading, setError, onCompleted, handler }) {
  if (loading) {
    return;
  }
  setLoading(true);
  setError('');
  try {
    await handler();
  } catch (e) {
    if (isString(e.response?.detail)) {
      setError(e.response?.detail);
    } else {
      handleError(e);
    }
    return;
  } finally {
    setLoading(false);
  }

  onCompleted();
}

function SendCodeForm({ onCompleted, loading, setLoading, setError, isVerified }) {
  const onSubmit = () =>
    makeApiCall({
      loading,
      setLoading,
      setError,
      onCompleted,
      handler: () => api.post('/users/me/verify-phone-number/')
    });

  return (
    <>
      <Button type="submit" onClick={onSubmit} loading={loading} disabled={isVerified}>
        {isVerified ? 'Phone verified' : 'Send confirmation SMS'}
      </Button>
    </>
  );
}

function ConfirmPhoneForm({ onCompleted, loading, setLoading, setError }) {
  const [code, setCode] = useState('');
  const classes = useStyles();

  const onSubmit = () =>
    makeApiCall({
      loading,
      setLoading,
      setError,
      onCompleted,
      handler: () => api.post('/users/me/verify-phone-number/confirm/', { code })
    });

  return (
    <>
      <TextField
        placeholder="Enter code from SMS"
        onChange={e => setCode(e.target.value)}
        value={code}
        className={classes.formInput}
      />
      <Button type="submit" onClick={onSubmit} loading={loading}>
        Confirm phone number
      </Button>
    </>
  );
}

function Form({ step, setStep, onCompleted, isVerified, ...props }) {
  switch (step) {
    case STEP_SEND_CODE:
      return <SendCodeForm {...props} onCompleted={() => setStep(STEP_CONFIRM_PHONE)} isVerified={isVerified} />;
    case STEP_CONFIRM_PHONE:
      return (
        <ConfirmPhoneForm
          {...props}
          onCompleted={() => {
            onCompleted();
            setStep(STEP_SEND_CODE);
          }}
        />
      );
    default:
      return null;
  }
}

export default function PhoneConfirmationForm({ onCompleted, isVerified }) {
  const [step, setStep] = useState(STEP_SEND_CODE);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  return (
    <>
      <Form
        step={step}
        setStep={setStep}
        setError={setError}
        loading={loading}
        setLoading={setLoading}
        onCompleted={onCompleted}
        isVerified={isVerified}
      />
      {error ? <FormError>{error}</FormError> : null}
    </>
  );
}

const useStyles = makeStyles({
  formInput: {
    marginBottom: 12
  }
});
