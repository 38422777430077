import React from 'react';
import PrivacyTermsWrap from './PrivacyTermsWrap';

const Terms = () => {
  return (
    <PrivacyTermsWrap>
      <h1>Terms of Use</h1>
      <p className="_effective-date">Effective Date: January 01, 2021</p>
      <p>
        The following terms and conditions (the “Agreement”) govern all use of the Product Lab AI, Inc app and
        ProductLab.ai website (together, the “Site”) and the products and services available on or at the Site (taken
        together with the Site, the “Service”). The Service is owned and operated by Product Lab AI, Inc (“ProductLab”).
        The Service is offered subject to your (the “User”) acceptance without modification of all of the terms and
        conditions contained herein and all other operating rules, policies and procedures that may be published from
        time to time on the Site by ProductLab.
      </p>
      <p>
        Please take time and carefully read through this Agreement. You may also visit{' '}
        <a href="https://productlab.ai/privacy/">https://productlab.ai/privacy/</a> to learn about our privacy related
        policies and procedures.
      </p>
      <p>
        Our privacy policy explains how we collect, use and share your information that you provide when you access the
        Service. By using the Service, you consent to ProductLab’s collection, use, and sharing of your information as
        set forth in our privacy policy.
      </p>
      <p>
        THESE TERMS CONTAIN A MANDATORY INDIVIDUAL ARBITRATION AND CLASS ACTION/JURY TRIAL WAIVER PROVISION THAT
        REQUIRES THE USE OF ARBITRATION ON AN INDIVIDUAL BASIS TO RESOLVE DISPUTES, RATHER THAN JURY TRIALS OR CLASS
        ACTIONS.
      </p>
      <p>
        BY USING OR ACCESSING ANY PART OF THE SERVICE, YOU AGREE TO ALL OF THE TERMS AND CONDITIONS CONTAINED HEREIN. IF
        YOU DO NOT AGREE, DO NOT USE OR ACCESS THE SERVICE.
      </p>
      <h2>Access</h2>
      <p>
        Subject to the terms and conditions of this Agreement and compliance therewith, ProductLab will provide User
        with the Service – for use by User only in accordance with all documentation and other written instructions
        provided by ProductLab (including, without limitation, as may be sent via email, account notification or posted
        by ProductLab on the Site).{' '}
      </p>
      <p>
        ProductLab may change, suspend or discontinue the Service at any time, including the availability of any
        feature, database, or content. ProductLab may also impose limits on certain features of the Service or restrict
        User’s access to parts or all of the Service without notice or liability. ProductLab reserves the right, at its
        discretion, to modify this Agreement at any time by posting a notice on the Site, or by sending User a notice
        via email or or account notification. User shall be responsible for reviewing and becoming familiar with any
        such modifications. Use of the Service by User following such notification constitutes User’s acceptance of the
        terms and conditions of this Agreement as modified.{' '}
      </p>
      <p>
        User certifies to ProductLab that if User is an individual (i.e., not a corporation), User is at least 13 years
        of age (provided that, if User is located in a state in which 13 is under the age of majority, then User
        represents they have the legal consent of a legal guardian to enter this Agreement).{' '}
      </p>
      <p>
        User also certifies that they are legally permitted to use the Service and take full responsibility for the
        selection and use of the Service. This Agreement is void where prohibited by law, and the right to access the
        Service is revoked in such jurisdictions.
      </p>
      <p>
        User acknowledges that the Service will sometimes be down for maintenance and updates. After these updates you
        may find new features added or existing features removed.
      </p>
      <p>
        User shall be responsible for obtaining and maintaining any equipment or ancillary services needed to connect
        to, access the Service, including, without limitation, modems, hardware, software, and long distance or local
        telephone service. User shall be responsible for ensuring that such equipment or ancillary services are
        compatible with the Service.
      </p>
      <h2>Site Content</h2>
      <p>
        User agrees that all content (e.g. data, metrics, and the like) and materials (collectively, “Content”)
        delivered via the Service or otherwise made available by ProductLab at the Site are protected by copyrights,
        trademarks, service marks, patents, trade secrets or other proprietary rights and laws. Except as expressly
        authorized by ProductLab in writing, User agrees not to sell, license, rent, modify, distribute, copy,
        reproduce, transmit, publicly display, publicly perform, publish, adapt, edit or create derivative works from
        such materials or content. However, User may print or download a reasonable number of copies of the materials or
        content at this Site for User’s own informational purposes; provided, that User retain all copyright and other
        proprietary notices contained therein. Reproducing, copying or distributing any content, materials or design
        elements on the Site for any other purpose is strictly prohibited without the express prior written permission
        of ProductLab.{' '}
      </p>
      <p>
        Use of Content for any purpose not expressly permitted in this Agreement is prohibited. Any rights not expressly
        granted herein are reserved.
      </p>
      <h2>User General Content</h2>
      <p>
        User acknowledges and agrees that if User uses the Site to contribute Content to the Site (“User Content”),
        ProductLab (and its successors) is hereby granted a non-exclusive, worldwide, perpetual, irrevocable,
        royalty-free, transferable right to display, distribute, modify, reproduce and otherwise fully exploit such User
        Content (including all related intellectual property rights), for any purpose, and to allow others to do so.
        ProductLab reserves the right to remove any User Content from the Site at any time, for any reason (including,
        but not limited to, upon receipt of claims or allegations from third parties or authorities relating to such
        User Content), or for no reason at all.
      </p>
      <p>
        User represents and warrants that (i) it has all right, power and authority, to contribute all of their User
        Content to the Site and grant the foregoing license, (ii) such content will not infringe or otherwise violate
        the rights of any third party, (iii) User’s contribution will not be in conflict with any obligations User has
        to any third party, (iv) such and content will at all times be accurate, truthful and up-to-date (and if not,
        User will immediately correct such issue) and (iv) neither such content, nor the contribution thereof, will
        violate any laws or regulations.
      </p>
      <h2>Panelist Content; Panelist Fees</h2>
      <p>
        To the extent User is engaged by ProductLab to provide data and other information (such as survey responses or
        photos) (collectively “Panelist Data”) – they will be a “Panelist”. ProductLab will compensate Panelist for the
        Panelist Data as set forth on the Site (or otherwise specified by ProductLab in writing (“Panelist Fees”).
        Panelist Fees will be credited to Panelist's account within thirty (30) days that it is earned. The Panelist
        must initiate a withdrawl of the Fees. Fees will be paid by a third-party services such as PayPal. If the
        Panalist does not withdraw any part of their balance within 30 days of it being credited to their account, it
        will expire. The Panelist Fees will be the sole compensation for Panelists provider of the Panelist Data.
      </p>
      <p>
        Panelist represents and warrants that (i) it has all right, power and authority, to contribute all of their
        Panelist Content to the Site and grant the foregoing license, (ii) such content will not infringe or otherwise
        violate the rights of any third party, (iii) Panelist’s contribution will not be in conflict with any
        obligations Panelist has to any third party, (iv) such and content will at all times be accurate, truthful and
        up-to-date (and if not, Panelist will immediately correct such issue) and (iv) neither such content, nor the
        contribution thereof, will violate any laws or regulations.
      </p>
      <h2>Registration; Security</h2>
      <p>
        As a condition to using certain features of the Service, User may be required to (i) provide email, phone number
        or Facebook credentials (“ProductLab User ID”). User shall provide ProductLab with accurate, unique, complete,
        and updated registration information. Failure to do so shall constitute a breach of this Agreement, which may
        result in immediate termination of User’s account and/or possible legal action.
      </p>
      <p>
        User may not (i) select or use as a ProductLab User ID a name of another person with the intent to impersonate
        that person; or (ii) use as a ProductLab User ID a name subject to any rights of a person other than User
        without appropriate authorization. ProductLab reserves the right to refuse registration of, or cancel a
        ProductLab User ID in its discretion.
      </p>
      <p>User shall be responsible for maintaining the confidentiality of User’s ProductLab account information.</p>
      <h2>Intellectual Property</h2>
      <p>
        All content included on the Site, such as text, graphics, logos, button icons, images, audio clips, digital
        downloads, data compilations, and software, is the property of ProductLab or its content suppliers and protected
        by United States and international copyright laws. The compilation of all content on the Site is the exclusive
        property of ProductLab and protected by U.S. and international copyright laws
      </p>
      <p>
        Except as expressly set forth herein, ProductLab alone (and its licensors, where applicable) will retain all
        intellectual property rights relating to the Service or any suggestions, ideas, enhancement requests, feedback
        or recommendations provided by you or any third party relating to the Service, which are hereby assigned to
        ProductLab. You will not copy, distribute, reproduce or use any of the foregoing except as expressly permitted
        under this Agreement. This Agreement is not a sale and does not convey any rights of ownership in or related to
        the Service, or any intellectual property rights.
      </p>
      <h2>Indemnification</h2>
      <p>
        User is responsible for all of its activity in connection with the Service. User shall defend, indemnify, and
        hold harmless ProductLab, its affiliates and each of its, and its affiliates’ employees, contractors, directors,
        suppliers and representatives from all liabilities, losses, penalties, fines, settlements, claims, and expenses,
        including reasonable attorneys’ fees, that arise from User’s (i) use or misuse of the Service; (ii) access to
        any part of the Service, (iv) content (including, for clarity, Panelist Content) or (iii) violation of this
        Agreement.{' '}
      </p>
      <h2>Warranty Disclaimer</h2>
      <p>
        THE SERVICE (INCLUDING, WITHOUT LIMITATION, THE SITE AND ANY SOFTWARE) IS PROVIDED ON AN “AS IS” BASIS, WITHOUT
        WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OF
        MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT. PRODUCTLAB MAKE NO WARRANTY THAT (I) THE
        SERVICE IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR (II) THE RESULTS OF USING THE SERVICE WILL MEET
        USER’S REQUIREMENTS. PARTICULARLY, PRODUCTLAB MAKES NO WARRANTIES WITH RESPECT TO THE ACCURACY OF ANY DATA
        PRESENTED VIA THE SERVICE. USER’S USE OF THE SERVICE IS SOLELY AT USER’S OWN RISK.
      </p>
      <h2>Limitation of Liability</h2>
      <p>
        IN NO EVENT SHALL PRODUCT, ITS OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, VENDORS OR SUPPLIERS BE LIABLE UNDER
        CONTRACT, TORT, STRICT LIABILITY, NEGLIGENCE OR ANY OTHER LEGAL THEORY WITH RESPECT TO THE SERVICE (OR ANY DATA
        OR OTHER INFORMATION AVAILABLE THROUGH THE SERVICE): (I) FOR ANY LOST PROFITS OR SPECIAL, INDIRECT, INCIDENTAL,
        PUNITIVE, OR CONSEQUENTIAL DAMAGES OF ANY KIND WHATSOEVER, EVEN IF FORESEEABLE, (II) FOR ANY BUGS, VIRUSES,
        TROJAN HORSES, OR THE LIKE (REGARDLESS OF THE SOURCE OF ORIGINATION), (III) FOR ANY ERRORS OR OMISSIONS IN ANY
        DATA OR INFORMATION OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF USER’S USE OF ANY DATA OR
        INFORMATION POSTED, EMAILED, TRANSMITTED OR OTHERWISE MADE AVAILABLE AT OR THROUGH THE SERVICE, OR (IV) FOR ANY
        DIRECT DAMAGES IN EXCESS OF (IN THE AGGREGATE) $100.00 (U.S.) (PROVIDED THAT IF PRODUCT LAB OWES A PANELIST ANY
        FEES THAT ARE GREATER THAN $100.00 – SUCH CAP WILL BE THE AMOUNTS OF SUCH FEES. In addition, ProductLab shall
        not be liable for any loss or liability resulting, directly or indirectly, from User’s inability to access or
        otherwise use the Service (including, without limitation, any delays or interruptions due to electronic or
        mechanical equipment failures, denial of service attacks, date data processing failures, telecommunications or
        Internet problems or utility failures. THE FOREGOING LIMITATIONS SHALL NOT APPLY TO THE EXTENT PROHIBITED BY
        APPLICABLE LAW.
      </p>
      <h2>Termination</h2>
      <p>
        ProductLab may terminate User’s access to all or any part of the Service at any time, with or without cause,
        effective upon notice thereof to User (provided that, if ProductLab determines there may be an immediate threat
        to ProductLab, it may terminate such access without notice). With respect to Panelists, within thirty (30) days
        from termination, ProductLab will pay all outstanding Panelist Fees.
      </p>
      <p>Upon termination notice from ProductLab, User will no longer access (or attempt to access) the Service. </p>
      <p>
        All provisions of this Agreement which by their nature should survive termination shall survive termination,
        including, without limitation, ownership provisions, warranty disclaimers and limitations of liability.
      </p>
      <h2>Miscellaneous</h2>
      <p>
        The failure of either party to exercise in any respect any right provided for herein shall not be deemed a
        waiver of any further rights hereunder. You hereby acknowledge and agree that you are not an employee, agent,
        partner, or joint venture of ProductLab, and you do not have any authority of any kind to bind ProductLab in any
        respect whatsoever. ProductLab shall not be liable for any failure to perform its obligations hereunder where
        such failure results from any cause beyond ProductLab’s reasonable control, including, without limitation,
        mechanical, electronic or communications failure or degradation (including “line-noise” interference). If any
        provision of this Agreement is found to be unenforceable or invalid, that provision shall be limited or
        eliminated to the minimum extent necessary so that this Agreement shall otherwise remain in full force and
        effect and enforceable. This Agreement is not assignable, transferable or sublicensable by User except with
        ProductLab’s prior written consent. ProductLab may transfer, assign or delegate this Agreement and its rights
        and obligations without consent. This Agreement shall be governed by and construed in accordance with the laws
        of the state of California, as if made within California between two residents thereof, and the parties submit
        to the exclusive jurisdiction and venue of the state and Federal courts located in San Francisco, California.
        Notwithstanding the foregoing sentence, (but without limiting either party’s right to seek injunctive or other
        equitable relief immediately, at any time, in any court of competent jurisdiction), any disputes arising with
        respect to this Agreement shall be settled by arbitration in accordance with the rules and procedures of the
        Judicial Arbitration and Mediation Service, Inc. (“JAMS”). The arbitrator shall be selected by joint agreement
        of the parties. In the event the parties cannot agree on an arbitrator within thirty (30) days of the initiating
        party providing the other party with written notice that it plans to seek arbitration, the parties shall each
        select an arbitrator affiliated with JAMS, which arbitrators shall jointly select a third such arbitrator to
        resolve the dispute. The written decision of the arbitrator shall be final and binding on the parties and
        enforceable in any court. The arbitration proceeding shall take place in San Francisco, California, using the
        English language. Any arbitration under Agreement will take place on an individual basis: class arbitrations and
        class actions are not permitted. YOU UNDERSTAND AND AGREE THAT BY ENTERING INTO THESE TERMS, YOU AND PRODUCTLAB
        ARE EACH WAIVING THE RIGHT TO TRIAL BY JURY OR TO PARTICIPATE IN A CLASS ACTION. Except as expressly set forth
        otherwise herein, both parties agree that this Agreement is the complete and exclusive statement of the mutual
        understanding of the parties and supersedes and cancels all previous written and oral agreements, communications
        and other understandings relating to the subject matter of this Agreement, and that all modifications must be in
        a writing signed by both parties, except as otherwise provided herein. No agency, partnership, joint venture, or
        employment is created as a result of this Agreement and User does not have any authority of any kind to bind
        ProductLab in any respect whatsoever. User shall comply at User’s expense with all applicable federal, state and
        local laws, including income tax laws. Any User registering with ProductLab from outside the United States
        hereby certifies that he or she will not access ProductLab from within the United States.
      </p>
      <p className="_last-terms-text">
        YOU MAY WITHDRAW YOUR CONSENT AT ANY TIME BY VISITING{' '}
        <a href="https://productlab.ai/contact">HTTPS://PRODUCTLAB.AI/CONTACT</a> OR EMAILING{' '}
        <a href="mailto:SUPPORT@PRODUCTLAB.AI">SUPPORT@PRODUCTLAB.AI</a>
      </p>
    </PrivacyTermsWrap>
  );
};

export default Terms;
