import React from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import { useStripe } from '@stripe/react-stripe-js';
import { Button } from './Button';
import { profileChanged } from '../../actions';
import api from '../../core/api';
import { handleError } from '../../utils/errors';

const VerifyIdentityButton = ({
  verificationStatus,
  identityLastErrorReason,
  identityLastErrorCode,
  fetchProfile = () => {}
}) => {
  const stripe = useStripe();
  const classes = useStyles();
  const dispatch = useDispatch();
  const checkUserIdentityIntervalRef = React.useRef();
  const [isLoading, setLoading] = React.useState(false);
  const [clientSecret, setClientSecret] = React.useState(null);
  const [status, setStatus] = React.useState(verificationStatus);
  let verificationStatusText = '';

  if (status === 'verified') {
    verificationStatusText = 'Identity verified';
  } else if (status === 'processing') {
    verificationStatusText = 'Identity verification processing';
  } else if (status === 'has_not_initiated' || status == 'requires_input') {
    verificationStatusText = 'Verify identity';
  } else if (status === 'created') {
    verificationStatusText = 'Identity verification created';
  } else if (status === 'redacted') {
    verificationStatusText = 'Identity verification redacted';
  } else if (status === 'canceled') {
    verificationStatusText = 'Identity verification canceled';
  } else {
    verificationStatusText = 'Identity verification processing';
  }

  const isButtonDisabled = () => {
    // if verification requires input or not initiated from the user the button should be clickable
    // so that the user can add any info required to the verification session

    // also if stripe instance is not avaible or user just submitted the document
    // disable the button
    return !stripe || (status !== 'requires_input' && status !== 'has_not_initiated');
  };

  const onVerifyClick = async () => {
    setLoading(true);
    api
      .get('verify_identity/')
      .then(res => {
        const { data } = res;
        if (data) {
          setClientSecret(data.client_secret);
        }
      })
      .catch(err => {
        handleError(err);
      })
      .finally(() => setLoading(false));
  };

  const verifyUserIdentity = async () => {
    const { error } = await stripe.verifyIdentity(clientSecret);
    if (error) {
      console.log('[error]', error);
      fetchProfile();
    } else {
      // get initial status feedback from the backend
      checkUserIdentity();
    }
  };

  const checkUserIdentity = () => {
    api
      .get('check_identity_status/')
      .then(res => {
        const { data } = res;
        setStatus(data.status);
      })
      .catch(err => {
        handleError(err);
      })
      .finally(() => {});
  };

  const pollUserIdentity = () => {
    checkUserIdentityIntervalRef.current = setInterval(() => {
      checkUserIdentity();
    }, 3000);
  };

  // start polling the backend if status is processing
  React.useEffect(() => {
    if (status === 'processing') {
      pollUserIdentity();
    }
    if (status === 'verified') {
      fetchProfile();
    }

    return () => {
      clearInterval(checkUserIdentityIntervalRef.current);
    };
  }, [status]);

  React.useEffect(() => {
    if (clientSecret) {
      verifyUserIdentity();
    }
  }, [clientSecret]);

  React.useEffect(() => {
    setStatus(verificationStatus);
  }, [verificationStatus]);

  return (
    <div className={classes.verifyIdentityButtonWrapper}>
      <Button
        disabled={isButtonDisabled()}
        loading={isLoading}
        onClick={onVerifyClick}
        className={classes.verifyIdentityButton}
      >
        {verificationStatusText}
      </Button>
      {status === 'requires_input' && identityLastErrorReason && identityLastErrorCode !== 'consent_declined' ? (
        <Alert severity="error" className={classes.verifyIdentityAlert}>
          {identityLastErrorReason}
        </Alert>
      ) : null}
    </div>
  );
};

const useStyles = makeStyles({
  verifyIdentityButtonWrapper: {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%'
  },
  verifyIdentityAlert: {
    width: '100%',
    maxWidth: '70%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: 12
  },
  verifyIdentityButton: {
    width: '100%'
  }
});

export default VerifyIdentityButton;
