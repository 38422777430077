import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { ReactComponent as LoaderIcon } from '../../media/icons/loader.svg';

const Loader = () => {
  const classes = useStyles();

  return (
    <div className={`loader ${classes.wrap}`}>
      <LoaderIcon />
    </div>
  );
};

const useStyles = makeStyles({
  wrap: {
    position: 'fixed',
    left: 0,
    top: 64,
    '@supports (padding-top: env(safe-area-inset-top))': {
      top: 'calc(64px + env(safe-area-inset-top))'
    },
    right: 0,
    bottom: 50,
    '@supports (padding-top: env(safe-area-inset-bottom))': {
      bottom: 'calc(50px + env(safe-area-inset-bottom))'
    },
    zIndex: 1000,
    backgroundColor: '#fafafa',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
});

export default Loader;
