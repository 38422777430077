import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { RateApp } from 'capacitor-rate-app';
import { App } from '@capacitor/app';

import Loader from '../UI/Loader';
import CashoutForm from '../UI/CashoutForm';
import Dialog from '../UI/Dialog';
import HistoryNavList from '../UI/HistoryNavList';

import { ReactComponent as DialogSuccessIcon } from '../../media/icons/dialog-success.svg';

import api from '../../core/api';
import { getUserId } from '../../core/auth';
import { formatCurrency } from '../../utils';
import { handleError } from '../../utils/errors';
import { useTitleWithEvent } from '../../hooks';

const PaymentBalance = ({ history }) => {
  useTitleWithEvent('Payment Balance', history);

  const classes = useStyles();

  const [isLoading, setLoading] = React.useState(true);
  const [balance, setBalance] = React.useState(0);
  const [paypalEmail, setPaypalEmail] = React.useState('');
  const [minWithdraw, setMinWithdraw] = React.useState(0);
  const [profile, setProfile] = React.useState(null);
  const [paymentHistory, setPaymentHistory] = React.useState([]);

  const [dialogOpened, setDialogOpened] = React.useState(false);

  const openDialog = () => {
    setDialogOpened(true);
  };

  const closeDialog = () => {
    setDialogOpened(false);
    RateApp.requestReview();
  };

  const fetchUser = React.useCallback(() => {
    api
      .get(`users/${getUserId()}/`)
      .then(res => {
        const profile = res.data.profile;
        setBalance(profile.balance);
        setPaypalEmail(profile.paypal_email || '');
        setMinWithdraw(profile.payments_min_withdraw || 0);
        setProfile(profile);
      })
      .catch(err => {
        handleError(err);
      })
      .finally(() => setLoading(false));
  }, []);

  React.useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  React.useEffect(() => {
    let interval = setInterval(() => fetchUser(), 1000 * 60 * 60);
    return () => clearInterval(interval);
  }, [fetchUser]);

  React.useEffect(() => {
    const listener = App.addListener('appStateChange', ({ isActive }) => {
      if (isActive) fetchUser();
    });
    return () => {
      listener.then(result => {
        result.remove();
      });
    };
  }, [fetchUser]);

  const fetchPaymentHistory = React.useCallback(() => {
    api
      .get(`payment/`)
      .then(res => {
        const { data } = res;
        if (data) {
          setPaymentHistory(data.results);
        }
      })
      .catch(err => {
        handleError(err);
      });
  }, []);

  React.useEffect(() => {
    fetchPaymentHistory();
  }, [fetchPaymentHistory]);

  const onCashoutSuccess = () => {
    openDialog();
    fetchUser();
    fetchPaymentHistory();
  };

  return (
    <div className={classes.page}>
      {isLoading ? (
        <div className={classes.loader}>
          <Loader />
        </div>
      ) : (
        <React.Fragment>
          <div className={classes.balance}>
            <div className={classes.balanceLabel}>Rewards Balance</div>
            <div className={classes.balanceValue}>{formatCurrency(balance)}</div>
          </div>
          <CashoutForm
            paypalEmail={paypalEmail}
            balance={+balance}
            onSuccess={onCashoutSuccess}
            profile={profile}
            setProfile={setProfile}
            fetchProfile={fetchUser}
            minWithdraw={minWithdraw}
          />
          <Dialog
            isOpened={dialogOpened}
            onClose={closeDialog}
            onSubmit={closeDialog}
            title="Success!"
            text={
              <>
                Your payment will arrive
                <br />
                within 24 hours!
              </>
            }
            buttonTitle="Got it!"
            icon={<DialogSuccessIcon />}
          />
          <HistoryNavList data={paymentHistory} />
        </React.Fragment>
      )}
    </div>
  );
};

const useStyles = makeStyles({
  loader: {
    '& .loader': {
      top: 176
    }
  },
  page: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 40,
    '& .policy-block': {
      marginTop: 0
    }
  },
  balance: {
    marginTop: 8,
    marginBottom: 24
  },
  balanceLabel: {
    fontSize: 14,
    lineHeight: '24px',
    color: '#8A8B94'
  },
  balanceValue: {
    font: "32px/40px 'Domine', serif",
    color: '#14315C',
    fontWeight: 700
  },
  dialog: {
    '& .MuiDialog-paper': {
      overflow: 'visible',
      margin: '56px 24px 24px',
      maxWidth: 'calc(100% - 48px)',
      width: '100%',
      boxShadow: 'none'
    }
  },
  dialogContent: {
    padding: '0 24px 24px',
    textAlign: 'center'
  },
  dialogIcon: {
    display: 'block',
    margin: '-32px auto 24px'
  },
  dialogTitle: {
    font: "32px/40px 'Domine', serif",
    color: '#333646',
    margin: 0,
    fontWeight: 700
  },
  dialogText: {
    fontSize: 16,
    lineHeight: '24px',
    color: '#5C5E6C',
    margin: '8px 0 0'
  },
  dialogButton: {
    width: '100%',
    marginTop: 24
  }
});

export default PaymentBalance;
