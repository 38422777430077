import React from 'react';
import { Box } from '@material-ui/core';

import TextField from './TextField';

const TextInput = ({ value, onChange }) => {
  return (
    <Box>
      <TextField
        value={value === undefined ? '' : value}
        onChange={({ target: { value } }) => onChange(value)}
        fullWidth
        autoFocus
      />
    </Box>
  );
};

export default TextInput;
