import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

import { ReactComponent as ChevronLeftIcon } from '../../media/icons/chevron-left.svg';

const PageHeader = ({ children, backBtn, backUrl, classNameTitle = '' }) => {
  const classes = useStyles();
  const history = useHistory();

  const goBack = React.useCallback(() => {
    if (backUrl) {
      history.push(backUrl);
    } else {
      history.goBack();
    }
  }, [history, backUrl]);

  return (
    <div className={classes.wrap}>
      {backBtn ? (
        <span className={classes.backButton} onClick={goBack}>
          <ChevronLeftIcon />
        </span>
      ) : null}
      <h1 className={`${classes.title} ${classNameTitle}`}>{children}</h1>
    </div>
  );
};

PageHeader.propTypes = {
  children: PropTypes.any,
  backBtn: PropTypes.bool,
  backUrl: PropTypes.string,
  classNameTitle: PropTypes.string
};

const useStyles = makeStyles({
  wrap: {
    padding: '12px 0 20px',
    display: 'flex',
    minHeight: 40
  },
  backButton: {
    width: 24,
    height: 24,
    margin: '8px 16px 0 0'
  },
  title: {
    font: "32px/40px 'Domine', serif",
    color: '#333646',
    margin: 0,
    fontWeight: 700
  }
});

export default PageHeader;
