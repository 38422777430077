import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const Error = ({ children }) => {
  const classes = useStyles();

  return <div className={classes.error}>{children}</div>;
};

const useStyles = makeStyles({
  error: {
    backgroundColor: '#f8d7da',
    color: '#721c24',
    border: '1px solid #721c24',
    borderRadius: '.25rem',
    padding: 5,
    marginTop: 5,
    marginBottom: 15
  }
});

export default Error;
