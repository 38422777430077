import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

import { ReactComponent as FacebookIcon } from '../media/icons/facebook.svg';
import { ReactComponent as TwitterIcon } from '../media/icons/twitter.svg';
import menuHexagonIcon from '../media/icons/footer-menu-hexagon.svg';

const Footer = () => {
  const classes = useStyles();

  return (
    <div className="wrapper">
      <footer className={classes.footer}>
        <p className={classes.copy}>
          © ProductLab. <br />
          All Rights Reserved
        </p>
        <ul className={classes.nav}>
          <li className={classes.navItem}>
            <Link to="/privacy" className={classes.navLink}>
              Privacy Policy
            </Link>
          </li>
          <li className={classes.navItem}>
            <Link to="/terms" className={classes.navLink}>
              Terms and Conditions
            </Link>
          </li>
          <li className={classes.navItem}>
            <a href="mailto:support@productlab.ai?subject=Do Not Sell My Info" className={classes.navLink}>
              Do Not Sell Info
            </a>
          </li>
          <li className={classes.navItem}>
            <a href="mailto:support@productlab.ai?subject=Support Request" className={classes.navLink}>
              Contact Us
            </a>
          </li>
          <li className={classes.navItem}>
            <a href="https://app.productlab.ai/blog/" className={classes.navLink}>
              Blog
            </a>
          </li>
        </ul>
        <ul className={classes.social}>
          <li className={classes.socialItem}>
            <a
              href="https://www.facebook.com/theproductlab"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.socialLink}
            >
              <FacebookIcon />
            </a>
          </li>
          <li className={classes.socialItem}>
            <a
              href="https://twitter.com/productlabai"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.socialLink}
            >
              <TwitterIcon />
            </a>
          </li>
        </ul>
      </footer>
    </div>
  );
};

const useStyles = makeStyles({
  footer: {
    backgroundColor: '#14315C',
    borderRadius: '40px 40px 0 0',
    position: 'relative',
    padding: '40px 24px',
    marginTop: 40,
    minHeight: 248,
    boxSizing: 'border-box',
    '@media (min-width: 1000px)': {
      marginTop: 0,
      padding: 40,
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      minHeight: 144,
      borderStyle: 'solid',
      borderWidth: '1px 1px 0',
      borderColor: '#fff'
    },
    '@media (min-width: 1180px)': {
      minHeight: 104
    }
  },
  copy: {
    fontSize: 14,
    lineHeight: '24px',
    color: 'rgba(255,255,255, .5)',
    margin: '0',
    '& br': {
      '@media (min-width: 1000px)': {
        display: 'none'
      }
    }
  },
  nav: {
    listStyle: 'none',
    padding: '0',
    margin: '8px 0 0',
    '@media (min-width: 1000px)': {
      marginTop: 0,
      marginBottom: 16,
      display: 'flex',
      order: -1,
      width: '100%',
      height: 24
    },
    '@media (min-width: 1180px)': {
      order: 'unset',
      width: 'unset',
      marginBottom: 0
    }
  },
  navItem: {
    display: 'block',
    marginTop: 16,
    '@media (min-width: 1000px)': {
      marginTop: 0,
      marginLeft: 16,
      paddingLeft: 40,
      background: `url(${menuHexagonIcon}) no-repeat transparent left center`
    },
    '&:first-child': {
      background: 'none',
      '@media (min-width: 1000px) and (max-width: 1179px)': {
        marginLeft: 0,
        paddingLeft: 0
      }
    }
  },
  navLink: {
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 500,
    color: '#fff',
    textDecoration: 'none'
  },
  social: {
    position: 'absolute',
    top: 40,
    right: 24,
    listStyle: 'none',
    padding: '0',
    margin: '0',
    display: 'flex',
    '@media (min-width: 1180px)': {
      position: 'static'
    }
  },
  socialItem: {
    marginLeft: 24,
    display: 'flex'
  },
  socialLink: {
    display: 'flex'
  }
});

export default Footer;
