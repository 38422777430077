import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { getFullSiteUrl } from '../../utils/urls';

const RegisterPrivacy = () => {
  const classes = useStyles();

  return (
    <div className={`policy-block ${classes.copyright}`}>
      <p>
        By clicking "Get Started", I agree to our&nbsp;
        <a href={getFullSiteUrl('/privacy')} target="_blank" rel="noopener noreferrer">
          Privacy Policy
        </a>
        &nbsp;and&nbsp;
        <a href={getFullSiteUrl('/terms')} target="_blank" rel="noopener noreferrer">
          Terms &amp; Conditions
        </a>
        .
      </p>
      <p className={`${classes.second_p}`}>
        By clicking "Get Started", I consent to receive automated SMS messages ProductLab and acknowledge that I can opt
        out at any time by updating my notification settings or replying "STOP" to any message.
      </p>
    </div>
  );
};

const useStyles = makeStyles({
  second_p: {
    paddingTop: '10px'
  },
  copyright: {
    fontSize: '14px',
    lineHeight: '20px',
    textAlign: 'center',
    marginTop: 'auto',
    paddingTop: 0,
    paddingBottom: 0,
    '@media (max-width: 999px)': {
      paddingBottom: 16
    },
    '& p': {
      margin: '0 auto',
      maxWidth: 303
    },
    '& a': {
      textDecoration: 'none'
      // color: '#14315C'
    }
  }
});

export default RegisterPrivacy;
