import React from 'react';
import PrivacyTermsWrap from './PrivacyTermsWrap';

const Privacy = () => {
  return (
    <PrivacyTermsWrap>
      <h1>Privacy Policy</h1>
      <p className="_effective-date">Effective Date: December 20, 2019</p>
      <p>
        Product Lab AI, Inc (“ProductLab,” “us,” “we,” or “our”) provides incentives and offers to individuals who work
        in the “gig economy” (“Users”) when they complete market research surveys. We then provide your personal
        information to third parties to identify potential offers we can share with you and/or provide compensation to
        you or us to continue offering this service.
      </p>
      <p>
        ProductLab is committed to protecting your privacy and the personal information you provide to us. This Privacy
        Policy (“Policy”) explains how we collect, use, disclose, and otherwise process the personal information (as
        defined below) of our customers and users. It applies to all personal information collected by us on
        https://productlab.ai/ (the “Site”) and any other website or mobile application that links to this Policy, as
        well as during any written, electronic, and oral communications (collectively, the “Services”).{' '}
      </p>
      <p>
        Your access to and use of our Services are subject to our Terms and Conditions, which define some of the terms
        used throughout this Policy. Please make sure that you have carefully read and understand the Terms and
        Conditions before you use our Services. By using our Services, you accept the Terms and Conditions, and accept
        our privacy practices described in this Policy. If you do not feel comfortable with any part of this Policy or
        our Terms and Conditions, you must not use or access our Services.
      </p>
      <p>
        Our Services may change from time to time and we reserve the right to update or modify this Policy at any time.
        If we make any material changes to the way we process your personal information, we will notify you before such
        changes are effective. If you object to any changes you must cease using our Services. We recommend you review
        this Policy periodically. This Policy became effective on the date indicated at the top of this page. Your
        continued use of our Services after any changes or revisions to this Policy indicates your agreement to the
        terms of the revised Policy.
      </p>
      <h2>Personal Information We Collect</h2>
      <p>
        When you use our Services, we may collect or receive personal information about you to provide our Services and
        operate our business. The types of personal information that we may collect about you are:
      </p>
      <ul className="alpha-list">
        <li>
          <span>Information You Provide to Us Directly</span>
          <p>
            You may provide your personal information to us when you use our Services, including when you register with
            ProductLab, respond to surveys, or otherwise access our Services. This information may include:
          </p>
          <ul className="dot-list">
            <li>
              <p>
                <b>Registration Details</b> – We collect certain personal information when you register for an account,
                including information such as first name, last name, email address, phone number, region, and company
                name. We also collect registration details if you register for and access your account via third-party
                social log-in.
              </p>
            </li>
            <li>
              <p>
                <b>Earnings and Activity Data</b> – We collect information about your earnings, the number of trips,
                rides, or other activities you’ve completed or in which you’ve engaged.
              </p>
            </li>
            <li>
              <p>
                <b>Third Party Account Data</b> – We allow you to connect certain third party accounts to your
                ProductLab account in order to provide us with direct access to specific personal information maintained
                on those third party accounts (e.g., rideshare account, bank account, etc.).
              </p>
            </li>
            <li>
              <p>
                <b>Employment Details</b> – We collect contact information and certain employment-related information if
                you apply for a job with us, such as previous and current work history, education information, and
                skills.
              </p>
            </li>
          </ul>
        </li>
        <li>
          <span>Information We Collect Automatically</span>
          <p>
            When you access our Site and use or Services, we, our service providers, and our business partners may
            automatically log information about you, your computer or mobile device, and your activity over time on our
            Site and other sites and online services, such as:
          </p>
          <ul className="dot-list">
            <li>
              <p>
                <b>Technical Information and Device Data</b> – We may collect information related to your browser and
                operating system, IP address, unique device identifiers, and other information such as your device type.
                With your permission, we may also collect your browser’s HTML5 location to auto-identify your region.
                See the “Your Rights and Choices” section of the Policy below for more information on how to manage your
                location-sharing settings.
              </p>
            </li>
            <li>
              <p>
                <b>Site Usage Information and Preferences</b> – We may collect information to better understand customer
                traffic patterns and Site usage such as pages or screens you viewed, how long you spent on a page or
                screen, navigation paths between pages or screens, information about your activity on a page or screen,
                access times, and duration of access. We may also collect information about your preferences to make
                your use of the Site more productive through the use of cookies.
              </p>
            </li>
            <li className="_divider">
              <p>Some of our automatic data collection is facilitated by:</p>
            </li>
            <li>
              <p>
                <b>Cookies,</b> which are text files that websites store on a visitor‘s device to uniquely identify the
                visitor’s browser or to store information or settings in the browser for the purpose of helping you
                navigate between pages efficiently and remembering your preferences, enabling functionality, helping us
                understand user activity and patterns, and facilitating online advertising. See the “Your Rights and
                Choices” section of the Policy below for more information on how to manage your cookie settings.
              </p>
            </li>
            <li>
              <p>
                <b>Web beacons,</b> also known as pixel tags or clear GIFs, which are typically used to demonstrate that
                a webpage or email was accessed or opened, or that certain content was viewed or clicked, typically to
                compile statistics about usage of websites and the success of marketing campaigns.
              </p>
            </li>
          </ul>
        </li>
      </ul>
      <h2>How We Use Your Personal Information</h2>
      <p>
        We use your personal information for the following purposes or as otherwise described at the time we collect it:
      </p>
      <ul className="alpha-list">
        <li>
          <span>Service Delivery</span>
          <p>We use your personal information to:</p>
          <ul className="dot-list">
            <li>
              <p>
                provide and operate the Services, which includes processing payments when Users take and complete
                research surveys, and to personalize offers and deals;
              </p>
            </li>
            <li>
              <p>establish and maintain your account, and identify your region;</p>
            </li>
            <li>
              <p>
                enable security features of the Services, such as by sending you secure links via email through which to
                log in to the Site;
              </p>
            </li>
            <li>
              <p>
                communicate with you about the Services, including by sending announcements, updates, security alerts,
                and support and administrative messages;
              </p>
            </li>
            <li>
              <p>
                improve the content and/or functionality of the Services and our business, and for research and
                development; and
              </p>
            </li>
            <li>
              <p>provide support for the Services and respond to your requests, questions and feedback.</p>
            </li>
          </ul>
        </li>
        <li>
          <span>Marketing and Advertising</span>
          <p>
            We and our third party advertising partners may collect and use your personal information for marketing and
            advertising purposes, including:
          </p>
          <ul className="dot-list">
            <li>
              <p>
                <b>For Direct Marketing</b> – We may send you ProductLab-related or other direct marketing
                communications as permitted by law, including by email and text. You may opt-out of our marketing
                communications as described in the “Your Rights and Choices” section of the Policy below.
              </p>
            </li>
            <li>
              <p>
                <b>For Interest-Based Advertising</b> – We may contract with third-party advertising companies and
                social media companies to display ads on our Service and other sites. These companies may use cookies
                and similar technologies to collect information about you (as described above) over time across our
                Service and other sites and services or your interaction with our emails, and use that information to
                serve ads that they think will interest you. These ads are known as “interest-based advertisements.”
                Refer to the “Your Rights and Choices” section of this policy to learn more about limiting
                interest-based advertising.
              </p>
            </li>
          </ul>
        </li>
        <li>
          <span>Compliance and Protection</span>
          <p>We may use your personal information to:</p>
          <ul className="dot-list">
            <li>
              <p>
                comply with applicable laws, lawful requests, and legal process, such as to respond to subpoenas or
                requests from government authorities;
              </p>
            </li>
            <li>
              <p>
                protect our, your or others’ rights, privacy, safety or property (including by making and defending
                legal claims);
              </p>
            </li>
            <li>
              <p>
                audit our internal processes for compliance with legal and contractual requirements and internal
                policies;
              </p>
            </li>
            <li>
              <p>enforce the terms and conditions that govern the Services; and</p>
            </li>
            <li>
              <p>
                prevent, identify, investigate and deter fraudulent, harmful, unauthorized, unethical or illegal
                activity, including cyberattacks and identity theft, and to improve and enforce our security measures.
              </p>
            </li>
          </ul>
        </li>
        <li>
          <span>For Our Business Purposes</span>
        </li>
      </ul>
      <p>
        We may use your personal information for any other purpose disclosed to you at the time we collect or receive
        the information, or otherwise with your consent.
      </p>
      <h2>To Whom We Disclose Your Personal Information</h2>
      <p>
        We may disclose your personal information to the following parties and as otherwise described in this Policy or
        at the time of collection:
      </p>
      <ul className="dot-list">
        <li>
          <p>
            <b>Affiliates</b> – Entities that control or are under the control of ProductLab. We will require these
            entities to comply with the terms of this Policy with regard to their use of your information.
          </p>
        </li>
        <li>
          <p>
            <b>Service Providers</b> – Companies and individuals that provide services on our behalf or help us operate
            the Services or our business (such as IT services, customer support, data management, email delivery,
            marketing, website analytics, professional advisors, and payment processing). These service providers have
            access to your personal information only for the purpose of performing Services on our behalf and are
            expressly obligated not to disclose or use your personal information for any other purpose.
          </p>
        </li>
        <li>
          <p>
            <b>Clients</b> – Companies to whom we sell your personal information (i.e., “Registration Details” and
            “Earnings and Activity Data”) to help them better understand certain market trends and better target
            promotions and advertising, to identify potential offers we can share with you and/or provide compensation
            to you or us to continue offering the Services.
          </p>
        </li>
        <li>
          <p>
            <b>Authorities and Others</b> – Law enforcement, government authorities, and private parties, as we believe
            in good faith to be necessary or appropriate for the compliance and operations purposes described above.
          </p>
        </li>
        <li>
          <p>
            <b>Business Transferees</b> – Relevant participants during a sale, transfer, merger, reorganization,
            dissolution, or similar event.
          </p>
        </li>
      </ul>
      <p>We may also disclose your information with your consent or at your direction.</p>
      <h2>Your Rights and Choices</h2>
      <p>
        We provide you and your authorized agents certain privacy rights related to the personal information we collect.
        However, these rights are not absolute, and in certain cases we may decline your request as permitted by law. In
        order to exercise these rights you must login to your account to confirm your identity, which helps us ensure
        that personal information is only made accessible to appropriate parties.{' '}
      </p>
      <p>
        You will not receive discriminatory treatment for exercising your rights and you can return to the Services
        after deleting your information. However, keep in mind that in order to provide you with a majority of our
        Services, we require your personal information.
      </p>
      <p>
        We also provide our you with specific choices related to how your personal information may be used or related to
        the technologies that collect this information.
      </p>
      <ul className="alpha-list">
        <li>
          <span>Your Rights</span>
          <p>
            This section lists the privacy-related rights we extend to all ProductLab customers with respect to the
            personal information we collect. Unless otherwise required by law, these rights apply to personal
            information that we’ve collected about you over the past 12 months.
          </p>
          <ul className="dot-list">
            <li>
              <p>
                <b>Right to Know</b> – You have the right to know the personal information we collect, use, disclose,
                and sell about you.
              </p>
            </li>
            <li>
              <p>
                <b>Right to Access, Update, or Rectify</b> – If you have registered for an account with us, you may
                access and review your personal information and update and/or rectify certain personal information by
                logging into your account and clicking the “My Account” page.
              </p>
            </li>
            <li>
              <p>
                <b>Right to Delete</b> – You have the right to request that we delete any personal information we have
                collected from you or maintain about you. However, we may save personal information when permitted by
                applicable law, including, without limitation, when the information is needed for a legal purpose.
              </p>
            </li>
            <li>
              <p>
                <b>Right to Opt-Out of the Sale of Your Personal Information</b> – In order to provide you with our
                services and identify potential offers we can share with you and/or provide compensation to you,
                ProductLab relies on providing your personal information to third parties. You have the right to opt-out
                of having your personal information sold by submitting a request to delete your account. You can request
                this by clicking the <b>“Do Not Sell My Personal Information”</b> link in the footer below; or you may
                access the request page by <a href="https://productlab.ai/contact">clicking here</a>.
              </p>
            </li>
          </ul>
        </li>
        <li>
          <span>Asserting Your Rights</span>
          <p>
            You may exercise your right to know, access, update, rectify or delete your personal information by
            logging-in to your account or by contacting us (see “How To Contact Us” section). You may also designate an
            authorized agent to make a request to know or a request to delete. In order to be able to act, authorized
            agents have to submit proof that they are authorized to act on your behalf, or have a power of attorney. We
            may deny requests from authorized agents who do not submit proof that they have been authorized by you to
            act on their behalf.
          </p>
        </li>
        <li>
          <span>Compliance and Protection</span>
          <p>
            This section lists the choices you can make with respect to the Services and the methods through which
            personal information may be collected.
          </p>
          <ul className="dot-list">
            <li>
              <p>
                <b>Opt-Out of Marketing Communications</b> – You may opt-out of marketing-related emails by following
                the opt-out or unsubscribe instructions at the bottom of the email, or by contacting us. You may
                continue to receive transactional and other non-marketing emails (e.g., account information, password
                reset emails). If you receive marketing text messages from us, you may opt-out of receiving further
                marketing text messages from us by replying STOP to our marketing message.
              </p>
            </li>
            <li>
              <p>
                <b>Restrict the Use of Cookies</b> – You may wish to restrict the use of cookies, or completely prevent
                them from being set. Most browsers let you remove and/or stop accepting cookies from the websites you
                visit. To do this, follow the instructions in your browser’s settings. Many browsers accept cookies by
                default until you change your settings. If you do not accept cookies, however, you may not be able to
                use all functionality of the Services and our Site may not work properly. For more information about
                cookies, including how to see what cookies have been set on your browser and how to manage and delete
                them, visit www.allaboutcookies.org. We use Google Analytics to help us understand user activity and
                patterns on the Site. You can learn more about Google Analytics cookies and about how Google protects
                your data at{' '}
                <a
                  href="https://support.google.com/analytics/answer/6004245?hl=en"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://support.google.com/analytics/answer/6004245?hl=en
                </a>
                .
              </p>
            </li>
            <li>
              <p>
                <b>Opt-Out of Location-Sharing</b> – When you first register for an account on our Site, we ask
                permission to access your location so that we can autofill your region. You can change your
                location-sharing permissions in your browser’s settings.
              </p>
            </li>
            <li>
              <p>
                <b>Opt-Out of Interest-based advertising</b> – Some of our advertising partners are members of the
                Network Advertising Initiative (NAI) and are subject to the Self-Regulatory Principles for Online
                Behavioral Advertising published by the Digital Advertising Alliance (DAA). You can obtain more
                information about these companies’ information collection practices, and opt-out of receiving
                interest-based advertising from participating NAI and DAA members at{' '}
                <a
                  href="http://www.networkadvertising.org/managing/opt_out.asp"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  http://www.networkadvertising.org/managing/opt_out.asp
                </a>{' '}
                and/or the DAA’s website at optout.aboutads.info. You can also limit the collection of your information
                for interest-based ads by blocking third party cookies in your browser settings or using privacy
                plug-ins or ad blocking software that help you block third party cookies. If you opt-out of
                interest-based advertisements, you will still see advertisements online but they may be less relevant to
                you.
              </p>
            </li>
            <li>
              <p>
                <b>Managing Information from Third Party Platforms</b> – If you choose to connect to the Service through
                your social media account, you may be able to use your settings in your account with that platform to
                limit the information we receive from it. If you revoke our ability to access information from a third
                party platform, that choice will not apply to information that we have already received from that third
                party.
              </p>
            </li>
          </ul>
        </li>
      </ul>
      <h2>Security</h2>
      <p>
        We employ a number of technical, organizational and physical safeguards designed to protect the personal
        information we collect. However, security risk is inherent in all internet and information technologies and we
        cannot guarantee the security of your personal information. Please keep this in mind when disclosing any
        information to ProductLab.
      </p>
      <p>
        If you have reason to believe that your interaction with us is no longer secure (for example, if you feel that
        the security of your account might have been compromised), or if you suspect someone else is using your account,
        please let us know immediately by contacting us as indicated in the Contact Us section below.
      </p>
      <h2>Data Retention</h2>
      <p>
        We will retain your personal information for as long as your account is active or as needed to provide you
        Services and to maintain a record of your transactions for financial reporting purposes. We will also retain and
        use your personal information as necessary to comply with our legal obligations, resolve disputes, and enforce
        our agreements.
      </p>
      <h2>Other Sites and Services</h2>
      <p>
        Our Services may contain links to other sites that are not operated by us. If you click on a third party link,
        you will be directed to that third party’s site. We strongly advise you to review the privacy policy of every
        site you visit.
      </p>
      <p>
        We have no control over and assume no responsibility for the content, privacy policies or practices of any third
        party sites or services.
      </p>
      <h2>California Residents</h2>
      <p>
        The chart below describes the personal information we collect by reference to the categories specified in the
        California Consumer Privacy Act of 2018, and describes our practices during the 12 months preceding the
        effective date of this Privacy Policy.{' '}
      </p>
      <p>
        All of the purposes for using each category of collected personal information as described in the chart are
        detailed the section above entitled “How We Use Your Personal Information,” and a list of the categories of
        personal information disclosed for a business purpose or sold in the past 12 months is detailed in the section
        above entitled “To Whom We Disclose Your Personal Information.”{' '}
      </p>
      <div className="table-wrap">
        <table>
          <thead>
            <tr>
              <th>Statutory category of personal information</th>
              <th>Personal information we collect in this category </th>
              <th>Source of personal information</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Identifiers (such as contact information, cookies, etc.)</td>
              <td>
                <ul className="dot-list">
                  <li>Registration Details</li>
                  <li>Technical Information and Device Data</li>
                  <li>Third Party Account Data</li>
                </ul>
              </td>
              <td>
                <ul className="dot-list">
                  <li>Directly from you</li>
                  <li>Automatic collection from you</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>Personal information under California Civil Code section 1798.80</td>
              <td>
                <ul className="dot-list">
                  <li>Technical Information and Device Data</li>
                </ul>
              </td>
              <td>
                <ul className="dot-list">
                  <li>Directly from you</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                Commercial Information (such as records of products or services purchased, obtained, or considered)
              </td>
              <td>
                <ul className="dot-list">
                  <li>Earnings and Activity Data</li>
                </ul>
              </td>
              <td>
                <ul className="dot-list">
                  <li>Directly from you</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>Internet or Network Information</td>
              <td>
                <ul className="dot-list">
                  <li>Technical Information and Device Data</li>
                  <li>Site Usage Information and Preferences</li>
                </ul>
              </td>
              <td>
                <ul className="dot-list">
                  <li>Automatic collection from you</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>Geolocation Data</td>
              <td>
                <ul className="dot-list">
                  <li>Technical Information and Device Data</li>
                </ul>
              </td>
              <td>
                <ul className="dot-list">
                  <li>Automatic collection from you</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>Professional or Employment Information</td>
              <td>
                <ul className="dot-list">
                  <li>Employment Details</li>
                </ul>
              </td>
              <td>
                <ul className="dot-list">
                  <li>Directly from you </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                Inferences (drawn from any of the information identified to create a profile about a consumer reflecting
                the consumer’s preferences, characteristics, psychological trends, predispositions, behavior, attitudes,
                intelligence, abilities, and aptitudes)
              </td>
              <td>
                <ul className="dot-list">
                  <li>Registration Details</li>
                  <li>Earnings and Activity Data</li>
                </ul>
              </td>
              <td>
                <ul className="dot-list">
                  <li>Directly from you </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>Protected Classification Information (such as race, gender, ethnicity)</td>
              <td>
                <ul className="dot-list">
                  <li>None</li>
                </ul>
              </td>
              <td>
                <ul className="dot-list">
                  <li>N/A</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                Education Information (defined as information that is not publicly available personally identifiable
                information as defined in the Family Educational Rights and Privacy Act (20 U.S.C. section Sec. 1232g, ;
                34 C.F.R. Part 99)
              </td>
              <td>
                <ul className="dot-list">
                  <li>None</li>
                </ul>
              </td>
              <td>
                <ul className="dot-list">
                  <li>N/A</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>Sensory Information (such as audio, electronic, visual, or other similar information)</td>
              <td>
                <ul className="dot-list">
                  <li>None</li>
                </ul>
              </td>
              <td>
                <ul className="dot-list">
                  <li>N/A</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <p>
        If you are a California resident, California law permits you to request certain information regarding the
        disclosure of your personal information by us to third parties for the third parties’ direct marketing purposes.
        You can email <a href="mailto:support@productlab.ai">support@productlab.ai</a> with your request.
      </p>
      <h2>International Users</h2>
      <p>
        ProductLab is based in the United States and any information we obtain about you will be stored in accordance
        with U.S. privacy laws, regulations, and standards, which may not be equivalent to the laws in your country of
        residence. We may also use service providers that operate in other countries. By using our Services, you consent
        to this collection, transfer, storage, and processing of information to and in the United States and other
        countries or other locations where privacy laws may not be as protective as those in your state, province, or
        country.
      </p>
      <h2>Children’s Information</h2>
      <p>
        Our Services are not intended for children under 13 years of age and we do not knowingly collect personal
        information from children under the age of 13. If we learn that we have collected personal information from a
        child under the age of 13, we will delete it.
      </p>
      <p>
        If you are a parent or guardian of a child under the age of 13 and believe he or she has disclosed personal
        information to us, please contact us at support@productlab.ai. A parent or guardian of a child under age 13 may
        review and request deletion of the child’s personal information.
      </p>
      <h2>How to Contact Us</h2>
      <p>
        If you have any questions or concerns relating to this Policy or our privacy practices please contact us at{' '}
        <a href="mailto:support@productlab.ai">support@productlab.ai</a>.{' '}
      </p>
    </PrivacyTermsWrap>
  );
};

export default Privacy;
