import {
  QUESTION_CHANGED,
  ANSWERS_CHANGED,
  QUESTION_INDEX_CHANGED,
  OPTIONS_CHANGED,
  SELECTED_OPTIONS_CHANGED,
  SUBMISSION_ID_CHANGED,
  QUESTION_DESCRIPTIONS_CHANGED,
  QUESTION_LIST_CHANGED,
  QUESTION_IMAGE_BASE64_CHANGED,
  IMG_LIST_CHANGED
} from '../actions/types';

const INITIAL_STATE = {
  error: '',
  loading: false,
  question: [],
  answers: [],
  questionIndex: 0,
  options: [],
  selectedOptions: [],
  submissionID: '',
  questionImageBase64: '',
  questionDescriptions: ['apps', 'lyft', 'doordash', 'uber'],
  questionList: [],
  imgList: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case QUESTION_CHANGED:
      return { ...state, question: action.payload };
    case ANSWERS_CHANGED:
      return { ...state, answers: action.payload };
    case QUESTION_LIST_CHANGED:
      return { ...state, questionList: action.payload };
    case QUESTION_DESCRIPTIONS_CHANGED:
      return { ...state, questionDescriptions: action.payload };
    case QUESTION_INDEX_CHANGED:
      return { ...state, questionIndex: action.payload };
    case OPTIONS_CHANGED:
      return { ...state, options: action.payload };
    case SELECTED_OPTIONS_CHANGED:
      return { ...state, selectedOptions: action.payload };
    case SUBMISSION_ID_CHANGED:
      return { ...state, submissionID: action.payload };
    case QUESTION_IMAGE_BASE64_CHANGED:
      return { ...state, questionImageBase64: action.payload };
    case IMG_LIST_CHANGED:
      return { ...state, imgList: action.payload };
    default:
      return state;
  }
};
