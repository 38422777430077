import {
  EMAIL_CHANGED,
  PHONE_CHANGED,
  REGISTER_ERROR_CHANGED,
  FIRST_NAME_CHANGED,
  LAST_NAME_CHANGED,
  PAYPAL_EMAIL_CHANGED,
  PASSWORD_CHANGED,
  REPEAT_PASSWORD_CHANGED
} from '../actions/types';

const INITIAL_STATE = {
  error: '',
  loading: false,
  email: '',
  phone: '',
  firstName: '',
  lastName: '',
  paypalEmail: '',
  password: '',
  repeatPassword: ''
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REGISTER_ERROR_CHANGED:
      return { ...state, error: action.payload };
    case EMAIL_CHANGED:
      return { ...state, email: action.payload };
    case FIRST_NAME_CHANGED:
      return { ...state, firstName: action.payload };
    case LAST_NAME_CHANGED:
      return { ...state, lastName: action.payload };
    case PHONE_CHANGED:
      return { ...state, phone: action.payload };
    case PAYPAL_EMAIL_CHANGED:
      return { ...state, paypalEmail: action.payload };
    case PASSWORD_CHANGED:
      return { ...state, password: action.payload };
    case REPEAT_PASSWORD_CHANGED:
      return { ...state, repeatPassword: action.payload };
    default:
      return state;
  }
};
