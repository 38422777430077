import React from 'react';
import { Capacitor } from '@capacitor/core';
import useGTM from '@elgorditosalsero/react-gtm-hook';
import { makeStyles } from '@material-ui/core/styles';
import { App } from '@capacitor/app';
import { Browser } from '@capacitor/browser';

import Dialog from '../UI/Dialog';

import Loader from '../UI/Loader';
import QuestionMultipleChoice from '../UI/QuestionMultipleChoice';
import QuestionImage from '../UI/QuestionImage';
import QuestionSurveyJs from '../UI/QuestionSurveyJs';
import Surveys from '../UI/Surveys';
import LoadingDialog from '../UI/LoadingDialog';

import api from '../../core/api';
import { AnswerType, CurrentItemResponseType, PageType } from '../../enums';

import { ReactComponent as DialogSuccessIcon } from '../../media/icons/dialog-success-survey.svg';
import { handleError } from '../../utils/errors';
import { useTitleWithEvent } from '../../hooks';

const getTitle = (pageType, questionDetails) => {
  if (questionDetails && questionDetails.question.survey === 'starting_survey') {
    return 'Starting Survey';
  }
  if (pageType === 'Question') {
    return 'Survey';
  }
  return pageType || 'Surveys';
};

const QuestionView = ({ questionDetails, onSubmit, isLoadingButton, onClose }) => {
  switch (questionDetails?.question?.answer_type) {
    case AnswerType.survey_js:
      return (
        <QuestionSurveyJs
          question={questionDetails.question}
          onSubmit={onSubmit}
          onClose={onClose}
          isLoadingButton={isLoadingButton}
          options={questionDetails?.options}
        />
      );
    case AnswerType.multiple_choice:
      return (
        <QuestionMultipleChoice
          question={questionDetails.question}
          onSubmit={onSubmit}
          isLoadingButton={isLoadingButton}
          options={questionDetails?.options}
        />
      );
    case AnswerType.image:
      return (
        <QuestionImage question={questionDetails.question} onSubmit={onSubmit} isLoadingButton={isLoadingButton} />
      );
    default:
      return null;
  }
};

const Question = ({ history }) => {
  const { sendDataToGTM } = useGTM();
  const classes = useStyles();

  const [isLoading, setLoading] = React.useState(true);
  const [isLoadingButton, setLoadingButton] = React.useState(false);
  const [isAnalyzing, setAnalyzing] = React.useState(false);
  const [questionDetails, setQuestonDetails] = React.useState(null);
  const [surveys, setSurveys] = React.useState(null);
  const [inBrainSurveys, setInBrainSurveys] = React.useState(null);
  const [pageType, setPageType] = React.useState(null);
  const [dialogOpened, setDialogOpened] = React.useState(false);

  useTitleWithEvent(getTitle(pageType, questionDetails), history);

  const loadQuestionDetails = React.useCallback(questionId => {
    api
      .get(`question_detail/${questionId}/`, { headers: { 'X-Accept-Version': '1.0.4' } })
      .then(res => {
        const { data } = res;
        if (data) {
          setQuestonDetails(data);
          setPageType(PageType.Question);
        }
      })
      .catch(err => {
        handleError(err);
      })
      .finally(() => setLoading(false));
  }, []);

  const loadCurrentItem = React.useCallback(() => {
    setLoading(true);
    api
      .get('get_current_item/', { headers: { 'X-Accept-Version': '1.0.4' } })
      .then(({ data }) => {
        if (Capacitor.getPlatform() === 'web' && data?.is_new === true && localStorage.getItem('PLTEST') !== 'true') {
          window.location.href = 'https://prodlab.app.link/app';
        }
        if (data?.type === CurrentItemResponseType.question) {
          const { questions, current_question_index: index } = data;
          loadQuestionDetails(questions[index].id);
        } else if (data?.type === CurrentItemResponseType.survey) {
          setSurveys(data.surveys);
          setPageType(PageType.Surveys);
          if (data.surveys.some(s => s.display_name === 'InBrain')) {
            getInBrainSurveys();
          }
        }
      })
      .catch(err => {
        handleError(err);
      })
      .finally(() => setLoading(false));
  }, [loadQuestionDetails]);

  const loadQuestionBySurvey = React.useCallback(
    surveyId => {
      api
        .get(`get_questions_by_survey/${surveyId}/`, { headers: { 'X-Accept-Version': '1.0.4' } })
        .then(res => {
          const { data } = res;
          if (data) {
            if (data.questions?.length) {
              const { questions, current_question_index: index } = data;
              loadQuestionDetails(questions[index].id);
            }
          }
        })
        .catch(err => {
          handleError(err);
          setLoading(false);
        });
    },
    [loadQuestionDetails]
  );

  const getInBrainSurveys = React.useCallback(() => {
    api
      .get('inbrain/list/')
      .then(res => {
        const { data } = res;
        if (data) {
          setInBrainSurveys(data);
        }
      })
      .catch(err => {
        handleError(err);
      })
      .finally(() => {
        setPageType(PageType.Surveys);
        setLoading(false);
      });
  }, [setInBrainSurveys]);

  React.useEffect(() => {
    loadCurrentItem();
  }, [loadCurrentItem]);

  React.useEffect(() => {
    let interval = setInterval(() => {
      if (pageType === PageType.Surveys) loadCurrentItem();
    }, 1000 * 60 * 60);

    return () => clearInterval(interval);
  }, [loadCurrentItem, pageType]);

  React.useEffect(() => {
    const listener = App.addListener('appStateChange', ({ isActive }) => {
      if (isActive && pageType === PageType.Surveys) {
        loadCurrentItem();
      }
    });
    return () => {
      listener.then(result => {
        result.remove();
      });
    };
  }, [pageType, loadCurrentItem]);

  const handleSubmitAnswer = React.useCallback(
    (url, payload, config, errHandler) => {
      setLoadingButton(true);
      setAnalyzing(true);
      api
        .post(url, payload, config)
        .then(res => {
          setAnalyzing(false);
          sendDataToGTM({
            event: 'SurveyComplete',
            surveyType: questionDetails.question.survey
          });
          setAnalyzing(false);
          setDialogOpened(true);
        })
        .catch(err => {
          setAnalyzing(false);
          errHandler && errHandler(err);
        })
        .finally(() => setLoadingButton(false));
    },
    [sendDataToGTM, questionDetails]
  );

  const handleSubmitDialog = React.useCallback(() => {
    setDialogOpened(false);
    loadCurrentItem();
  }, [loadCurrentItem]);

  const handleClose = () => {
    setDialogOpened(false);
    loadCurrentItem();
  };

  const getInBrainQuestionBySurvey = React.useCallback(item => {
    Browser.open({ url: item.link });
  });

  return isLoading ? (
    <Loader />
  ) : (
    <div className={classes.page}>
      {pageType === PageType.Surveys ? (
        <Surveys
          data={surveys || []}
          inbrainData={inBrainSurveys || []}
          goToDetails={loadQuestionBySurvey}
          goToInBrainDetails={getInBrainQuestionBySurvey}
        />
      ) : null}
      {pageType === PageType.Question && questionDetails ? (
        <>
          <QuestionView
            questionDetails={questionDetails}
            onSubmit={handleSubmitAnswer}
            isLoadingButton={isLoadingButton}
            onClose={handleClose}
          />
          <Dialog
            isOpened={dialogOpened}
            onClose={handleSubmitDialog}
            onSubmit={handleSubmitDialog}
            title="Success!"
            text={
              <>
                Your response has
                <br />
                been submitted.
              </>
            }
            buttonTitle="Continue"
            icon={<DialogSuccessIcon />}
          />
          <LoadingDialog isOpened={isAnalyzing} title={'Analyzing'} text={'Analyzing submission.'} />
        </>
      ) : null}
    </div>
  );
};

const useStyles = makeStyles({
  page: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  }
});

export default Question;
