import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaFacebookF, FaTwitter } from 'react-icons/fa';
import { makeStyles } from '@material-ui/core/styles';
import GraphicWrapper from '../UI/GraphicWrapper';
import springTextLogo from '../../media/spring-text-logo.svg';
import getSpringApp from '../../media/get-spring-app.png';
import getSpringAppQr from '../../media/qr2.png';
import productLabLogoWhite from '../../media/product-lab-logo-white.png';

function isMobileDevice() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

function GetApp() {
  const classes = useStyles();
  const [es, setEs] = useState(false);
  useEffect(() => {
    setEs(!!(navigator?.language ?? '').match(/^es\b/));
  }, [setEs]);

  function onAppGetClick() {
    window.open('https://prodlab.app.link/app', '_blank');
  }

  return (
    <GraphicWrapper>
      <div style={{ display: 'flex', flexFlow: 'column', alignItems: 'center', textAlign: 'center' }}>
        <h1
          style={{
            color: 'white',
            fontFamily: 'Anton',
            fontSize: '50px',
            maxWidth: '520px',
            marginTop: '100px'
          }}
        >
          Make <span style={{ color: '#03DAC6' }}>Extra Cash</span> With The ProductLab App
        </h1>
        <p style={{ color: 'white', maxWidth: '650px', fontSize: '24px' }}>
          Unlock the power of your data to earn on receipts, statements surveys, and more!
        </p>
        {isMobileDevice() ? (
          <button className={classes.getAppButton} onClick={onAppGetClick}>
            Get the App
          </button>
        ) : (
          <>
            <img src={getSpringAppQr} style={{ height: '140px' }} />
            <span style={{ color: 'white' }}>Scan to get the app</span>
          </>
        )}
      </div>

      {/* <img src={productLabLogoWhite} className={classes.logoText} /> */}
    </GraphicWrapper>
  );
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexFlow: 'column',
    width: '100%',
    backgroundColor: '#161C31',
    padding: 10,
    boxSizing: 'border-box',
    height: '100vh',
    minHeight: '100vh',
    '@media (max-width: 768px)': {
      height: 'auto',
      minHeight: '100vh'
    }
  },
  blueBackgroundWrapper: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    width: '100%',
    height: '90%',
    backgroundImage: 'url(/chips.png) , linear-gradient(180deg, #0114BF 0%, #4663F6 100%)',
    backgroundSize: 'contain',
    borderRadius: 10
  },
  mainContentWrapper: {
    display: 'flex',
    flexFlow: 'row',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    padding: '0 50px',
    '@media (max-width: 768px)': {
      flexFlow: 'column',
      padding: '0 24px'
    }
  },
  leftItemWrapper: {
    display: 'flex',
    flexFlow: 'column',
    width: '50%',
    order: 1,
    '@media (max-width: 768px)': {
      width: '100%',
      order: 2
    }
  },
  rightItemWrapper: {
    display: 'flex',
    width: '50%',
    height: '100%',
    order: 2,
    '@media (max-width: 768px)': {
      width: '100%',
      order: 1
    }
  },
  logoText: {
    // width: '150px',
    width: 50,
    position: 'relative',
    top: 0,
    left: 0,
    '@media (max-width: 768px)': {
      // width: '50px',
      width: 30,
      position: 'absolute',
      top: 30,
      left: 30
    }
  },
  splashImage: {},
  getAppButton: {
    backgroundColor: 'white',
    color: 'black',
    border: 0,
    borderRadius: 6,
    padding: '10px 20px',
    fontWeight: 600,
    margin: '20px 0',
    display: 'none',
    '@media (max-width: 768px)': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  },
  qrWrapper: {
    display: 'flex',
    flexFlow: 'row',
    backgroundColor: '#0215C0',
    padding: 4,
    marginTop: 10,
    borderRadius: 10,
    width: 'max-content',
    alignItems: 'center',
    '@media (max-width: 768px)': {
      display: 'none'
    }
  },
  header: {
    font: "40px/48px 'Domine', serif",
    color: '#333646',
    margin: 0,
    fontWeight: 70
  },
  qr: {
    width: 200,
    marginTop: 20
  },
  footerWrapper: {
    width: '100%',
    minHeight: 60,
    display: 'flex',
    alignItems: 'center',
    flexFlow: 'row wrap',
    '@media (max-width: 768px)': {
      margin: '10px 0',
      flexFlow: 'column'
    }
  },
  footerLinksWrapper: {
    display: 'flex',
    justifyContent: 'space-around',
    flexFlow: 'row wrap',
    order: 2,
    '@media (max-width: 768px)': {
      order: 1
    }
  },
  footerLink: {
    margin: '8px 10px',
    color: 'white',
    textDecoration: 'none'
  },
  copywrite: {
    flex: 1,
    order: 1,
    color: '#A4A4A4',
    margin: '8px 10px',
    '@media (max-width: 768px)': {
      order: 3
    }
  },
  socialLinksWrapper: {
    display: 'flex',
    flexFlow: 'row',
    order: 3,
    '@media (max-width: 768px)': {
      order: 2
    }
  }
});

export default GetApp;
