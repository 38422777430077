import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import { ReactComponent as CornerTop } from '../media/landing-corner-top.svg';
import { ReactComponent as CornerBottom } from '../media/landing-corner-bottom.svg';

const LandingGetStarted = () => {
  const classes = useStyles();

  return (
    <section className={classes.section}>
      <div className="wrapper">
        <div className={classes.container}>
          <CornerTop className={classes.cornerTop} />
          <CornerBottom className={classes.cornerBottom} />
          <p className={classes.title}>Share Insights, Get Paid!</p>
          <a href="https://prodlab.app.link/app" className={classes.button}>
            Get Started
          </a>
        </div>
      </div>
    </section>
  );
};

const useStyles = makeStyles({
  section: {
    paddingTop: 80,
    position: 'relative',
    color: '#333646',
    '@media (min-width: 1000px)': {
      paddingBottom: 80,
      paddingTop: 0
    }
  },
  container: {
    position: 'relative',
    backgroundColor: '#F2F2F2',
    borderRadius: 40,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '80px 24px',
    '@media (min-width: 1000px)': {
      alignItems: 'center'
    }
  },
  cornerBottom: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    userSelect: 'none'
  },
  cornerTop: {
    position: 'absolute',
    right: 0,
    top: 0,
    userSelect: 'none'
  },
  title: {
    marginBottom: 40,
    font: "32px/40px 'Domine', serif",
    '@media (min-width: 1000px)': {
      font: "40px/48px 'Domine', serif"
    }
  },
  button: {
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 500,
    color: '#fff',
    textDecoration: 'none',
    textAlign: 'center',
    padding: '12px',
    borderRadius: 4,
    boxSizing: 'border-box',
    backgroundColor: '#579862',
    width: '100%',
    zIndex: 1,
    '&:hover': {
      color: '#fff',
      backgroundColor: '#4E8958'
    },
    '&:active': {
      color: '#fff',
      backgroundColor: '#467B4F'
    },
    '@media (min-width: 1000px)': {
      width: 240
    }
  }
});

export default LandingGetStarted;
