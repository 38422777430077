import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import { Radio } from './Radio';
import { Button } from './Button';

const OPTION_NONE = 'None of the above';

const QuestionMultipleChoice = ({ question, onSubmit, isLoadingButton, options }) => {
  const classes = useStyles();

  const [multipleChoices, setMultipleChoices] = React.useState({});
  React.useEffect(() => {
    const selectedValues = {};
    options.forEach(x => (selectedValues[x.value] = x.is_selected || false));
    setMultipleChoices(selectedValues);
  }, [options, setMultipleChoices]);

  const onMultipleChoiceChange = React.useCallback(e => {
    const choice = e.target.value;
    const selected = e.target.checked;

    if (choice === OPTION_NONE && selected) {
      setMultipleChoices({ [choice]: selected });
    } else {
      setMultipleChoices(prev => {
        const ret = {
          ...prev,
          [choice]: selected
        };
        delete ret[OPTION_NONE];
        return ret;
      });
    }
  }, []);

  const submitAnswer = React.useCallback(() => {
    const payload = {
      question: {
        question_id: question?.id,
        answer_type: question?.answer_type,
        answer: Object.keys(multipleChoices).filter(key => multipleChoices[key])
      }
    };
    onSubmit('submission_create/', payload, {});
  }, [onSubmit, multipleChoices, question]);

  return (
    <React.Fragment>
      <div className={classes.row}>
        <div className={classes.rowLabel}>{question?.question_description}</div>
        <div className={classes.radioGroup}>
          {options?.map(option => (
            <Radio
              key={option.id}
              value={option.value}
              label={option.value}
              checked={multipleChoices[option.value] || false}
              onChange={onMultipleChoiceChange}
              asCheckbox
            />
          ))}
        </div>
      </div>
      <div className={classes.submitButtonWrap}>
        <div>
          <Button
            className={classes.submitButton}
            onClick={submitAnswer}
            disabled={isLoadingButton || !Object.keys(multipleChoices).some(key => !!multipleChoices[key])}
            loading={isLoadingButton}
          >
            Submit
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
};

QuestionMultipleChoice.propTypes = {
  question: PropTypes.object,
  onSubmit: PropTypes.func,
  isLoadingButton: PropTypes.bool,
  options: PropTypes.array
};

const useStyles = makeStyles({
  radioGroupLabel: {
    fontSize: 16,
    lineHeight: '24px',
    color: '#5C5E6C',
    marginBottom: 8
  },
  row: {
    marginBottom: 24
  },
  rowLabel: {
    fontSize: 16,
    lineHeight: '24px',
    color: '#5C5E6C',
    marginBottom: 8
  },
  radioGroup: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column'
  },
  submitButtonWrap: {
    marginTop: 16,
    paddingBottom: 24
  },
  submitButton: {
    width: '100%'
  }
});

export default QuestionMultipleChoice;
