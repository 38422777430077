import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Button from './Button';

const NavButton = props => {
  const classes = useStyles();

  return <Button {...props} />;
};

const useStyles = makeStyles({
  navButton: {}
});

export default NavButton;
