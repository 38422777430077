import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import NavButton from './NavButton';

const Nav = ({ currentPage, onCurrentPageChange, pageCount }) => {
  const classes = useStyles();

  return (
    <Box display="flex" flexDirection="column-reverse" className={classes.nav}>
      {currentPage !== undefined && currentPage <= pageCount - 2 ? (
        <NavButton className={classes.button} onClick={() => onCurrentPageChange(currentPage + 1, 'next')}>
          Next
        </NavButton>
      ) : null}
      {currentPage !== undefined && currentPage === pageCount - 1 ? (
        <NavButton className={classes.button} onClick={() => onCurrentPageChange(undefined, 'complete')}>
          Complete
        </NavButton>
      ) : null}
      {currentPage !== undefined && currentPage > 0 ? (
        <NavButton
          color="secondary"
          className={classes.button}
          onClick={() => onCurrentPageChange(currentPage - 1, 'prev')}
        >
          Previous
        </NavButton>
      ) : null}
    </Box>
  );
};

const useStyles = makeStyles({
  nav: {
    paddingTop: 10,
    paddingBottom: 25,
    paddingLeft: 24,
    paddingRight: 24
  },
  button: {
    marginTop: 10
  }
});

export default Nav;
