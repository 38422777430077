import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import HistoryContainer from '../UI/HistoryContainer';

import { formatCurrency } from '../../utils';
import { useTitleWithEvent } from '../../hooks';

const SurveyHistory = ({ history }) => {
  const classes = useStyles();

  useTitleWithEvent('Survey History', history);

  return (
    <HistoryContainer
      pageTitle="Survey History"
      renderItem={item => (
        <div className={classes.item} key={item.id}>
          <div className={classes.itemAction}>{item.action}</div>
          <div className={classes.itemAmount}>+ {formatCurrency(item.amount)}</div>
        </div>
      )}
    />
  );
};

const useStyles = makeStyles({
  item: {
    padding: '12px 24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  itemAction: {
    fontSize: 16,
    lineHeight: '24px',
    color: '#333646'
  },
  itemAmount: {
    font: "16px/24px 'Roboto', serif",
    color: '#579862',
    fontWeight: 500,
    whiteSpace: 'nowrap',
    paddingLeft: 8
  }
});

export default SurveyHistory;
