import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Profile from './components/screens/Profile';
import ProfileEdit from './components/screens/ProfileEdit';
import Question from './components/screens/Question';
import PaymentBalance from './components/screens/PaymentBalance';
import PayoutHistory from './components/screens/PayoutHistory';
import SurveyHistory from './components/screens/SurveyHistory';
import ProfileSurvey from './components/screens/ProfileSurvey';
import BottomNav from './components/UI/BottomNav';
import history from './core/history';
import { handleError } from './utils/errors';
import { getUserId } from './core/auth';
import { profileChanged } from './actions';
import { ReactComponent as LogoIcon } from './media/icons/logo.svg';
import { Capacitor } from '@capacitor/core';
import { PushNotifications } from '@capacitor/push-notifications';
import { useHandleWsEvents } from './hooks';

import './App.css';
import api from './core/api';

const isPushNotificationsAvailable = Capacitor.isPluginAvailable('PushNotifications');
const platform = Capacitor.getPlatform();

const App = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);
  useHandleWsEvents(auth.profile);

  React.useEffect(() => {
    if (isPushNotificationsAvailable) {
      PushNotifications.requestPermissions().then(permission => {
        if (permission.receive === 'granted') {
          PushNotifications.register().then();
        }
      });
    }
  }, []);

  React.useEffect(() => {
    if (isPushNotificationsAvailable) {
      PushNotifications.addListener('registration', async token => {
        await api.put('/users/me/device-token/', {
          platform: platform,
          token: token.value
        });
      });
      return () => {
        PushNotifications.removeAllListeners();
      };
    }
  }, []);

  React.useEffect(() => {
    fetchUser();
  }, []);

  const fetchUser = React.useCallback(() => {
    api
      .get(`users/${getUserId()}/`)
      .then(res => {
        const profile = res.data.profile;
        dispatch(profileChanged(profile));
      })
      .catch(err => {
        handleError(err);
      })
      .finally(() => {});
  }, []);

  return (
    <div className={classes.page}>
      <div className={`app-header ${classes.header}`}>
        <LogoIcon />
      </div>
      <div className={`app-content ${classes.content}`}>
        <Switch>
          <Route path="/app/profile/edit" component={ProfileEdit} />
          <Route path="/app/profile/survey" component={ProfileSurvey} />
          <Route path="/app/profile" component={Profile} />
          <Route path="/app/payment/history/payout" component={PayoutHistory} />
          <Route path="/app/payment/history/survey" component={SurveyHistory} />
          <Route path="/app/payment" component={PaymentBalance} />
          <Route path="/app" render={() => <Question history={history} key={Math.random()} />} />
        </Switch>
      </div>
      <BottomNav />
    </div>
  );
};

const useStyles = makeStyles({
  page: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  header: {
    minHeight: 64,
    display: 'flex',
    padding: '12px 16px',
    boxSizing: 'border-box',
    '@supports (padding-top: env(safe-area-inset-top))': {
      paddingTop: 'max(12px + env(safe-area-inset-top))'
    }
  },
  content: {
    flex: '1',
    overflowY: 'auto',
    overflowX: 'hidden',
    paddingLeft: 24,
    paddingRight: 24,
    touchAction: 'pan-x pan-y'
  }
});

export default App;
