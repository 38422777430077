import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import MaterialUiButton from '@material-ui/core/Button';

import Loader from '../UI/Loader';

const colorsMap = {
  primary: {
    backgroundColor: '#579862',
    color: '#fff',
    backgroundColorHover: '#4E8958',
    colorHover: '#fff',
    backgroundColorActive: '#467B4F',
    colorActive: '#fff',
    backgroundColorDisabled: '#EEEEEE',
    colorDisabled: 'rgba(0,0,0,.3)'
  },
  secondary: {
    backgroundColor: '#EEEEEE',
    color: '#000',
    backgroundColorHover: '#D6D6D6',
    colorHover: '#000',
    backgroundColorActive: '#C1C1C1',
    colorActive: '#000',
    backgroundColorDisabled: '#EEEEEE',
    colorDisabled: '#000'
  },
  inherit: {
    backgroundColor: '#D62121',
    color: '#fff',
    backgroundColorHover: '#C11E1E',
    colorHover: '#fff',
    backgroundColorActive: '#AE1B1B',
    colorActive: '#fff',
    backgroundColorDisabled: '#EEEEEE',
    colorDisabled: 'rgba(0,0,0,.3)'
  }
};

const getColor = (color, type) => {
  return colorsMap[color][type];
};

const StyledButton = withStyles({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    padding: '12px',
    border: 'none',
    lineHeight: '24px',
    backgroundColor: props => getColor(props.color, 'backgroundColor'),
    color: props => getColor(props.color, 'color'),
    fontWeight: 500,
    borderRadius: 4,
    minWidth: 48,
    '&:hover': {
      backgroundColor: props => getColor(props.color, 'backgroundColorHover'),
      color: props => getColor(props.color, 'colorHover'),
      boxShadow: 'none'
    },
    '&:active': {
      backgroundColor: props => getColor(props.color, 'backgroundColorActive'),
      color: props => getColor(props.color, 'colorActive'),
      boxShadow: 'none'
    },
    '&:disabled': {
      backgroundColor: props => getColor(props.color, 'backgroundColorDisabled'),
      color: props => getColor(props.color, 'colorDisabled'),
      boxShadow: 'none'
    },
    '&:focus': {
      boxShadow: 'none'
    },
    '& .loader': {
      position: 'static',
      backgroundColor: 'transparent',
      '& svg': {
        width: 24,
        height: 24
      }
    },
    '& .MuiButton-startIcon': {
      display: props => (props.loading === 'true' ? 'none' : 'inherit')
    }
  }
})(MaterialUiButton);

const Button = ({ children, color = 'primary', loading = false, ...rest }) => {
  return (
    <StyledButton disableRipple color={color} loading={loading.toString()} {...rest}>
      {loading ? <Loader /> : children}
    </StyledButton>
  );
};

Button.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary', 'inherit']),
  loading: PropTypes.bool
};

export { Button };
