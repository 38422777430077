import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Confirm from '../Confirm';

import { ReactComponent as UploadIcon } from '../../../media/icons/upload.svg';
import { ReactComponent as TrashIcon } from '../../../media/icons/trash.svg';

const useFileObjectUrl = file => {
  const [url, setUrl] = React.useState(null);
  React.useEffect(() => {
    setUrl(URL.createObjectURL(file));
    return () => URL.revokeObjectURL(url);
  }, [file]);
  return url;
};

const ImagePreview = ({ file, ...props }) => {
  const url = useFileObjectUrl(file);
  return url ? <img {...props} src={url} alt={file.name} /> : null;
};

const ImageInput = ({ value, onChange, questionModel }) => {
  const classes = useStyles();

  const submitButton = React.useRef(null);

  const [imageToDelete, setImageToDelete] = React.useState(null);
  const [confirmOpened, setConfirmOpened] = React.useState(false);

  const openConfirm = image => {
    setImageToDelete(image);
    setConfirmOpened(true);
  };

  const closeConfirm = () => {
    setConfirmOpened(false);
  };

  const onSelectImages = e => {
    const files = [...e.target.files];
    onChange([...(value || []), ...files]);
    e.target.value = null;
    if (submitButton.current) {
      submitButton.current.scrollIntoView();
    }
  };

  const confirmDelete = () => {
    const fileName = imageToDelete?.name;
    onChange(value.filter(image => image.name !== fileName));
    closeConfirm();
  };

  return (
    <React.Fragment>
      <div className={classes.content}>
        <div className={classes.imgList}>
          {(value || []).map((image, i) => (
            <div
              key={`img${i}`}
              style={{
                display: 'flex',
                flexDirection: 'column',
                color: 'red'
              }}
            >
              <div className={classes.imgListItem} key={`${image.name}${i}`}>
                <ImagePreview className={classes.imgListItemImg} file={image} />
                <div className={classes.imgListItemBtnWrap}>
                  <div className={classes.imgListItemBtn} onClick={() => openConfirm(image)}>
                    <TrashIcon />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div>
        <div className={classes.uploadButton}>
          <UploadIcon />
          {'upload_button_text' in questionModel && typeof questionModel.upload_button_text === 'string'
            ? questionModel.upload_button_text
            : 'Add Image(s)'}
          <input id="upload" type="file" accept="image/*" multiple onChange={onSelectImages} />
        </div>
      </div>
      <div className={classes.disclaimer}>
        <p className={classes.disclaimerText}>
          Do not upload images containing sensitive personal information or other information that we do not require.
        </p>
      </div>
      <Confirm
        opened={confirmOpened}
        text="Are you sure you want to delete selected file?"
        onClose={closeConfirm}
        onYes={confirmDelete}
      />
    </React.Fragment>
  );
};

const useStyles = makeStyles({
  row: {
    marginBottom: 24
  },
  rowLabel: {
    fontSize: 16,
    lineHeight: '24px',
    color: '#5C5E6C'
  },
  content: {
    marginBottom: '40px'
  },
  imgPreview: {
    maxWidth: '100%',
    maxHeight: '100%'
  },
  imgList: {
    height: '100%',
    overflowY: 'auto'
  },
  imgListItem: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px 0 8px 20px',
    borderTop: '1px solid #E9E9E9',
    '&:first-child': {
      borderTop: 'none'
    }
  },
  imgListItemImg: {
    maxWidth: 250,
    maxHeight: 150,
    objectFit: 'contain'
  },
  imgListItemStatus: {
    marginLeft: 16,
    marginRight: 16,
    '& span': {
      display: 'inline-flex',
      padding: '4px 16px',
      borderRadius: 4,
      fontSize: 16,
      lineHeight: '24px',
      '&.uploaded': {
        color: '#2B508D',
        backgroundColor: 'rgba(43,80,141, .1)'
      },
      '&.approved': {
        color: '#579862',
        backgroundColor: 'rgba(87,152,98, .1)'
      },
      '&.uploading': {
        color: '#5C5E6C',
        backgroundColor: 'rgba(92,94,108, .1)'
      }
    }
  },
  imgListItemBtnWrap: {
    marginLeft: 'auto',
    minWidth: 24
  },
  imgListItemBtn: {
    width: 24,
    height: 24,
    color: '#B8B8B8',
    '& svg path': {
      fill: 'currentColor'
    }
  },
  '@keyframes loader': {
    from: {
      transform: 'rotate(0deg)'
    },
    to: {
      transform: 'rotate(360deg)'
    }
  },
  imgListItemLoader: {
    animationName: '$loader',
    animationDuration: '1000ms',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'linear',
    display: 'flex'
  },
  uploadButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    background: '#fff',
    border: '1px dashed #A0AFC4',
    boxSizing: 'border-box',
    borderRadius: 4,
    fontFamily: 'inherit',
    fontSize: 16,
    lineHeight: '24px',
    color: '#2B508D',
    fontWeight: 500,
    padding: 23,
    marginBottom: 24,
    position: 'relative',
    '& svg': {
      marginRight: 8,
      '& path': {
        fill: 'currentColor'
      }
    },
    '&:disabled': {
      color: 'rgba(0,0,0,.3)',
      borderColor: '#EEEEEE'
    },
    '& input': {
      position: 'absolute',
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      opacity: 0.001,
      width: '100%',
      height: '100%'
    }
  },
  disclaimer: {
    background: '#F2F2F2',
    border: '1px solid #C4CBD6',
    boxSizing: 'border-box',
    borderRadius: 4,
    padding: 15
  },
  disclaimerText: {
    margin: 0,
    fontSize: 14,
    lineHeight: '24px',
    color: '#5C5E6C',
    marginBottom: 8
  },
  dialogImgPreview: {
    '& .MuiBackdrop-root': {
      backgroundColor: '#000'
    },
    '& .MuiDialog-paper': {
      overflow: 'visible',
      margin: 0,
      maxWidth: '100%',
      width: '100%',
      height: '100%',
      maxHeight: '100%',
      boxShadow: 'none',
      backgroundColor: 'transparent'
    }
  },
  dialogImgPreviewContent: {
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%'
  },
  dialogImg: {
    maxWidth: '100%',
    maxHeight: '100%'
  }
});

export default ImageInput;
