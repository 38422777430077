import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Form, Formik } from 'formik';
import { useHistory } from 'react-router-dom';

import GraphicWrapper from '../components/UI/GraphicWrapper';
import Privacy from '../components/UI/Privacy';
import { Button } from '../components/UI/Button';
import InputField from '../components/UI/InputField';

import { ReactComponent as ChevronDownIcon } from '../media/icons/chevron-down.svg';
import { ReactComponent as ChevronLeftIcon } from '../media/icons/chevron-left.svg';

import api from '../core/api';
import { getUserId } from '../core/auth';
import { handleError } from '../utils/errors';
import { getFullSiteUrl } from '../utils/urls';
import { composeValidators, validatePhone, validateRequired, validateEmail } from '../utils/validators';

const options = [
  {
    value: 'Support',
    label: 'Support'
  },
  {
    value: 'Business Inquiry',
    label: 'Business Inquiry'
  },
  {
    value: 'Other',
    label: 'Other'
  }
];

const ContactUs = () => {
  const classes = useStyles();
  const history = useHistory();

  const [success, setSuccess] = React.useState(false);

  const handleSubmit = (values, actions) => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    api
      .post('contact-us/', { ...values }, config)
      .then(() => {
        setSuccess(true);
        if (values.reason === 'Delete Account') {
          history.push('/');
        }
      })
      .catch(err => {
        actions.setSubmitting(false);
        handleError(err);
      });
  };

  return (
    <GraphicWrapper className={`${classes.page} ${success ? classes.successPage : ''}`}>
      {success ? (
        <div className={classes.form}>
          <h1 className={classes.successTitle}>Thank you</h1>
          <p className={classes.successText}>For getting in touch with us</p>
          <Button color="secondary" className={classes.succesLink} onClick={() => history.push('/')}>
            Back to site
          </Button>
        </div>
      ) : (
        <div className={classes.form}>
          <h1 className={classes.pageTitle}>Contact Us</h1>

          <Formik
            initialValues={{
              first_name: '',
              last_name: '',
              phone: '',
              email: '',
              reason: options[0].value,
              message: ''
            }}
            onSubmit={handleSubmit}
          >
            {props => (
              <Form>
                <InputField
                  type="tel"
                  name="phone"
                  className={classes.textbox}
                  placeholder="Phone"
                  validate={composeValidators(validateRequired, validatePhone)}
                />
                <InputField
                  select
                  name="reason"
                  className={classes.textbox}
                  placeholder="Reason for Contact"
                  SelectProps={{
                    native: true,
                    IconComponent: ChevronDownIcon
                  }}
                  validate={validateRequired}
                >
                  {options.map(option => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </InputField>
                <InputField
                  multiline
                  name="message"
                  rows={4}
                  className={classes.textbox}
                  placeholder="Message"
                  validate={validateRequired}
                />
                <div className={`policy-block ${classes.copyright}`}>
                  <p style={{ color: 'white' }}>
                    By participating you agree to our&nbsp;
                    <a
                      href={getFullSiteUrl('/privacy')}
                      style={{ color: 'black' }}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Privacy Policy
                    </a>
                    &nbsp;and&nbsp;
                    <a
                      href={getFullSiteUrl('/terms')}
                      style={{ color: 'black' }}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Terms &amp; Conditions
                    </a>
                    .
                  </p>
                </div>
                <div className={classes.buttonContainer}>
                  <Button
                    type="submit"
                    className={classes.submitButton}
                    disabled={!props.dirty || !props.isValid}
                    loading={props.isSubmitting}
                  >
                    Submit
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </GraphicWrapper>
  );
};

const footerHeightDesktop = 105;
const footerHeightTablet = 145;

const useStyles = makeStyles({
  page: {
    minHeight: 'calc(100vh - 96px)',
    height: 'auto',
    '@media (min-width: 1000px)': {
      marginBottom: -footerHeightDesktop
    },
    '@media (min-width: 1000px) and (max-width: 1179px)': {
      marginBottom: -footerHeightTablet
    },
    '& .login-wrap-left': {
      margin: 'auto 0',
      '@media (min-width: 1000px)': {
        paddingBottom: footerHeightDesktop + 24
      },
      '@media (min-width: 1000px) and (max-width: 1179px)': {
        paddingBottom: footerHeightTablet + 24
      }
    }
  },
  form: {
    '@media (max-width: 999px)': {
      paddingBottom: 16
    },
    '@media (min-width: 1000px)': {
      padding: '56px 0'
    },
    '& .policy-block': {
      paddingTop: 16,
      paddingBottom: 24
    }
  },
  pageTitle: {
    margin: '0 0 40px',
    color: 'white',
    fontWeight: 700
  },
  textbox: {
    width: '100%',
    marginBottom: 24,
    '& .MuiOutlinedInput-root': {
      backgroundColor: '#161C31',
      borderColor: '#161C31',
      color: 'white'
    },
    '& .MuiOutlinedInput-root svg path': {
      fill: 'white'
    },
    '& .MuiOutlinedInput-root input::placeholder': {
      color: '#969696'
    },
    '& .MuiOutlinedInput-root textarea::placeholder': {
      color: '#969696'
    },
    '& .MuiOutlinedInput-root fieldset': {
      borderColor: '#161C31',
      color: 'white'
    }
  },
  submitButton: {
    width: '100%',
    marginTop: 16,
    backgroundColor: 'white',
    color: 'black',
    '&:disabled': {
      color: 'gray',
      backgroundColor: '#eeeeeed4'
    },
    '&:active': {
      color: 'gray',
      backgroundColor: 'white'
    },
    '& .loader svg circle': {
      fill: 'currentColor'
    }
  },
  deleteButton: {
    width: '100%',
    marginTop: 10,
    '& .loader svg circle': {
      fill: 'currentColor'
    }
  },
  successPage: {
    '@media (max-width: 999px)': {
      minHeight: 'unset'
    }
  },
  successTitle: {
    margin: 0,
    // font: "40px/48px 'Domine', serif",
    color: 'white',
    fontWeight: 700
  },
  successText: {
    margin: '16px 0 0',
    fontSize: 16,
    lineHeight: '24px',
    color: 'white'
  },
  succesLink: {
    width: '100%',
    marginTop: 40
  },
  backLink: {
    width: '100%',
    marginTop: 10
  }
});

export default ContactUs;
