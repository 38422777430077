import React from 'react';
import { Box } from '@material-ui/core';

import TextField from './TextField';

const NumberInput = ({ questionModel, value, onChange }) => {
  return (
    <Box>
      <TextField
        type="number"
        value={value === undefined ? '' : value}
        onChange={({ target: { value } }) => onChange(value)}
        pattern="\d*"
        fullWidth
        autoFocus
      />
    </Box>
  );
};

export default NumberInput;
