import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import hexagon from '../media/icons/privacy-hexagon.svg';

const PrivacyTermsWrap = ({ children }) => {
  const classes = useStyles();

  return (
    <section className={classes.page}>
      <div className="wrapper">{children}</div>
    </section>
  );
};

PrivacyTermsWrap.propTypes = {
  children: PropTypes.any
};

const useStyles = makeStyles({
  page: {
    position: 'relative',
    paddingTop: 80,
    paddingBottom: 40,
    '@media (min-width: 1000px)': {
      paddingBottom: 80
    },
    '& h1': {
      font: "40px/48px 'Domine', serif",
      fontWeight: 700,
      margin: 0,
      color: '#14315C'
    },
    '& h2': {
      fontSize: 24,
      lineHeight: '32px',
      fontWeight: 500,
      margin: '40px 0 8px',
      color: '#333646'
    },
    '& p': {
      color: '#333646',
      margin: '0 0 24px',
      fontSize: 16,
      lineHeight: '24px',
      '&._effective-date': {
        fontSize: 14,
        color: '#5C5E6C',
        margin: '16px 0 80px'
      },
      '&._last-terms-text': {
        fontSize: 14,
        color: '#5C5E6C',
        margin: '40px 0 0'
      },
      '& a': {
        textDecoration: 'none',
        color: 'rgb(51, 80, 137)',
        fontWeight: 500,
        wordBreak: 'break-word'
      }
    },
    '& .alpha-list': {
      counterReset: 'alphaList',
      listStyle: 'none',
      padding: 0,
      margin: 0,
      '& ul': {
        marginLeft: -32
      },
      '& > li': {
        position: 'relative',
        paddingLeft: 32,
        '& > span': {
          fontSize: 16,
          lineHeight: '24px',
          fontWeight: 500,
          color: '#5C5E6C',
          display: 'block',
          paddingBottom: 16,
          '@media (min-width: 1000px)': {
            paddingBottom: 8
          }
        },
        '& > p': {
          marginBottom: 16
        },
        '&:before': {
          counterIncrement: 'alphaList',
          content: 'counter(alphaList, lower-alpha)',
          position: 'absolute',
          width: 24,
          height: 24,
          left: 0,
          top: 0,
          fontSize: 14,
          lineHeight: '22px',
          fontWeight: 500,
          color: '#333646',
          textAlign: 'center',
          background: `url(${hexagon}) no-repeat transparent center center`
        }
      }
    },
    '& .dot-list': {
      listStyle: 'none',
      padding: 0,
      margin: 0,
      '& > li': {
        position: 'relative',
        paddingLeft: 32,
        '&._divider': {
          padding: '8px 0',
          '&:before': {
            display: 'none'
          }
        },
        '&:not(:last-child)': {
          '& p': {
            marginBottom: 8
          }
        },
        '&:before': {
          content: '""',
          position: 'absolute',
          width: 4,
          height: 4,
          left: 10,
          top: 10,
          backgroundColor: '#333646',
          borderRadius: 4
        }
      }
    },
    '& .table-wrap': {
      overflowY: 'auto',
      margin: '0 -24px',
      padding: '0 24px'
    },
    '& table': {
      borderCollapse: 'collapse',
      width: '100%',
      maxWidth: '100%',
      minWidth: 600,
      border: 'none',
      margin: '0 0 24px',
      '& th': {
        paddingRight: 16,
        paddingTop: 12,
        paddingBottom: 12,
        fontSize: 16,
        lineHeight: '24px',
        fontWeight: 500,
        textAlign: 'left',
        backgroundColor: '#C4CBD6',
        color: '#14315C',
        width: '30%',
        '&:first-child': {
          paddingLeft: 16,
          borderTopLeftRadius: 6,
          borderBottomLeftRadius: 6,
          width: '40%'
        },
        '&:last-child': {
          borderTopRightRadius: 6,
          borderBottomRightRadius: 6
        }
      },
      '& td': {
        paddingRight: 16,
        paddingTop: 16,
        paddingBottom: 16,
        fontSize: 16,
        lineHeight: '24px',
        color: '#333646',
        width: '30%',
        '&:first-child': {
          paddingLeft: 16,
          width: '40%'
        }
      },
      '& tr': {
        '&:nth-child(odd)': {
          backgroundColor: '#F2F2F2'
        }
      }
    }
  }
});

export default PrivacyTermsWrap;
