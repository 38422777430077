import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Field } from 'formik';

import { TextField } from './TextField';

const InputField = ({ className, name, title, validate, ...props }) => {
  const classes = useStyles();

  return (
    <Field name={name} validate={validate}>
      {({ field, meta }) => (
        <div className={className}>
          <div className={classes.label}>{title}</div>
          <TextField className={classes.input} {...props} {...field} />
          {meta.touched && meta.error && <div className={classes.error}>{meta.error}</div>}
        </div>
      )}
    </Field>
  );
};

const useStyles = makeStyles({
  label: {
    fontSize: 16,
    lineHeight: '24px',
    color: '#8A8B94',
    marginBottom: 8
  },
  input: {
    width: '100%'
  },
  error: {
    fontSize: 16,
    lineHeight: '24px',
    width: '100%',
    color: '#D62121',
    marginBottom: 8
  }
});

export default InputField;
