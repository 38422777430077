import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { Form, Formik } from 'formik';

import api from '../../core/api';
import { getUserId } from '../../core/auth';
import { validatePhone } from '../../utils/validators';

import PageHeader from '../UI/PageHeader';
import Privacy from '../UI/Privacy';
import { Button } from '../UI/Button';
import InputField from '../UI/InputField';
import LocationInputField from '../UI/LocationInputField';
import Loader from '../UI/Loader';
import { geocode } from '../../utils/geocoder';
import { handleError } from '../../utils/errors';
import { useTitleWithEvent } from '../../hooks';

const fields = ['first_name', 'last_name', 'phone_number', 'latitude', 'longitude', 'status'];

const ProfileEdit = ({ history }) => {
  useTitleWithEvent('Edit Profile', history);

  const classes = useStyles();

  const [user, setUser] = React.useState({});
  const [region, setRegion] = React.useState(undefined);
  const [isLoading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const fetchData = () => {
      api
        .get(`users/${getUserId()}/`)
        .then(res => {
          const { data } = res;
          if (data) {
            setUser(data);
          }
        })
        .catch(err => {
          handleError(err);
        })
        .finally(() => setLoading(false));
    };

    fetchData();
  }, []);

  React.useEffect(() => {
    if (!user || !user.profile) return;

    const lat = user?.profile?.latitude;
    const lng = user?.profile?.longitude;

    if (!lat || !lng || (lat === 0 && lng === 0)) return;
    geocode(lat, lng, setRegion);
  }, [user]);

  if (isLoading) {
    return <Loader />;
  }

  const handleSubmit = (values, actions) => {
    const payload = {};
    for (const field of fields) {
      payload[field] = values[field];
    }

    api
      .put(`profile_update/${user.profile.id}/`, payload)
      .then(res => {
        setUser({
          ...user,
          profile: res.data
        });
        actions.setSubmitting(false);
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };
  return (
    <div className={classes.page}>
      <PageHeader backBtn>Edit Profile</PageHeader>
      <Formik initialValues={user.profile} onSubmit={handleSubmit}>
        {props => (
          <Form>
            <InputField
              className={classes.row}
              name="first_name"
              placeholder="Enter First Name"
              title="First Name"
              autoComplete="given-name"
            />
            <InputField
              className={classes.row}
              name="last_name"
              placeholder="Enter Last Name"
              title="Last Name"
              autoComplete="family-name"
            />
            <InputField
              className={classes.row}
              name="phone_number"
              placeholder="Enter Phone"
              type="tel"
              title="Phone"
              validate={validatePhone}
            />

            <div className={classes.row}>
              <div className={classes.rowLabel}>Region</div>
              <LocationInputField
                inputStyle={classes.input}
                address={region ?? ''}
                onSelect={location => {
                  props.setFieldValue('latitude', location.latitude);
                  props.setFieldValue('longitude', location.longitude);
                  geocode(location.latitude, location.longitude, setRegion);
                }}
              />
            </div>
            <div className={classes.buttonsWrap}>
              {/* <div>
                <Button
                  color="secondary"
                  className={classes.button}
                  onClick={goTochangePass}
                >
                  Change Password
                </Button>
              </div> */}
              <div>
                <Button type="submit" className={classes.button} disabled={!props.dirty || props.isSubmitting}>
                  Save Changes
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <Privacy />
    </div>
  );
};

ProfileEdit.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  })
};

const useStyles = makeStyles({
  page: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    '& .policy-block': {
      marginTop: 0
    }
  },
  row: {
    marginBottom: 24,
    position: 'relative'
  },
  rowLabel: {
    fontSize: 16,
    lineHeight: '24px',
    color: '#5C5E6C',
    marginBottom: 8
  },
  input: {
    width: '100%'
  },
  buttonsWrap: {
    marginTop: 'auto'
  },
  button: {
    marginTop: 16,
    width: '100%',
    color: 'white'
  }
});

export default withRouter(ProfileEdit);
