import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';

import { getToken } from '../../core/auth';

const PrivateRoute = ({ component: Page, ...rest }) => {
  const token = getToken();

  return (
    <Route
      render={props =>
        token ? <Page {...props} /> : <Redirect to={{ pathname: '/login', state: { referer: rest.location } }} />
      }
      {...rest}
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.func
};

export default PrivateRoute;
