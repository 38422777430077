import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Survey from './survey/Survey';

const useStyles = makeStyles({
  wrapper: {
    marginBottom: 8
  }
});

function getFirstErrorPage(surveyModel, errors) {
  let pageIndex = 0;
  for (const page of surveyModel.pages) {
    for (const element of page.elements) {
      if (errors[element.name]) {
        return pageIndex;
      }
    }
    pageIndex += 1;
  }
}

const QuestionSurveyJs = ({ question, onSubmit }) => {
  const [errors, setErrors] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [lastPageAction, setLastPageAction] = useState('initial');
  const [data, setData] = useState({});

  const sourceSurveyModel = question?.survey_js?.data;
  const surveyModel = {
    ...sourceSurveyModel
  };

  const handleDataChange = newData => {
    setData({ ...data, ...newData });
  };

  const handleError = err => {
    const data = err.response;
    setLastPageAction('initial');
    if (Object.keys(data.errors || {}).length > 0) {
      setErrors(data.errors);
      const page = getFirstErrorPage(surveyModel, data.errors);
      setData({ ...data, [page.name]: null });
      setCurrentPage(page === undefined ? surveyModel.pages.length - 1 : page);
    } else {
      const page = surveyModel.pages.length - 1;
      setCurrentPage(page);
      const lastPageName = surveyModel.pages[page].name;
      setErrors({ [lastPageName]: data.error.message });
    }
  };

  const handleComplete = () => {
    const fileAnswers = Object.entries(data).filter(
      ([key, value]) => Array.isArray(value) && value.length > 0 && value[0] instanceof File
    );
    if (fileAnswers.length > 0) {
      const payload = new FormData();
      let imageIndex = 0;
      const fileAnswerData = fileAnswers.map(([key, value]) => {
        return [
          key,
          value.map(file => {
            const result = imageIndex;
            payload.append('image', file);
            imageIndex += 1;
            return { image: result };
          })
        ];
      });
      const payloadData = {
        question: {
          question_id: question?.id,
          answer_type: question?.answer_type,
          answer: { ...data, ...Object.fromEntries(fileAnswerData) }
        }
      };
      payload.append('data', JSON.stringify(payloadData));
      onSubmit(
        'submission_create/',
        payload,
        {
          headers: {
            Accept: 'application/json',
            'content-type': 'multipart/form-data'
          }
        },
        handleError
      );
    } else {
      const payload = {
        question: {
          question_id: question?.id,
          answer_type: question?.answer_type,
          answer: data
        }
      };
      onSubmit('submission_create/', payload, {}, handleError);
    }
  };

  return (
    <Survey
      surveyModel={surveyModel}
      data={data}
      onDataChange={handleDataChange}
      currentPage={currentPage}
      onCurrentPageChange={setCurrentPage}
      lastPageAction={lastPageAction}
      onLastPageActionChange={setLastPageAction}
      onComplete={handleComplete}
      errors={errors}
      onErrorsChange={setErrors}
    />
  );
};

const useStylesNew = makeStyles({
  wrapper: {
    marginBottom: 8
  }
});

export default QuestionSurveyJs;
