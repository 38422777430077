import { PaymentActionType } from './enums';

export const formatCurrency = value => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2
  });
  return formatter.format(value);
};

export const formatPaymentAmount = payment => {
  switch (payment.action) {
    case PaymentActionType.survey:
      return `+ ${formatCurrency(payment.amount)}`;
    case PaymentActionType.withdrawal:
    case PaymentActionType.reverse:
      return `- ${formatCurrency(payment.amount)}`;
    default:
      return;
  }
};

export const formatDateTime = dateString => {
  if (!dateString) {
    return;
  }
  const date = new Date(dateString);

  let hours = date.getHours();
  let minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes < 10 ? '0' + minutes : minutes;
  const timeString = hours + ':' + minutes + ' ' + ampm;
  return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()} at ${timeString}`;
};

export const formatDate = dateString => {
  if (!dateString) {
    return;
  }
  const date = new Date(dateString);
  return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
};

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

const dayShortNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export const formatPaymentHistoryDate = dateString => {
  if (!dateString) {
    return;
  }
  const date = new Date(dateString);

  let today = new Date();
  today.setHours(0);
  today.setMinutes(0);
  today.setSeconds(0);
  today.setMilliseconds(0);

  const dateToCompmare = new Date(date.getFullYear(), date.getMonth(), date.getDate());

  const diff = today.getTime() - dateToCompmare.getTime();

  if (dateToCompmare.getTime() === today.getTime()) {
    return 'Today';
  } else if (diff <= 24 * 60 * 60 * 1000) {
    return 'Yesterday';
  } else {
    return `${dayShortNames[date.getDay()]}, ${monthNames[date.getMonth()]} ${date.getDate()}`;
  }
};
