import React from 'react';
import { Slide, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Question from './Question';
import Error from './Error';

const Page = ({ pageModel, active, lastPageAction, data, onDataChange, isLastPage, errors }) => {
  const classes = useStyles();
  const handleChange = (name, value) => {
    onDataChange({ [name]: value });
  };

  return (
    <Slide
      direction={lastPageAction === 'prev' ? (active ? 'right' : 'left') : active ? 'left' : 'right'}
      in={active}
      className={classes.page}
      timeout={lastPageAction === 'initial' ? { enter: 0 } : lastPageAction === 'complete' ? { exit: 0 } : undefined}
    >
      <Box>
        {pageModel.elements.map(element => (
          <Question
            key={element.name}
            questionModel={element}
            value={data[element.name]}
            onChange={value => handleChange(element.name, value)}
            error={errors[element.name]}
          />
        ))}
        {pageModel.elements.length === 0 ? <Error>Error: The page has no elements.</Error> : null}
      </Box>
    </Slide>
  );
};

const useStyles = makeStyles({
  page: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    overflowY: 'scroll'
  }
});

export default Page;
