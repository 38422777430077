import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import Header from './Header';
import Footer from './Footer';

import Landing from './Landing';
import Terms from './Terms';
import Privacy from './Privacy';
import ContactUs from './ContactUs';

import './web-site.css';
import Welcome from '../components/screens/Welcome';
import { isMobileBuild } from '../utils/mobile';

function MobileContactUs() {
  return <ContactUs />;
}

function MobileApp() {
  return (
    <Switch>
      <Route path="/contact-us" component={MobileContactUs} />
      <Route component={Welcome} />
    </Switch>
  );
}

function WebApp() {
  const classes = useStyles();

  return (
    <div className={classes.page}>
      <Header />
      <div className={classes.content}>
        <Switch>
          <Route path="/terms" component={Terms} />
          <Route path="/privacy" component={Privacy} />
          <Route path="/contact-us" component={ContactUs} />
          <Route component={Landing} />
        </Switch>
      </div>
      <Footer />
    </div>
  );
}

function App() {
  if (isMobileBuild()) {
    return <MobileApp />;
  }
  return <WebApp />;
}

const useStyles = makeStyles({
  page: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
    paddingTop: 64,
    boxSizing: 'border-box',
    '@media (min-width: 1000px)': {
      paddingTop: 96
    }
  },
  content: {
    flex: '1'
  }
});

export default App;
