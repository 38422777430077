import React from 'react';
import { Box, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const RadioInput = ({ questionModel, value, onChange }) => {
  const classes = useStyles();

  return (
    <Box className={classes.radioInput}>
      <RadioGroup
        aria-label={questionModel.name}
        name={questionModel.name}
        value={value === undefined ? '' : value}
        onChange={({ target: { value } }) => onChange(value)}
      >
        {questionModel.choices.map(choice => (
          <FormControlLabel
            key={choice.value}
            value={choice.value}
            control={<Radio className={classes.radio} />}
            label={choice.text}
          />
        ))}
        {questionModel.hasOther ? (
          <FormControlLabel
            value={'other'}
            control={<Radio className={classes.radio} />}
            label={questionModel.otherText}
          />
        ) : null}
        {questionModel.hasNone ? (
          <FormControlLabel
            value={'none'}
            control={<Radio className={classes.radio} />}
            label={questionModel.noneText}
          />
        ) : null}
      </RadioGroup>
    </Box>
  );
};

const useStyles = makeStyles({
  radioInput: {},
  radio: {
    '&.Mui-checked': {
      color: '#333646'
    }
  }
});

export default RadioInput;
