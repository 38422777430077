const TOKEN_KEY = 'accessToken';
const USER_ID_KEY = 'userId';

export const removeToken = () => localStorage.removeItem(TOKEN_KEY);
export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const setToken = token => localStorage.setItem(TOKEN_KEY, token);

export const removeUserId = () => localStorage.removeItem(USER_ID_KEY);
export const setUserId = userId => localStorage.setItem(USER_ID_KEY, userId);
export const getUserId = () => localStorage.getItem(USER_ID_KEY);
