import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Slider from 'react-slick';

import './slick.css';

import chevronLeft from '../media/icons/chevron-left.svg';
import chevronRight from '../media/icons/chevron-right.svg';

const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
  variableWidth: true,
  responsive: [
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        variableWidth: false
      }
    }
  ]
};

const LandingHero = () => {
  const classes = useStyles();

  return (
    <section className="landing-section">
      <div className="wrapper">
        <h2 className={classes.h2}>What Our Community is Saying: </h2>
        <Slider {...settings} className={classes.slider}>
          <div className={classes.sliderItem}>
            <p className={classes.sliderItemText}>
              "I look forward to getting the emails announcing new surveys. It usually only takes a couple minutes and
              the pay is great for what it is!"
            </p>
            <b className={classes.sliderItemAuthor}>Mario L.</b>
          </div>
          <div className={classes.sliderItem}>
            <p className={classes.sliderItemText}>
              "I have participated twice now. It helps to know I get a bonus I didn't count on to help with gas and
              such!"{' '}
            </p>
            <b className={classes.sliderItemAuthor}>Tonya A.</b>
          </div>
          <div className={classes.sliderItem}>
            <p className={classes.sliderItemText}>
              "It's been an awesome way to earn extra cash, it really adds up and getting cash payouts is very fast!"
            </p>
            <b className={classes.sliderItemAuthor}>Danielle C.</b>
          </div>
        </Slider>
      </div>
    </section>
  );
};

const useStyles = makeStyles({
  h2: {
    font: "32px/40px 'Domine', serif",
    margin: '0 0 40px',
    color: '#333646',
    '@media (min-width: 1000px)': {
      fontSize: 40,
      lineHeight: '48px'
    }
  },
  slider: {
    position: 'relative',
    paddingBottom: 72,
    '@media (min-width: 1000px)': {
      paddingBottom: 98
    },
    '& div': {
      outline: 'none'
    },
    '& .slick-list': {
      '@media (min-width: 1000px)': {
        overflow: 'visible'
      }
    },
    '& .slick-slide': {
      '@media (min-width: 1000px)': {
        width: 440,
        margin: '0 20px'
      }
    },
    '& .slick-arrow': {
      position: 'absolute',
      bottom: 0,
      width: 48,
      height: 48,
      borderRadius: 16,
      border: 'none',
      fontSize: 0,
      padding: 0,
      outline: 'none',
      backgroundColor: '#F2F2F2',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      cursor: 'pointer',
      '&.slick-disabled': {
        cursor: 'default',
        opacity: '.7'
      },
      '&.slick-next': {
        right: 0,
        backgroundImage: `url(${chevronRight})`
      },
      '&.slick-prev': {
        right: 72,
        backgroundImage: `url(${chevronLeft})`
      }
    },
    '& .slick-dots': {
      position: 'absolute',
      bottom: 22,
      left: 0,
      listStyle: 'none',
      padding: 0,
      margin: 0,
      fontSize: 0,
      lineHeight: 0,
      '& li': {
        marginRight: 16,
        display: 'inline-block',
        verticalAlign: 'top',
        '&.slick-active button': {
          width: 24,
          backgroundColor: '#14315C'
        }
      },
      '& button': {
        width: 8,
        height: 4,
        border: 'none',
        backgroundColor: '#C4CBD6',
        borderRadius: 10,
        fontSize: 0,
        transition: 'background-color .3s ease, width .3s ease',
        outline: 'none'
      }
    }
  },
  sliderItem: {
    borderRadius: 24,
    backgroundColor: '#F2F2F2',
    padding: 24,
    color: '#333646',
    boxSizing: 'border-box',
    '@media (min-width: 1000px)': {
      paddingLeft: 40,
      paddingRight: 40
    }
  },
  sliderItemText: {
    fontSize: 16,
    lineHeight: '24px',
    margin: 0
  },
  sliderItemAuthor: {
    display: 'block',
    fontSize: 24,
    lineHeight: '32px',
    fontWeight: 500,
    marginTop: 8
  }
});

export default LandingHero;
