import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Slider from 'react-slick';

import '../../web-site/slick.css';

import RegistrationHowItWorks from './RegistrationHowItWorks';

const settings = {
  dots: true,
  arrows: false,
  infinite: true,
  autoplay: true,
  autoplaySpeed: 3000,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  fade: true
};

const RegistrationSlider = () => {
  const classes = useStyles();
  return (
    <Slider {...settings} className={classes.slider}>
      <div>
        <RegistrationHowItWorks />
      </div>
      <div>
        <h2 className={classes.sliderItemTitle}>What Our Community is Saying: </h2>
        <ul className={classes.reviewsList}>
          <li className={classes.reviewsListItem}>
            <p className={classes.reviewsListItemText}>
              "I look forward to getting the emails announcing new surveys. It usually only takes a couple minutes and
              the pay is great for what it is!"
            </p>
            <b className={classes.reviewsListItemAuthor}>Mario L.</b>
          </li>
          <li className={classes.reviewsListItem}>
            <p className={classes.reviewsListItemText}>
              "I have participated twice now. It helps to know I get a bonus I didn't count on to help with gas and
              such!"
            </p>
            <b className={classes.reviewsListItemAuthor}>Tonya A.</b>
          </li>
          <li className={classes.reviewsListItem}>
            <p className={classes.reviewsListItemText}>
              "It's been an awesome way to earn extra cash, it really adds up and getting cash payouts is very fast!"
            </p>
            <b className={classes.reviewsListItemAuthor}>Danielle C.</b>
          </li>
        </ul>
      </div>
      <div>
        <ul className={classes.statisticsList}>
          <li className={classes.statisticsListItem}>
            <p className={classes.statisticsListItemLabel}>Active Panelists</p>
            <b className={classes.statisticsListItemValue}>125K+</b>
          </li>
          <li className={classes.statisticsListItem}>
            <p className={classes.statisticsListItemLabel}>Surveys Completed</p>
            <b className={classes.statisticsListItemValue}>250K+</b>
          </li>
          <li className={classes.statisticsListItem}>
            <p className={classes.statisticsListItemLabel}>Rewards Paid</p>
            <b className={classes.statisticsListItemValue}>$2M+</b>
          </li>
        </ul>
      </div>
    </Slider>
  );
};

const useStyles = makeStyles({
  slider: {
    marginTop: 80,
    color: '#fff',
    paddingBottom: 66,
    '& div': {
      outline: 'none'
    },
    '& .slick-dots': {
      position: 'absolute',
      zIndex: 5,
      bottom: 0,
      left: 0,
      listStyle: 'none',
      padding: 0,
      margin: 0,
      fontSize: 0,
      lineHeight: 0,
      '& li': {
        marginRight: 16,
        display: 'inline-block',
        verticalAlign: 'top',
        '&.slick-active button': {
          width: 24,
          backgroundColor: '#fff'
        }
      },
      '& button': {
        width: 8,
        height: 4,
        border: 'none',
        backgroundColor: 'rgba(196, 203, 214, .2)',
        borderRadius: 10,
        fontSize: 0,
        transition: 'background-color .3s ease, width .3s ease',
        outline: 'none'
      }
    },
    '& .hiw-registration': {
      background: 'none',
      padding: 0,
      marginTop: 0,
      '& li': {
        paddingLeft: 128
      }
    }
  },
  sliderItemTitle: {
    margin: 0,
    font: "40px/48px 'Domine', serif"
  },
  reviewsList: {
    listStyle: 'none',
    padding: 0,
    margin: 0
  },
  reviewsListItem: {
    marginTop: 40
  },
  reviewsListItemText: {
    fontSize: 16,
    lineHeight: '24px',
    margin: 0
  },
  reviewsListItemAuthor: {
    display: 'block',
    fontSize: 24,
    lineHeight: '32px',
    fontWeight: 500,
    marginTop: 8
  },
  statisticsList: {
    listStyle: 'none',
    padding: 0,
    margin: 0
  },
  statisticsListItem: {
    marginBottom: 64,
    display: 'flex',
    flexDirection: 'column'
  },
  statisticsListItemLabel: {
    fontSize: 24,
    lineHeight: '32px',
    fontWeight: 500,
    color: '#C4CBD6',
    margin: 0
  },
  statisticsListItemValue: {
    font: "80px/88px 'Domine', serif",
    fontWeight: 700
  }
});

export default RegistrationSlider;
