import React from 'react';
import api from '../../core/api';
import { handleError } from '../../utils/errors';
import { useHistory } from 'react-router-dom';
import Loader from '../UI/Loader';
import QuestionMultipleChoice from '../UI/QuestionMultipleChoice';
import { Alert } from '@material-ui/lab';
import PageHeader from '../UI/PageHeader';

const fetchStartingSurvey = () =>
  api.get('surveys/starting_survey/', {
    params: {
      filter_by_time: false,
      filter_by_caps: false
    }
  });

function ProfileSurveyContent() {
  const [loading, setLoading] = React.useState(false);
  const [survey, setSurvey] = React.useState(null);
  const [submitting, setSubmitting] = React.useState(false);
  const [saved, setSaved] = React.useState(false);
  const history = useHistory();

  React.useEffect(() => {
    async function getCurrentItem() {
      setLoading(true);
      try {
        const { data } = await fetchStartingSurvey();
        setSurvey(data);
      } catch (e) {
        handleError(e);
      } finally {
        setLoading(false);
      }
    }

    getCurrentItem().then();
  }, []);

  const submitAnswer = async (url, payload, config) => {
    setSubmitting(true);
    setSaved(false);
    try {
      await api.post(url, payload, config);
      setSaved(true);
    } catch (e) {
      handleError(e);
    } finally {
      setSubmitting(false);
    }
    history.push('/app');
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      {survey?.questions?.map(question => (
        <QuestionMultipleChoice
          key={question.id}
          question={question}
          onSubmit={submitAnswer}
          isLoadingButton={submitting}
          options={question.answer_options}
        />
      ))}
      {saved && <Alert severity="success">Your preferences saved!</Alert>}
    </>
  );
}

export default function ProfileSurvey() {
  return (
    <>
      <PageHeader>Profile Survey</PageHeader>
      <ProfileSurveyContent />
    </>
  );
}
