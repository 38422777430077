import { combineReducers } from 'redux';
import AuthReducer from './Auth';
import FormReducer from './Form';
import SummaryReducer from './Summary';
import RegisterReducer from './Register';

export default combineReducers({
  auth: AuthReducer,
  form: FormReducer,
  summary: SummaryReducer,
  register: RegisterReducer
});
