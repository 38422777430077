import React from 'react';
import { Redirect } from 'react-router-dom';

import LandingHero from './LandingHero';
import LandingHowItWorks from './LandingHowItWorks';
import LandingStatistics from './LandingStatistics';
import LandingReviews from './LandingReviews';
import LandingGetStarted from './LandingGetStarted';

import { getToken } from '../core/auth';
import { useTitleWithEvent } from '../hooks';

const Landing = ({ history }) => {
  useTitleWithEvent('Landing', history);

  const token = getToken();
  if (token) {
    return <Redirect to="/app" />;
  }
  return (
    <React.Fragment>
      <LandingHero />
      <LandingHowItWorks />
      <LandingStatistics />
      <LandingReviews />
      <LandingGetStarted />
    </React.Fragment>
  );
};

export default Landing;
