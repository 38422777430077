import axios from 'axios';
import { toast } from 'react-toastify';
import { Capacitor } from '@capacitor/core';

import history from './history';

import { getToken, removeToken, removeUserId } from './auth';

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL
});

api.interceptors.request.use(config => {
  const token = getToken();
  if (token != null) {
    config.headers.Authorization = 'Token ' + token;
    config.headers['X-Device-Platform'] = `${Capacitor.getPlatform()}`;
  }
  return config;
});

api.interceptors.response.use(
  response => response,
  err => {
    let error;
    try {
      error = err.toJSON();
    } catch (e) {
      error = err;
    }

    const status = err.response?.status;

    if (status === 401) {
      removeToken();
      removeUserId();
      history.push('/login');
    } else if (status >= 500) {
      toast.error(error.message);
    }
    if (err && err.response) {
      error.response = err.response.data;
    }
    return Promise.reject(error);
  }
);

export default api;
