import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

import { ReactComponent as LogoIcon } from '../media/icons/logo.svg';
import menuBg from '../media/mobile-menu-bg.svg';

const Header = () => {
  const classes = useStyles();

  const [menuOpened, setMenuOpened] = React.useState(false);

  const toggleMenu = React.useCallback(() => {
    setMenuOpened(!menuOpened);
  }, [menuOpened]);

  return (
    <header className={classes.header}>
      <div className="wrapper">
        <div className={classes.headerWrapper}>
          <Link to="/" className={classes.headerLogo}>
            <LogoIcon />
          </Link>
          <div onClick={toggleMenu} className={`${classes.toggleMenu} ${menuOpened ? classes.toggleMenuOpened : ''}`}>
            <span className="top"></span>
            <span className="middle"></span>
            <span className="btm"></span>
          </div>
          <div className={`${classes.navWrap} ${menuOpened ? classes.navWrapOpened : ''}`}>
            <ul className={classes.nav}>
              <li>
                <a href="https://prodlab.app.link/app" className={classes.navLinkSignup}>
                  Sign Up
                </a>
              </li>
              <li>
                <Link to="/login" className={classes.navLinkLogin}>
                  Log in
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </header>
  );
};

const useStyles = makeStyles({
  header: {
    position: 'fixed',
    left: 0,
    top: 0,
    right: 0,
    zIndex: 100,
    paddingTop: 'calc(env(safe-area-inset-top) + 12px)',
    paddingBottom: 12,
    backgroundColor: '#fff',
    borderBottom: '1px solid rgba(196, 203, 214, .2)',
    '@media (min-width: 1000px)': {
      paddingTop: 24,
      paddingBottom: 23
    }
  },
  headerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft: -8,
    '@media (min-width: 1000px)': {
      marginLeft: 0
    }
  },
  toggleMenu: {
    width: 16,
    height: 16,
    border: '4px solid transparent',
    position: 'relative',
    '& span': {
      display: 'block',
      height: 2,
      width: 16,
      backgroundColor: '#000',
      borderRadius: 2,
      position: 'absolute',
      left: 0,
      transitionProperty: 'transform',
      '&.top': {
        top: 2,
        transitionDuration: '.3s',
        transitionTimingFunction: 'cubic-bezier(.68,-.55,.265,1.55)'
      },
      '&.middle': {
        top: 7,
        transition: 'opacity .15s ease .3s'
      },
      '&.btm': {
        top: 12,
        transition: 'transform .3s cubic-bezier(.68,-.55,.265,1.55)'
      }
    },
    '@media (min-width: 1000px)': {
      display: 'none'
    }
  },
  toggleMenuOpened: {
    '& span': {
      '&.top': {
        transform: 'translate3d(0,5px,0) rotate(45deg)',
        transitionDelay: '.1s'
      },
      '&.middle': {
        transitionDelay: '0s',
        opacity: 0
      },
      '&.btm': {
        transform: 'translate3d(0,-5px,0) rotate(-45deg)',
        transitionDelay: '.1s'
      }
    }
  },
  navWrap: {
    position: 'fixed',
    left: 0,
    top: 63,
    right: 0,
    height: 0,
    overflow: 'hidden',
    zIndex: 101,
    background: `url(${menuBg}) no-repeat #fff right bottom`,
    transition: 'bottom .3s ease, height .3s ease',
    '@media (min-width: 1000px)': {
      position: 'static',
      height: 'auto',
      backgroundImage: 'none'
    }
  },
  navWrapOpened: {
    bottom: 0,
    height: 'calc(100% - 63px)',
    '& a': {
      opacity: 1
    }
  },
  nav: {
    listStyle: 'none',
    padding: '24px',
    margin: '0',
    height: '100%',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '@media (min-width: 1000px)': {
      flexDirection: 'row-reverse',
      padding: 0
    }
  },
  navLinkSignup: {
    display: 'block',
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 500,
    color: '#fff',
    textDecoration: 'none',
    textAlign: 'center',
    padding: '12px',
    borderRadius: 4,
    transition: 'opacity .3s ease',
    opacity: 0,
    boxSizing: 'border-box',
    backgroundColor: '#579862',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#4E8958'
    },
    '&:active': {
      color: '#fff',
      backgroundColor: '#467B4F'
    },
    '@media (min-width: 1000px)': {
      opacity: 1,
      width: 120
    }
  },
  navLinkLogin: {
    display: 'block',
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 500,
    color: '#579862',
    textDecoration: 'none',
    textAlign: 'center',
    padding: '12px',
    marginTop: 24,
    transition: 'opacity .3s ease',
    opacity: 0,
    boxSizing: 'border-box',
    '&:hover': {
      color: '#4E8958'
    },
    '&:active': {
      color: '#467B4F'
    },
    '@media (min-width: 1000px)': {
      opacity: 1,
      marginTop: 0,
      marginLeft: 8,
      width: 120
    }
  }
});

export default Header;
