import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MaterialUiTextField from '@material-ui/core/TextField';

const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
const chromeAutocompleteIds = new Set(['address']);

const StyledTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      borderRadius: 6,
      fontSize: 16,
      lineHeight: '24px',
      backgroundColor: '#fff',
      padding: 0,

      '& fieldset': {
        borderColor: '#F2F2F2',
        borderWidth: 1
      },
      '&:hover fieldset': {
        borderColor: '#C4CBD6',
        borderWidth: 1
      },
      '&.Mui-focused fieldset': {
        borderColor: '#2B508D',
        borderWidth: 1
      },
      '&.Mui-disabled': {
        color: 'rgba(0, 0, 0, 0.3)'
      },
      '&.Mui-disabled fieldset': {
        backgroundColor: 'rgba(242, 242, 242, 0.5)',
        borderColor: '#F2F2F2',
        borderWidth: 1
      },
      '&.Mui-error fieldset': {
        borderColor: '#D62121',
        borderWidth: 1
      },
      '& ~ .MuiFormHelperText-contained': {
        marginLeft: 0,
        marginRight: 0,
        '&.Mui-error ': {
          color: '#D62121'
        }
      },
      '& .MuiSelect-iconOutlined': {
        right: 16
      },
      '& .MuiSelect-outlined.MuiSelect-outlined': {
        paddingRight: 56
      }
    },
    '& input, & textarea, & select': {
      height: 48,
      padding: '11px 15px',
      boxSizing: 'border-box',
      borderRadius: 6,
      '&::-webkit-input-placeholder': {
        color: 'rgba(0,0,0, .5)',
        opacity: 1
      },
      '&::-moz-placeholder': {
        color: 'rgba(0,0,0, .5)',
        opacity: 1
      },
      '&:-ms-input-placeholder': {
        color: 'rgba(0,0,0, .5)',
        opacity: 1
      },
      '&:-moz-placeholder': {
        color: 'rgba(0,0,0, .5)',
        opacity: 1
      },
      '&:focus': {
        backgroundColor: 'transparent'
      }
    },
    '& textarea': {
      height: 144
    }
  }
})(MaterialUiTextField);

const TextField = props => {
  React.useEffect(() => {
    if (isChrome) {
      [...document.getElementsByTagName('input')]
        .filter(element => {
          return element.id && chromeAutocompleteIds.has(element.id);
        })
        .forEach(element => element.setAttribute('autocomplete', 'nope'));
    }
  }, []);

  return <StyledTextField variant="outlined" {...props} />;
};

export default TextField;
