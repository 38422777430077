import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import rightColBg from '../../media/register-bg.svg';
import pageTopIllustrationMobile from '../../media/login-mobile-bg.svg';

import RegistrationSlider from './RegistrationSlider';
import Responsive from './Responsive';
import RegistrationHowItWorks from './RegistrationHowItWorks';
import { Button } from '../UI/Button';

const RegistrationWrap = ({ children }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Responsive.Mobile>
        <div className={classes.mobileIllustration}>
          <img src={pageTopIllustrationMobile} width="375" height="181" alt="illustration" />
        </div>
      </Responsive.Mobile>
      <div className={classes.left}>
        <div className={classes.formWrap}>
          <div className={classes.form}>{children}</div>
        </div>
      </div>
      <div className={classes.right}>
        <Responsive.Mobile>
          <RegistrationHowItWorks />
          <div className={classes.submitContainer}>
            <Button type="submit" className={classes.submitButton} onClick={() => window.scrollTo(0, 0)}>
              GET STARTED
            </Button>
          </div>
        </Responsive.Mobile>
        <Responsive.Desktop>
          <div className={classes.rightContent}>
            <div>
              <h1 className={classes.rightContentHeader}>Share Insights, Get Paid!</h1>
              <p className={classes.rightContentText}>
                Join the fastest growing community of paid market research panelists!
              </p>
            </div>
            <RegistrationSlider />
          </div>
        </Responsive.Desktop>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    '@media (min-width: 1000px)': {
      flexDirection: 'row',
      padding: '40px 40px 40px 0',
      minHeight: '100vh'
    }
  },
  left: {
    display: 'flex',
    boxSizing: 'border-box',
    paddingLeft: 24,
    paddingRight: 24,
    '@media (min-width: 1000px)': {
      minWidth: 400,
      paddingTop: 24,
      alignItems: 'center',
      flexGrow: 1
    }
  },
  mobileIllustration: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  formWrap: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    '@media (min-width: 1000px)': {
      maxWidth: 320,
      margin: '0 auto'
    }
  },
  form: {
    '& > h2': {
      margin: 0,
      font: "40px/48px 'Domine', serif",
      color: '#333646'
    },
    '& > p': {
      margin: '8px 0 0 0',
      fontSize: '16px',
      lineHeight: '24px',
      '@media (min-width: 1000px)': {
        marginTop: 16
      },
      '& a': {
        textDecoration: 'none',
        color: '#579862',
        fontWeight: 'bold'
      },
      '&._mobile-visible': {
        marginTop: 8,
        marginBottom: 40
      }
    }
  },
  right: {
    position: 'relative',
    minWidth: 0,
    '@media (min-width: 1000px)': {
      flexBasis: '800px'
    }
  },
  rightContent: {
    background: `url(${rightColBg}) no-repeat #14315C right bottom`,
    borderRadius: 4,
    minHeight: 880,
    height: '100%',
    boxSizing: 'border-box',
    '@media (min-width: 1000px)': {
      padding: '40px 40px 62px'
    },
    '@media (min-width: 1200px)': {
      padding: '80px 80px 62px'
    }
  },
  rightContentHeader: {
    margin: 0,
    font: "40px/48px 'Domine', serif",
    fontWeight: 700,
    color: '#fff'
  },
  rightContentText: {
    margin: '8px 0 0',
    fontSize: 16,
    lineHeight: '24px',
    color: '#fff'
  },
  submitContainer: {
    backgroundColor: '#14315C',
    padding: '0 24px 40px 24px'
  },
  submitButton: {
    width: '100%'
  }
});

export default RegistrationWrap;
