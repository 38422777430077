import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import HistoryLink from './HistoryLink';

const HistoryNavList = ({ data }) => {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.listItem}>
        <HistoryLink title="Payout history" navTo="/app/payment/history/payout" item={data[0]} />
      </div>
    </div>
  );
};

HistoryNavList.propTypes = {
  data: PropTypes.array
};

const useStyles = makeStyles({
  listItem: {
    marginTop: 16
  }
});

export default HistoryNavList;
