import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import RadioInput from './RadioInput';
import NumberInput from './NumberInput';
import CheckboxInput from './CheckboxInput';
import TextInput from './TextInput';
import ImageInput from './ImageInput';
import QuestionImage from './QuestionImage';
import Error from './Error';

const inputs = {
  number: NumberInput,
  radiogroup: RadioInput,
  checkbox: CheckboxInput,
  text: TextInput,
  image: ImageInput
};

const Question = ({ questionModel, value, onChange, error }) => {
  const classes = useStyles();
  const hasInput = questionModel.type in inputs;
  const Input = hasInput && inputs[questionModel.type];

  return (
    <Box className={classes.question}>
      {questionModel.image ? <QuestionImage id={questionModel.image} /> : null}
      <Typography className={classes.title}>{questionModel.title}</Typography>
      {error ? <Error>{error}</Error> : null}
      {hasInput ? (
        <Input questionModel={questionModel} value={value} onChange={onChange} />
      ) : (
        <Error>Error: {JSON.stringify(questionModel.type)} is not a valid element type</Error>
      )}
    </Box>
  );
};

const useStyles = makeStyles({
  question: {
    paddingLeft: 24,
    paddingRight: 24
  },
  title: {
    paddingBottom: 15,
    fontSize: '20px'
  }
});

export default Question;
