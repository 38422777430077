import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import HistoryContainer from '../UI/HistoryContainer';

import { formatCurrency } from '../../utils';
import { useTitleWithEvent } from '../../hooks';
import upperFirst from 'lodash/upperFirst';

function prettifyTransactionAction(action) {
  if (action === 'reverse') {
    return (
      <span>
        Reverse <small>(survey rejected)</small>
      </span>
    );
  }
  return upperFirst(action);
}

function TransactionAmount({ data }) {
  const { action, amount, ending_balance } = data;
  const classes = useStyles();
  const className =
    {
      reverse: classes.itemAmountReverse
    }[action] || '';
  const sign =
    {
      survey: '💵 ',
      withdrawal: '💰 -',
      reverse: '⚠️ -'
    }[action] || '';
  return (
    <div className={`${classes.itemAmount} ${className}`}>
      <div>
        {sign}
        {formatCurrency(amount)}
      </div>
      <small>(balance {formatCurrency(ending_balance)})</small>
    </div>
  );
}

function Transaction({ data }) {
  const classes = useStyles();
  const { action, paypal_email } = data;
  return (
    <div className={classes.item}>
      <div className={classes.itemAction}>{prettifyTransactionAction(action)}</div>
      {Boolean(paypal_email) ? <div className={classes.itemEmail}>{paypal_email}</div> : null}
      <TransactionAmount data={data} />
    </div>
  );
}

const PayoutHistory = ({ history }) => {
  useTitleWithEvent('Payout History', history);

  return <HistoryContainer pageTitle="Payout History" renderItem={item => <Transaction key={item.id} data={item} />} />;
};

const useStyles = makeStyles({
  item: {
    padding: '12px 24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  itemAction: {
    fontSize: 16,
    lineHeight: '24px',
    color: '#333646'
  },
  itemEmail: {
    fontSize: 14,
    lineHeight: '24px',
    color: '#8A8B94',
    paddingLeft: 8,
    marginRight: 'auto',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  itemAmount: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    font: "16px/24px 'Roboto', serif",
    color: '#579862',
    fontWeight: 500,
    whiteSpace: 'nowrap',
    paddingLeft: 8
  },
  itemAmountReverse: {
    color: '#D62121'
  }
});

export default PayoutHistory;
