import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

import hero from '../media/landing-hero.svg';

const LandingHero = () => {
  const classes = useStyles();

  return (
    <section className={classes.section}>
      <div className="wrapper">
        <h1 className={classes.h1}>Share Insights, Get Paid!</h1>
        <p className={classes.p}>Join the fastest growing community of paid market research panelists!</p>
        <a href="https://prodlab.app.link/app" className={classes.link}>
          Get Started
        </a>
      </div>
      <img className={classes.illustration} src={hero} width="721" height="640" alt="illustration" />
    </section>
  );
};

const useStyles = makeStyles({
  section: {
    position: 'relative',
    paddingTop: 80,
    color: '#333646',
    '@media (min-width: 1000px)': {
      paddingBottom: 80
    }
  },
  h1: {
    font: "32px/40px 'Domine', serif",
    fontWeight: 700,
    margin: 0,
    paddingRight: 32,
    '@media (min-width: 1000px)': {
      fontSize: 64,
      lineHeight: '96px',
      paddingRight: 0
    }
  },
  p: {
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 500,
    margin: '16px 0 0'
  },
  link: {
    display: 'block',
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 500,
    color: '#fff',
    textDecoration: 'none',
    textAlign: 'center',
    padding: '12px',
    borderRadius: 4,
    marginTop: 40,
    boxSizing: 'border-box',
    backgroundColor: '#579862',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#4E8958'
    },
    '&:active': {
      color: '#fff',
      backgroundColor: '#467B4F'
    },
    '@media (min-width: 1000px)': {
      width: 200
    }
  },
  illustration: {
    position: 'absolute',
    right: 0,
    top: 0,
    height: 160,
    width: 'auto',
    '@media (min-width: 1000px)': {
      height: 500
    },
    '@media (min-width: 1200px)': {
      width: 721,
      height: 640
    }
  }
});

export default LandingHero;
