import React, { useState, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { IconButton } from '@material-ui/core';
import { FileCopy } from '@material-ui/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { TextField } from '../UI/TextField';
import { getFullSiteUrl } from '../../utils/urls';

import LocationInputField from '../UI/LocationInputField';
import { Button } from '../UI/Button';
import Privacy from '../UI/Privacy';

import { ReactComponent as LogoIcon } from '../../media/icons/logo-white.svg';
import { ReactComponent as ChevronRightIcon } from '../../media/icons/chevron-right.svg';
import bgDesktop from '../../media/surveys-bg-desktop.jpg';
import bgMobile from '../../media/surveys-bg-mobile.jpg';

import api from '../../core/api';
import { getUserId } from '../../core/auth';
import { formatCurrency } from '../../utils';
import { handleError } from '../../utils/errors';

import { Capacitor } from '@capacitor/core';
import { StatusBar, Style } from '@capacitor/status-bar';
import { Share } from '@capacitor/share';

const isStatusBarAvailable = Capacitor.isPluginAvailable('StatusBar');

const PageType = {
  Surveys: 'Surveys',
  Location: 'Location'
};

const Surveys = ({ data, inbrainData, goToDetails, goToInBrainDetails }) => {
  const classes = useStyles();
  const hasSurveys = data.length > 0;

  const [balance, setBalance] = useState(null);
  const [pageType, setPageType] = useState(PageType.Surveys);
  const [user, setUser] = useState(null);
  const [location, setLocation] = useState(null);
  const [canShare, setCanShare] = useState(false);
  const [showCopied, setShowCopied] = useState(false);

  const mountedRef = useRef(false);

  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const fetchUser = useCallback(() => {
    api
      .get(`users/${getUserId()}/`)
      .then(res => {
        if (mountedRef.current) {
          const profile = res.data.profile;
          setBalance(+profile.balance);
          setUser(res.data);
          if (!profile.latitude || !profile.longitude) setPageType(PageType.Location);
        }
      })
      .catch(err => {
        if (mountedRef.current) {
          handleError(err);
        }
      });
  }, []);

  useEffect(() => {
    let interval = setInterval(() => {
      fetchUser();
    }, 1000 * 60 * 60);
    return () => clearInterval(interval);
  }, [fetchUser]);

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  useEffect(() => {
    checkIfNativeShareIsSupported();
  }, []);

  const contentRef = useRef(null);

  useEffect(() => {
    const onScrollListener = () => {
      if (window.pageYOffset <= 10) {
        document.body.classList.remove('hidden-nav');
      } else if (window.pageYOffset > 10) {
        if (contentRef?.current?.clientHeight + 232 + 88 - window.innerHeight > 40) {
          document.body.classList.add('hidden-nav');
        }
      }
    };

    document.body.classList.add('surveys-page');
    window.addEventListener('scroll', onScrollListener);

    return () => {
      document.body.classList.remove('surveys-page', 'hidden-nav');
      window.removeEventListener('scroll', onScrollListener);
    };
  }, [hasSurveys]);

  useEffect(() => {
    if (isStatusBarAvailable) {
      StatusBar.setStyle({ style: Style.Dark }).then();
      return () => {
        StatusBar.setStyle({ style: Style.Light }).then();
      };
    }
  }, []);

  const checkIfNativeShareIsSupported = async () => {
    let isSupported = await Share.canShare();
    if (isSupported.value) {
      setCanShare(true);
    }
  };

  const handleSubmit = (values, actions) => {
    api
      .put(`profile_update/${user.profile.id}/`, {
        latitude: location.latitude,
        longitude: location.longitude
      })
      .then(res => {
        setUser({
          ...user,
          profile: res.data
        });
        if (res.data.latitude && res.data.longitude) setPageType(PageType.Surveys);
        actions.setSubmitting(false);
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  const onShareClick = async url => {
    let canShare = await Share.canShare();
    if (canShare.value) {
      await Share.share({
        title: 'Invite Friends',
        text:
          '☀️ I think you’ll like the Productlab app, earn cash rewards for taking surveys. Here is my link to learn more -',
        url: url,
        dialogTitle: 'Invite Friends'
      });
    }
  };

  function LocationForm(props) {
    return (
      <Formik initialValues={user.profile} onSubmit={handleSubmit}>
        {props => (
          <Form>
            <div className={classes.row}>
              <div className={classes.rowLabel}>
                Enter the nearest major city to see surveys available in your area!
              </div>
              <LocationInputField
                inputStyle={classes.input}
                address={location?.address ?? ''}
                onSelect={location => {
                  props.setFieldValue('latitude', location.latitude);
                  props.setFieldValue('longitude', location.longitude);
                  setLocation(location);
                }}
              />
            </div>
            <div className={classes.buttonsWrap}>
              <div>
                <Button type="submit" className={classes.button}>
                  Apply
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    );
  }

  function SurveysList(props) {
    return (
      <div>
        <div className={classes.surveyList}>
          {data.map(item =>
            item.display_name !== 'InBrain' ? (
              <div key={item.id} className={classes.surveyItem} onClick={() => goToDetails(item.id)}>
                <div className={classes.surveyItemLeft}>
                  <div className={classes.surveyItemLeftTop}>
                    <div className={classes.surveyItemDays}>{item.days_left}</div>
                    <div className={classes.surveyItemTime}>{item.duration}</div>
                  </div>
                  <div className={classes.surveyItemLeftBottom}>
                    <div className={classes.surveyItemTitle}>{item.display_name || item.name || item.survey}</div>
                    <div>
                      <span className={classes.surveyItemCost}>{item.reward_text}</span>
                    </div>
                  </div>
                </div>
                <div className={classes.surveyItemRight}>
                  <ChevronRightIcon />
                </div>
              </div>
            ) : (
              inbrainData.map(item => (
                <div key={item.id} className={classes.surveyItem} onClick={() => goToInBrainDetails(item)}>
                  <div className={classes.surveyItemLeft}>
                    <div className={classes.surveyItemLeftTop}>
                      <div className={classes.surveyItemDays}></div>
                      <div className={classes.surveyItemTime}>{item.lengthOfInterview_display}</div>
                    </div>
                    <div className={classes.surveyItemLeftBottom}>
                      <div className={classes.surveyItemTitleInBrains}>Partner Survey</div>
                      <div>
                        <span className={classes.surveyItemCost}>{item.cpi_display}</span>
                      </div>
                    </div>
                  </div>
                  <div className={classes.surveyItemRight}>
                    <ChevronRightIcon />
                  </div>
                </div>
              ))
            )
          )}
        </div>
        <div className={classes.surveyButtonWrap}>
          {canShare ? (
            <Button
              onClick={() => onShareClick(getFullSiteUrl('/register?utm_referral=' + user.profile.id))}
              className={classes.surveyButton}
            >
              Invite Friends
            </Button>
          ) : (
            <div className={classes.surveyCopyToClipboardFallbackWrapper}>
              <CopyToClipboard text={getFullSiteUrl('/register?utm_referral=' + user.profile.id)}>
                <Button onClick={() => setShowCopied(true)} className={classes.surveyButton}>
                  Invite Friends
                </Button>
              </CopyToClipboard>
              {showCopied ? <div className={classes.copied}>Copied link to clipboard!</div> : null}
            </div>
          )}
        </div>
        {/* <div className={classes.surveysNote}>Click to copy your referral link:</div>
        <CopyToClipboard text={getFullSiteUrl('/register?utm_referral=' + user.profile.id)}>
          <div className={classes.linkRow} onClick={() => setShowCopied(true)}>
            <TextField
              className={classes.referralInput}
              value={getFullSiteUrl('/register?utm_referral=' + user.profile.id)}
              disabled
            />
            <IconButton>
              <FileCopy />
            </IconButton>
          </div>
        </CopyToClipboard> */}
      </div>
    );
  }

  return (
    <div className={classes.page}>
      <div className={classes.header}>
        <LogoIcon />
      </div>
      <div className={classes.top}>
        <div className={classes.topBalance}>
          <div className={classes.topBalanceLabel}>Rewards Balance</div>
          <div className={classes.topBalanceValue}>{balance !== null ? formatCurrency(balance) : null}</div>
        </div>
      </div>
      <div className={classes.pageContent} ref={contentRef}>
        {!hasSurveys ? (
          <div className={classes.pageContentHeader}>No more surveys right now, check back next week.</div>
        ) : null}
        {pageType === PageType.Location && user ? <LocationForm /> : null}
        {pageType === PageType.Surveys && user ? <SurveysList /> : null}
      </div>
      <Privacy />
    </div>
  );
};

Surveys.propTypes = {
  data: PropTypes.array,
  goToDetails: PropTypes.func
};

const useStyles = makeStyles({
  page: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    paddingTop: 232,
    margin: '0 -24px',
    position: 'relative',
    backgroundColor: '#fff',
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      left: 0,
      top: 0,
      right: 0,
      height: 248,
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      '@media (max-width: 999px)': {
        backgroundImage: `url(${bgMobile})`
      },
      '@media (min-width: 1000px)': {
        backgroundImage: `url(${bgDesktop})`
      }
    }
  },
  header: {
    position: 'absolute',
    left: 16,
    top: 12,
    paddingTop: 'env(safe-area-inset-top)'
  },
  top: {
    position: 'absolute',
    left: 24,
    right: 24,
    top: 144,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  topBalance: {
    color: '#fff'
  },
  topBalanceLabel: {
    fontSize: 14,
    lineHeight: '24px'
  },
  topBalanceValue: {
    font: "32px/40px 'Domine', serif",
    fontWeight: 700,
    minHeight: 40
  },
  pageContent: {
    padding: '24px 24px 0',
    borderRadius: '16px 16px 0 0',
    backgroundColor: '#fff',
    position: 'relative'
  },
  pageContentHeader: {
    fontSize: 24,
    lineHeight: '32px',
    fontWeight: 700,
    color: '#333646'
  },
  surveyList: {
    paddingTop: 8
  },
  surveyItem: {
    border: '1px solid #C4CBD6',
    boxSizing: 'border-box',
    borderRadius: 4,
    paddingLeft: '15px',
    paddingTop: '11px',
    paddingBottom: '11px',
    display: 'flex',
    alignItems: 'center',
    marginTop: 16,
    backgroundColor: '#fff',
    overflow: 'hidden',
    cursor: 'pointer',
    '&:active': {
      color: '#fff',
      backgroundColor: '#14315C',
      '& $surveyItemCost': {
        color: 'white'
      }
    }
  },
  surveyItemLeft: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1
  },
  surveyItemLeftTop: {
    display: 'flex'
  },
  surveyItemLeftBottom: {
    display: 'flex'
  },
  surveyItemRight: {
    paddingLeft: '10px',
    paddingRight: '4px'
  },
  surveyItemDays: {
    color: 'red',
    fontWeight: 500,
    fontSize: 11,
    flexGrow: 1
  },
  surveyItemTime: {
    color: 'red',
    fontWeight: 500,
    fontSize: 11
  },
  surveyItemTitle: {
    fontSize: 16,
    lineHeight: '24px',
    minWidth: 0,
    flexGrow: 1
  },
  surveyItemTitleInBrains: {
    fontSize: 16,
    lineHeight: '24px',
    minWidth: 0,
    marginTop: '-8px',
    flexGrow: 1
  },
  surveyItemCost: {
    fontSize: 16,
    lineHeight: '24px',
    color: '#579862',
    fontWeight: 500,
    paddingLeft: 16,
    whiteSpace: 'nowrap'
  },
  surveysNote: {
    fontSize: 14,
    color: '#8A8B94',
    marginTop: '12px'
  },
  link: {
    color: '#5C5E6C'
  },
  copied: {
    fontSize: 14,
    marginTop: 6,
    color: '#8A8B94'
  },
  row: {
    marginBottom: 24,
    position: 'relative'
  },
  rowLabel: {
    fontSize: 16,
    lineHeight: '24px',
    color: '#5C5E6C',
    marginBottom: 8
  },
  input: {
    width: '100%'
  },
  buttonsWrap: {
    marginTop: 'auto'
  },
  button: {
    marginTop: 16,
    width: '100%'
  },
  linkRow: {
    marginTop: '5px',
    display: 'flex'
  },
  referralInput: {
    width: '100%'
  },
  surveyButtonWrap: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginTop: 40
  },
  surveyCopyToClipboardFallbackWrapper: {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%'
  },
  surveyButton: {
    width: '50%',
    minWidth: 200
  }
});

export default Surveys;
