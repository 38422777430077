import React from 'react';

import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

import { TextField } from './TextField';
import { regionFromResult } from '../../utils/geocoder';
import { makeStyles } from '@material-ui/core/styles';

const searchOptions = {
  types: ['(cities)'],
  componentRestrictions: { country: ['us', 'ca'] }
};

export const errorsMapping = {
  ZERO_RESULTS: 'No results.',
  UNKNOWN_ERROR: 'No results.'
};

export function SuggestionsItems({ loading, error, data, getSuggestionItemProps }) {
  const plainItemStyle = {
    padding: '10px 15px',
    fontSize: 14
  };
  if (error) {
    return (
      <div style={plainItemStyle} key="_error">
        {error}
      </div>
    );
  }
  const style = {
    ...plainItemStyle,
    borderBottom: '1px solid #C4CBD6',
    cursor: 'pointer'
  };
  if (data?.length) {
    return (
      <>
        {data.map(suggestion => {
          return (
            <div
              {...getSuggestionItemProps(suggestion, {
                style
              })}
              key={suggestion.placeId}
            >
              {suggestion.description}
            </div>
          );
        })}
      </>
    );
  }
  if (loading) {
    return (
      <div style={plainItemStyle} key="_loading">
        Loading...
      </div>
    );
  }
  return null;
}

export function SuggestionsList({ visible, loading, error, data, getSuggestionItemProps }) {
  const styles = useStyles();
  if (!(visible && (loading || error || data?.length > 0))) {
    return null;
  }
  return (
    <div className={styles.placeSuggestionList}>
      <SuggestionsItems data={data} loading={loading} error={error} getSuggestionItemProps={getSuggestionItemProps} />
    </div>
  );
}

const LocationInputField = ({ address, onSelect, inputStyle }) => {
  const [isFocused, setFocused] = React.useState(false);
  const [locationInput, setLocationInput] = React.useState(address);
  const [error, setError] = React.useState(null);

  React.useEffect(() => {
    setLocationInput(address);
  }, [address]);

  const onHandleSelect = async value => {
    const results = await geocodeByAddress(value);
    const resultCoords = await getLatLng(results[0]);
    const location = {
      address: regionFromResult(results[0]),
      latitude: resultCoords.lat,
      longitude: resultCoords.lng
    };
    onSelect(location);
    setLocationInput(location.address);
  };

  const handleError = e => {
    if (errorsMapping[e]) {
      setError(errorsMapping[e]);
    } else {
      console.error('[react-places-autocomplete]:', e);
    }
  };

  return (
    <PlacesAutocomplete
      value={locationInput}
      onChange={value => {
        setLocationInput(value);
        setError(null);
      }}
      onSelect={onHandleSelect}
      onError={(e, clearSuggestions) => {
        handleError(e);
        clearSuggestions();
      }}
      searchOptions={searchOptions}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
        const inputProps = getInputProps({
          placeholder: 'Enter city'
        });
        return (
          <div>
            <TextField
              id="address"
              required
              className={inputStyle}
              {...inputProps}
              onFocus={() => {
                setFocused(true);
                setError(null);
              }}
              onBlur={e => {
                setFocused(false);
                setError(null);
                const name = address ?? '';
                if (e.target.value !== name) {
                  inputProps.onChange({ target: { value: name } });
                }
              }}
            />
            <SuggestionsList
              visible={isFocused}
              loading={loading}
              error={error}
              data={suggestions}
              getSuggestionItemProps={getSuggestionItemProps}
            />
          </div>
        );
      }}
    </PlacesAutocomplete>
  );
};

const useStyles = makeStyles({
  placeSuggestionList: {
    position: 'absolute',
    zIndex: 1000,
    backgroundColor: 'white',
    width: '100%',
    left: 0,
    right: 0,
    top: '100%',
    color: 'black',
    border: '1px solid #C4CBD6',
    borderRadius: 6
  }
});

export default LocationInputField;
