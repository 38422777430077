import {
  PHONE_CHANGED,
  REGISTER_ERROR_CHANGED,
  FIRST_NAME_CHANGED,
  LAST_NAME_CHANGED,
  PAYPAL_EMAIL_CHANGED
} from './types';

export const registerErrorChanged = error => {
  return {
    type: REGISTER_ERROR_CHANGED,
    payload: error
  };
};

export const phoneChanged = phone => {
  return {
    type: PHONE_CHANGED,
    payload: phone
  };
};

export const firstNameChanged = name => {
  return {
    type: FIRST_NAME_CHANGED,
    payload: name
  };
};

export const lastNameChanged = lastname => {
  return {
    type: LAST_NAME_CHANGED,
    payload: lastname
  };
};

export const paypalEmailChanged = paypalEmail => {
  return {
    type: PAYPAL_EMAIL_CHANGED,
    payload: paypalEmail
  };
};
