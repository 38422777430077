import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

export default function FormError({ children }) {
  const classes = useStyles();

  return <div className={classes.error}>{children}</div>;
}

const useStyles = makeStyles({
  error: {
    color: '#D62121',
    fontSize: 16,
    marginTop: 8
  }
});
