import React from 'react';
import { Box, FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const CheckboxInput = ({ questionModel, value, onChange }) => {
  const classes = useStyles();

  const checkedValues = (value || '').split(';').filter(v => v);
  const updateValue = (checkValue, checked) => {
    if (checked && !checkedValues.includes(checkValue)) {
      const newCheckedValues = [...checkedValues, checkValue];
      const newValue = newCheckedValues.join(';');
      onChange(newValue);
    } else if (!checked && checkedValues.includes(checkValue)) {
      onChange(checkedValues.filter(v => v !== checkValue).join(';'));
    }
  };

  return (
    <Box className={classes.radioInput}>
      <FormGroup component="fieldset" aria-label={questionModel.name} name={questionModel.name}>
        {questionModel.choices.map(choice => (
          <FormControlLabel
            key={choice.value}
            control={
              <Checkbox
                className={classes.check}
                checked={checkedValues.includes(choice.value)}
                onChange={({ target: { checked } }) => updateValue(choice.value, checked)}
              />
            }
            label={choice.text}
          />
        ))}
      </FormGroup>
    </Box>
  );
};

const useStyles = makeStyles({
  radioInput: {},
  check: {
    '&.Mui-checked': {
      color: '#333646'
    }
  }
});

export default CheckboxInput;
