import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import validator from 'validator';

import { useSelector, useDispatch } from 'react-redux';
import { profileChanged } from '../../actions';

import { ReactComponent as PayPalIcon } from '../../media/icons/paypal.svg';

import { TextField } from './TextField';
import { Button } from './Button';

import api from '../../core/api';

import { formatCurrency } from '../../utils';
import PhoneConfirmationForm from './PhoneConfirmationForm';
import VerifyIdentityButton from './VerifyIdentityButton';

const CashoutForm = ({ onSuccess, paypalEmail, balance, minWithdraw, profile, setProfile, fetchProfile }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);

  const [email, setEmail] = React.useState(paypalEmail);
  const [error, setError] = React.useState(null);
  const [isLoadingButton, setLoadingButton] = React.useState(false);

  const onEmailChange = React.useCallback(
    e => {
      const { value } = e.target;
      setEmail(value);
      if (value && value.trim() !== '' && error) {
        setError(null);
      }
    },
    [error]
  );

  const onSubmit = React.useCallback(
    e => {
      e.preventDefault();
      if (email?.trim() === '') {
        setError('Required field');
        return;
      }
      if (!validator.isEmail(email)) {
        setError('Invalid email');
        return;
      }

      const payload = {
        paypal_email: email
      };

      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      };

      setLoadingButton(true);

      api
        .post('/payment/cashout/', payload, config)
        .then(() => {
          setEmail('');
          onSuccess();
        })
        .catch(err => {
          if (err.response?.error?.message) {
            setError(err.response?.error?.message);
          } else {
            setError(err.response);
          }
        })
        .finally(() => setLoadingButton(false));
    },
    [email, onSuccess]
  );

  // manually update redux store for now
  React.useEffect(() => {
    dispatch(profileChanged(profile));
  }, [profile]);

  if ((!auth.profile?.is_phone_number_verified || !auth.profile?.is_identity_verified) && balance >= minWithdraw) {
    return (
      <>
        <div className={classes.paypalFormLabel}>
          Please confirm your phone number and your identity to cash out your earnings:
        </div>
        <PhoneConfirmationForm
          isVerified={auth.profile?.is_phone_number_verified}
          onCompleted={() => {
            setProfile({
              ...auth.profile,
              is_phone_number_verified: true
            });
            fetchProfile();
          }}
        />
        <div className={classes.identityButtonWrapper}>
          <VerifyIdentityButton
            isVerified={auth.profile?.is_identity_verified}
            verificationStatus={auth.profile?.identity_verification_status}
            identityLastErrorReason={auth.profile?.identity_last_error_reason}
            identityLastErrorCode={auth.profile?.identity_last_error_code}
            fetchProfile={fetchProfile}
          />
        </div>
      </>
    );
  }

  if (!auth.profile?.is_phone_number_verified && parseInt(balance) === 0) {
    return null;
  }

  return (
    <React.Fragment>
      {balance >= minWithdraw ? (
        <React.Fragment>
          <div className={classes.paypalForm}>
            <div className={classes.paypalFormLabel}>Paypal account</div>
            <TextField
              className={classes.paypalFormInput}
              placeholder="Enter your paypal email address"
              onChange={onEmailChange}
              value={email}
              type="email"
              error={!!error}
              helperText={error}
            />
          </div>
          <Button
            color="secondary"
            className={classes.paypalButton}
            startIcon={<PayPalIcon />}
            type="submit"
            onClick={onSubmit}
            loading={isLoadingButton}
          >
            Cash Out With PayPal
          </Button>
          <p className={classes.paypalText}>
            Don’t have a PayPal account? <br />
            Sign up at&nbsp;
            <a href="https://www.paypal.com/" target="_blank" rel="noopener noreferrer">
              Paypal.com
            </a>
          </p>
        </React.Fragment>
      ) : (
        <div className={classes.paypalDisclaimer}>
          <p className={classes.paypalDisclaimerText}>The minimum to cash out is {formatCurrency(minWithdraw)}</p>
        </div>
      )}
    </React.Fragment>
  );
};

CashoutForm.propTypes = {
  onSuccess: PropTypes.func,
  paypalEmail: PropTypes.string,
  balance: PropTypes.number,
  minWithdraw: PropTypes.number
};

const useStyles = makeStyles({
  paypalFormLabel: {
    fontSize: 16,
    lineHeight: '24px',
    color: '#8A8B94',
    marginBottom: 8
  },
  paypalFormInput: {
    width: '100%'
  },
  paypalDisclaimer: {
    background: '#F2F2F2',
    border: '1px solid #C4CBD6',
    boxSizing: 'border-box',
    borderRadius: 4,
    padding: 15,
    marginBottom: 24
  },
  paypalDisclaimerText: {
    margin: 0,
    fontSize: 14,
    lineHeight: '24px',
    color: '#8A8B94'
  },
  paypalButton: {
    marginTop: 24,
    width: '100%'
  },
  paypalText: {
    margin: '16px 0 24px',
    fontSize: 16,
    lineHeight: '24px',
    color: '#000',
    '& a': {
      textDecoration: 'none',
      color: '#579862',
      fontWeight: 500
    }
  },
  identityButtonWrapper: {
    width: '100%',
    marginTop: 8
  }
});

export default CashoutForm;
