import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Capacitor } from '@capacitor/core';
import validator from 'validator';
import axios from 'axios';
import { Link, Redirect } from 'react-router-dom';
import { LoginForm } from '../UI/LoginForm';
import LoginWrap from '../UI/LoginWrap';
import {
  currentFormChanged,
  emailChanged,
  errorChanged,
  passwordChanged,
  phoneChanged,
  usernameChanged,
  verificationCodeChanged
} from '../../actions';
import { getToken, setToken, setUserId } from '../../core/auth';
import { useTitleWithEvent } from '../../hooks';

class Login extends Component {
  componentDidMount = () => {
    if (Capacitor.getPlatform() === 'web' && localStorage.getItem('PLTEST') !== 'true') {
      window.location.href = 'https://prodlab.app.link/app';
    }
  };

  onPhoneChange = event => {
    const val = event.target.value;
    let phone = val.replace(/\D/g, '');
    this.props.phoneChanged(phone);
  };

  onVerificationCodeChange = event => {
    const val = event.target.value;
    this.props.verificationCodeChanged(val);
  };

  onCurrentFormChange = form => {
    this.props.currentFormChanged(form);
  };

  onErrorChange = error => {
    this.props.errorChanged(error);
  };

  setCurrentFormToPhone = () => {
    this.onCurrentFormChange('phone');
  };

  setCurrentFormToVerificationCode = () => {
    this.onCurrentFormChange('verificationCode');
  };

  validatePhone = phone => validator.isMobilePhone(phone);

  savePhoneLocalStorage = phone => {
    localStorage.setItem('phone', phone);
  };

  sendVerificationCode = () => {
    const { phone } = this.props;
    const params = { phone_number: phone };
    const base_url = process.env.REACT_APP_BASE_URL;
    const url = base_url + 'send_code/';
    this.onErrorChange(null);
    var validated = this.validatePhone(phone);
    if (!validated) {
      this.onErrorChange({
        type: 'field',
        message: 'Invalid phone number'
      });
      return;
    }
    this.props.phoneChanged('');
    this.savePhoneLocalStorage(phone);
    axios
      .post(url, params)
      .then(res => {
        if (res.data.status === 'pending') {
          this.setCurrentFormToVerificationCode();
        }

        if (res.data.status === 'error') {
          var errorMessage = res.data.error_message;
          this.onErrorChange({
            type: 'backend',
            message: errorMessage
          });
        }
      })
      .catch(err => {
        var errorMessage = err.response?.data?.error_message || err.message;
        this.onErrorChange({
          type: 'catch',
          message: errorMessage
        });
      });
  };

  checkVerificationCode = () => {
    const { verificationCode } = this.props;
    const phone = localStorage.getItem('phone');
    const base_url = process.env.REACT_APP_BASE_URL;
    const url = base_url + 'check_code/';
    const params = {
      phone_number: phone,
      verification_code: verificationCode
    };
    var token;
    this.onErrorChange(null);
    this.props.verificationCodeChanged('');
    if (isNaN(verificationCode)) {
      this.onErrorChange({
        type: 'field',
        message: 'invalid code'
      });
      return;
    }

    if (verificationCode.length !== 6) {
      this.onErrorChange({
        type: 'field',
        message: 'Code must be 6 digits long'
      });
      return;
    }

    axios
      .post(url, params)
      .then(res => {
        if (res.data.status === 'success') {
          token = res.data.key;
          setToken(token);
          setUserId(res.data?.user?.id);
          this.setCurrentFormToPhone();
          this.props.history.push('/app');
        } else {
          this.onErrorChange({
            type: 'backend',
            message: res.data?.error_message ?? 'Error'
          });
        }
      })
      .catch(err => {
        var errorMessage = err.response?.data.error_message || err.message;
        this.onErrorChange({
          type: 'catch',
          message: errorMessage
        });
      });
  };

  generateLoginForm = () => {
    const { currentForm, phone, error, verificationCode } = this.props;
    switch (currentForm) {
      case 'phone':
        return (
          <LoginForm
            onInputChange={this.onPhoneChange}
            onPress={this.sendVerificationCode}
            placeholder="Enter your phone"
            buttonText="SEND CODE"
            text={phone}
            error={error}
          />
        );
      case 'verificationCode':
        return (
          <LoginForm
            onInputChange={this.onVerificationCodeChange}
            onPress={this.checkVerificationCode}
            placeholder="Enter your verification code"
            buttonText="LOG IN"
            text={verificationCode}
            reenterText="Need to re-enter phone number?<br>Click Here"
            reenterTextClick={this.setCurrentFormToPhone}
            error={error}
          />
        );
      default:
        throw new Error(`Unsupported form type: ${currentForm}`);
    }
  };

  render() {
    const token = getToken();
    if (token) {
      return <Redirect to="/app" />;
    }
    return (
      <LoginWrap>
        <h1>Log In</h1>
        <p>
          New to Product Lab? <a href="https://prodlab.app.link/app">Sign Up</a>
        </p>
        {this.generateLoginForm()}
      </LoginWrap>
    );
  }
}

const LoginPage = ({ history, ...props }) => {
  useTitleWithEvent('Login', history);
  return <Login history={history} {...props} />;
};

const mapStateToProps = ({ auth }) => {
  const { error, loading, email, password, username, currentForm, phone, verificationCode } = auth;
  return {
    error,
    loading,
    email,
    password,
    username,
    currentForm,
    phone,
    verificationCode
  };
};

export default connect(mapStateToProps, {
  emailChanged,
  passwordChanged,
  usernameChanged,
  currentFormChanged,
  phoneChanged,
  verificationCodeChanged,
  errorChanged
})(LoginPage);
