import {
  EMAIL_CHANGED,
  PASSWORD_CHANGED,
  USERNAME_CHANGED,
  REPEAT_PASSWORD_CHANGED,
  CURRENT_FORM_CHANGED,
  VERIFICATION_CODE_CHANGED,
  ERROR_CHANGED,
  PROFILE_CHANGED
} from './types';

export const emailChanged = text => {
  return {
    type: EMAIL_CHANGED,
    payload: text
  };
};

export const passwordChanged = text => {
  return {
    type: PASSWORD_CHANGED,
    payload: text
  };
};

export const repeatPasswordChanged = text => {
  return {
    type: REPEAT_PASSWORD_CHANGED,
    payload: text
  };
};

export const usernameChanged = username => {
  return {
    type: USERNAME_CHANGED,
    payload: username
  };
};

export const currentFormChanged = form => {
  return {
    type: CURRENT_FORM_CHANGED,
    payload: form
  };
};

export const verificationCodeChanged = code => {
  return {
    type: VERIFICATION_CODE_CHANGED,
    payload: code
  };
};

export const errorChanged = error => {
  return {
    type: ERROR_CHANGED,
    payload: error
  };
};

export const profileChanged = profile => {
  return {
    type: PROFILE_CHANGED,
    payload: profile
  };
};
