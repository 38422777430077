import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import login_page_img_desktop from '../../media/login-desktop-bg.svg';
import login_page_img_mobile from '../../media/login-mobile-bg.svg';

const LoginWrap = ({ className, children }) => {
  const classes = useStyles();
  return (
    <div className={`${classes.root} ${className}`}>
      <div className={`login-wrap-left ${classes.left}`}>
        <div className={classes.formWrap}>
          <div className={classes.form}>{children}</div>
        </div>
      </div>
      <div className={classes.right}>
        <picture>
          <source media="(min-width: 1000px)" srcSet={login_page_img_desktop} />
          <img src={login_page_img_mobile} alt="illustration" />
        </picture>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  root: {
    display: 'flex',
    '@media (max-width: 999px)': {
      flexDirection: 'column-reverse',
      overflowY: 'auto',
      minHeight: '100%'
    },
    '@media (min-width: 1000px)': {
      height: '100%'
    }
  },
  left: {
    flexGrow: '1',
    display: 'flex',
    justifyContent: 'center',
    boxSizing: 'border-box',
    paddingLeft: 24,
    paddingRight: 24,
    paddingBottom: 24,
    height: '100%',
    '@media (min-width: 1000px)': {
      overflowY: 'auto',
      minWidth: 400,
      paddingTop: 24,
      alignItems: 'center'
    }
  },
  right: {
    flexBasis: '800px',
    overflow: 'hidden',
    position: 'relative',
    '@media (max-width: 999px)': {
      display: 'flex',
      justifyContent: 'flex-end',
      width: '100%',
      flexBasis: '181px',
      minHeight: '181px',
      height: 'auto'
    },
    '& picture': {
      display: 'block',
      '@media (min-width: 1000px)': {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        left: '0',
        marginTop: -55
      }
    }
  },
  formWrap: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    '@media (min-width: 1000px)': {
      maxWidth: 320
    }
  },
  form: {
    '& > h1': {
      margin: 0,
      font: "40px/48px 'Domine', serif",
      color: '#333646',
      fontWeight: 700
    },
    '& > p': {
      margin: '40px 0 0 0',
      fontSize: '16px',
      lineHeight: '24px',
      '@media (min-width: 1000px)': {
        marginTop: 24
      },
      '& a': {
        textDecoration: 'none',
        color: '#579862',
        fontWeight: 'bold'
      }
    }
  }
});

export default LoginWrap;
