import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import hexagon from '../media/icons/hexagon-hiw.svg';

const LandingHowItWorks = () => {
  const classes = useStyles();

  return (
    <section className="landing-section">
      <div className="wrapper">
        <h2 className={classes.h2}>How It Works:</h2>
        <ol className={classes.list}>
          <li className={classes.listItem}>
            <strong className={classes.listItemTitle}>Sign Up</strong>
            <p className={classes.listItemText}>
              Provide some basic information so we can identify the best opportunities for you
            </p>
          </li>
          <li className={classes.listItem}>
            <strong className={classes.listItemTitle}>Share Insights</strong>
            <p className={classes.listItemText}>
              We’ll notify you with new opportunities to provide insights and opinions via the our mobile web
              application
            </p>
          </li>
          <li className={classes.listItem}>
            <strong className={classes.listItemTitle}>Get Paid</strong>
            <p className={classes.listItemText}>Accumulate earnings and cash out instantly via PayPal</p>
          </li>
        </ol>
      </div>
    </section>
  );
};

const useStyles = makeStyles({
  h2: {
    font: "32px/40px 'Domine', serif",
    margin: '0 0 40px',
    color: '#333646',
    '@media (min-width: 1000px)': {
      fontSize: 40,
      lineHeight: '48px'
    }
  },
  list: {
    counterReset: 'list',
    listStyle: 'none',
    padding: 0,
    margin: 0,
    color: '#333646',
    '@media (min-width: 1000px)': {
      display: 'flex',
      margin: '40px -24px 0'
    }
  },
  listItem: {
    position: 'relative',
    paddingLeft: 112,
    minHeight: 104,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '&:not(:last-child)': {
      marginBottom: 48,
      '@media (min-width: 1000px)': {
        marginBottom: 0
      }
    },
    '@media (min-width: 1000px)': {
      width: 'calc(33.33333% - 48px)',
      marginLeft: 24,
      marginRight: 24
    },
    '&:before': {
      counterIncrement: 'list',
      content: 'counter(list)',
      position: 'absolute',
      width: 104,
      height: 104,
      left: 0,
      top: '50%',
      marginTop: -52,
      font: "40px/104px 'Domine', serif",
      fontWeight: 700,
      color: '#2B508D',
      textAlign: 'center',
      background: `url(${hexagon}) no-repeat transparent center center`
    }
  },
  listItemTitle: {
    fontSize: 24,
    lineHeight: '32px',
    fontWeight: 500,
    display: 'block'
  },
  listItemText: {
    fontSize: 16,
    lineHeight: '24px',
    margin: '8px 0 0'
  }
});

export default LandingHowItWorks;
