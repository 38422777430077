import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

import { ReactComponent as ChevronRightIcon } from '../../media/icons/chevron-right.svg';

const HistoryLink = ({ title, navTo, item }) => {
  const classes = useStyles();
  const history = useHistory();

  const goTo = React.useCallback(() => {
    history.push(navTo);
  }, [history, navTo]);

  if (!item) {
    return null;
  }

  return (
    <div className={classes.link} onClick={goTo}>
      <div className={classes.linkTop}>
        {title}
        <ChevronRightIcon />
      </div>
    </div>
  );
};

HistoryLink.propTypes = {
  title: PropTypes.string,
  navTo: PropTypes.string,
  item: PropTypes.object
};

const useStyles = makeStyles({
  link: {
    border: '1px solid #F2F2F2',
    borderRadius: 6,
    padding: '0 16px',
    color: '#333646',
    backgroundColor: '#fff',
    fontFamily: 'Roboto, sans-serif',
    marginTop: 16
  },
  linkTop: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 500,
    paddingTop: 16,
    paddingBottom: 15
  }
});

export default HistoryLink;
