import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import mobileLeftBtmBg from '../media/icons/statistics-mobile-left-btm-bg.svg';
import mobileRightTopBg from '../media/icons/statistics-mobile-right-top-bg.svg';
import desktopLeftBtmBg from '../media/icons/statistics-desktop-left-btm-bg.svg';
import desktopRightTopBg from '../media/icons/statistics-desktop-right-top-bg.svg';

const LandingStatistics = () => {
  const classes = useStyles();

  return (
    <section className="landing-section">
      <div className="wrapper">
        <h2 className="landing-section__h2">Some Statistics:</h2>
        <div className={classes.statistics}>
          <div className={classes.statisticsItem}>
            <span className={classes.statisticsItemLabel}>Active Panelists</span>
            <b className={classes.statisticsItemValue}>125K+</b>
          </div>
          <div className={classes.statisticsItem}>
            <span className={classes.statisticsItemLabel}>Surveys Completed</span>
            <b className={classes.statisticsItemValue}>250K+</b>
          </div>
          <div className={classes.statisticsItem}>
            <span className={classes.statisticsItemLabel}>Rewards Paid</span>
            <b className={classes.statisticsItemValue}>$2M+</b>
          </div>
        </div>
      </div>
    </section>
  );
};

const useStyles = makeStyles({
  statistics: {
    borderRadius: 40,
    padding: '0 40px 40px',
    background: `url(${mobileLeftBtmBg}) no-repeat left bottom, url(${mobileRightTopBg}) no-repeat top right`,
    backgroundColor: '#14315C',
    '@media (min-width: 1000px)': {
      display: 'flex',
      padding: '80px 0',
      background: `url(${desktopLeftBtmBg}) no-repeat left bottom, url(${desktopRightTopBg}) no-repeat top right`,
      backgroundColor: '#14315C'
    }
  },
  statisticsItem: {
    paddingTop: 40,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '@media (min-width: 1000px)': {
      flex: '1 0 33.33333%',
      paddingTop: 0
    }
  },
  statisticsItemLabel: {
    fontSize: 24,
    lineHeight: '32px',
    fontWeight: 500,
    color: '#C4CBD6'
  },
  statisticsItemValue: {
    font: "48px/64px 'Domine', serif",
    fontWeight: 700,
    color: '#fff',
    '@media (min-width: 1000px)': {
      fontSize: 80,
      lineHeight: '88px'
    }
  }
});

export default LandingStatistics;
