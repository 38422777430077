import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

import PageHeader from '../UI/PageHeader';
import Privacy from '../UI/Privacy';
import { Button } from '../UI/Button';
import Loader from '../UI/Loader';
import { Switch } from '../UI/Switch';

import api from '../../core/api';
import { getUserId, removeToken, removeUserId } from '../../core/auth';
import { geocode } from '../../utils/geocoder';
import { handleError } from '../../utils/errors';

import { ReactComponent as EditIcon } from '../../media/icons/edit.svg';
import { ReactComponent as MailIcon } from '../../media/icons/mail.svg';
import { ReactComponent as MobileIcon } from '../../media/icons/mobile.svg';
import { useTitleWithEvent } from '../../hooks';

function LinkButton({ href, children }) {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Button color="secondary" className={classes.button} onClick={() => history.push(href)}>
      {children}
    </Button>
  );
}

const Profile = () => {
  const history = useHistory();
  useTitleWithEvent('Profile', history);
  const classes = useStyles();

  const [user, setUser] = React.useState({});
  const [region, setRegion] = React.useState(undefined);
  const [isLoading, setLoading] = React.useState(true);

  const goToEdit = React.useCallback(() => {
    history.push('/app/profile/edit');
  }, [history]);

  const logout = React.useCallback(() => {
    api
      .post('logout/', {})
      .then(res => {
        if (res.data) {
          removeToken();
          removeUserId();
          history.push('/login');
        }
      })
      .catch(err => {
        handleError(err);
      });
  }, [history]);

  React.useEffect(() => {
    const fetchData = () => {
      api
        .get(`users/${getUserId()}/`)
        .then(res => {
          const { data } = res;
          if (data) {
            setUser(data);
          }
        })
        .catch(err => {
          handleError(err);
        })
        .finally(() => setLoading(false));
    };

    fetchData();
  }, []);

  const updateProfileValue = async (name, value) => {
    const newUser = {
      ...user,
      profile: {
        ...user.profile,
        [name]: value
      }
    };
    setUser(newUser);
    try {
      const response = await api.put(`profile_update/${newUser.profile.id}/`, {
        latitude: newUser.profile?.latitude,
        longitude: newUser.profile?.longitude,
        send_sms_notifications: newUser.profile?.send_sms_notifications,
        send_email_notifications: newUser.profile?.send_email_notifications
      });
      setUser(user => ({
        ...user,
        profile: response.data
      }));
    } catch (e) {
      handleError(e);
    }
  };

  const latitude = user?.profile?.latitude;
  const longitude = user?.profile?.longitude;

  React.useEffect(() => {
    if (!latitude || !longitude || (latitude === 0 && longitude === 0)) return;
    geocode(latitude, longitude, setRegion);
  }, [latitude, longitude]);

  return isLoading ? (
    <Loader />
  ) : (
    <div className={classes.page}>
      <PageHeader classNameTitle={classes.pageHeaderTitle}>
        Profile
        <span onClick={goToEdit}>
          <EditIcon />
        </span>
      </PageHeader>
      <div>
        <div className={classes.row}>
          <div className={classes.rowLabel}>First Name</div>
          <div className={classes.rowValue}>{user.profile?.first_name}</div>
        </div>
        <div className={classes.row}>
          <div className={classes.rowLabel}>Last Name</div>
          <div className={classes.rowValue}>{user.profile?.last_name}</div>
        </div>
        <div className={classes.row}>
          <div className={classes.rowLabel}>Email</div>
          <div className={classes.rowValue}>{user.email}</div>
        </div>
        <div className={classes.row}>
          <div className={classes.rowLabel}>Phone</div>
          <div className={classes.rowValue}>{user.profile?.phone_number}</div>
        </div>
        {region && (
          <div className={classes.row}>
            <div className={classes.rowLabel}>Region</div>
            <div className={classes.rowValue}>{region}</div>
          </div>
        )}
      </div>
      <div className={classes.toggleWrap}>
        <div className={classes.toggleRow}>
          <div className={classes.toggleRowLabel}>
            <MobileIcon />
            Text notifications
          </div>
          <div className={classes.toggleRowValue}>
            <Switch
              checked={user?.profile?.send_sms_notifications}
              onChange={async e => {
                await updateProfileValue('send_sms_notifications', e.target.checked);
              }}
            />
          </div>
        </div>
        <div className={classes.toggleRow}>
          <div className={classes.toggleRowLabel}>
            <MailIcon />
            Email notifications
          </div>
          <div className={classes.toggleRowValue}>
            <Switch
              checked={user?.profile?.send_email_notifications}
              onChange={async e => {
                await updateProfileValue('send_email_notifications', e.target.checked);
              }}
            />
          </div>
        </div>
      </div>
      <div className={classes.buttonsWrap}>
        {/*
        <div>
          <LinkButton href="/app/profile/survey">Profile Survey</LinkButton>
        </div>
        */}
        <div>
          <LinkButton href="/contact-us">Contact Us</LinkButton>
        </div>
        <div>
          <LinkButton href="/contact-us">Do Not Sell Info</LinkButton>
        </div>
        <div>
          <Button color="secondary" className={`${classes.button} ${classes.buttonLogout}`} onClick={logout}>
            Logout
          </Button>
        </div>
      </div>
      <Privacy />
    </div>
  );
};

const useStyles = makeStyles({
  page: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    '& .policy-block': {
      marginTop: 0
    }
  },
  pageHeaderTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    '& span': {
      cursor: 'pointer'
    }
  },
  row: {
    padding: '12px 24px',
    margin: '0 -24px',
    display: 'flex',
    justifyContent: 'space-between',
    '&:nth-child(even)': {
      backgroundColor: '#F2F2F2'
    }
  },
  rowLabel: {
    fontSize: 14,
    lineHeight: '24px',
    color: '#333'
  },
  rowValue: {
    fontSize: 16,
    lineHeight: '24px',
    color: '#333646',
    fontWeight: 500,
    paddingLeft: 16,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  toggleWrap: {
    paddingTop: 40
  },
  toggleRow: {
    padding: '20px 0',
    display: 'flex',
    justifyContent: 'space-between',
    '&:not(:last-of-type)': {
      boxShadow: 'inset 0 -1px 0 #F2F2F2'
    }
  },
  toggleRowLabel: {
    display: 'flex',
    align: 'center',
    fontSize: 16,
    lineHeight: '24px',
    color: '#333',
    '& svg': {
      marginRight: 16
    }
  },
  shareRow: {
    display: 'flex',
    align: 'center',
    padding: '20px 0',
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 500,
    color: '#2B508D',
    '& svg': {
      marginRight: 16
    }
  },
  buttonsWrap: {
    marginTop: 'auto',
    paddingTop: 24
  },
  button: {
    marginTop: 16,
    width: '100%'
  },
  buttonLogout: {
    color: '#D62121 !important'
  }
});

export default Profile;
