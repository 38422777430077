import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Dialog from './Dialog';
import LoadingIndicator from './LoadingIndicator';

const LoadingDialog = ({ isOpened, title, text }) => {
  const classes = useStyles();

  return (
    <Dialog
      isOpened={isOpened}
      title={title}
      text={text}
      disabled={!isOpened}
      showButton={false}
      icon={
        <div className={classes.loadingWrap}>
          <div className={classes.loading}>
            <LoadingIndicator color="white" size={48} />
          </div>
        </div>
      }
    />
  );
};

const useStyles = makeStyles({
  loadingWrap: {
    display: 'flex',
    justifyContent: 'center'
  },
  loading: {
    width: 96,
    height: 96,
    borderRadius: 48,
    backgroundColor: '#579862',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
});

export default LoadingDialog;
